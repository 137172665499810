import React, { useMemo, useState, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormProvider from 'src/components/hook_form/form_provider';
import RHFTextField from 'src/components/hook_form/rhf_text_field';
import * as Yup from 'yup';
import { Badge, InlineGrid, Card, InlineStack, Button, Box, Banner } from '@shopify/polaris';
import { PlusIcon, DeleteIcon } from '@shopify/polaris-icons';
import { chooseLan } from 'src/utils/language_code';
import { requiredField } from 'src/utils/format_data';
import { getDirtyFields } from 'src/utils/format_data';
import { locationSchema, machineEndpointsSchema } from 'src/utils/schema';
import { useGetLocations } from 'src/api/location_api';
import { RHFAPISelect } from 'src/components/hook_form/rhf_select';
import CommonSelect from 'src/components/hook_form/common_select';
import { MACHINE_TYPE } from 'src/api/common_code/machine_type';
import {
  deleteMachineEndpoint,
  postMachine,
  updateMachine,
  useGetMachine,
} from 'src/api/machine_api';
import { print } from 'src/utils/log';

const MachineForm = ({ onSuccess, currentData }) => {
  const { t } = useTranslation(['validation', 'settings']);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const isEdit = !!currentData?.machineId;
  const { searchResults: machineData } = useGetMachine(isEdit ? currentData.machineId : null);

  const schema = Yup.object().shape({
    machineNameLocal: Yup.string().required(t('validation:machine_name_required')),
    machineNameEnglish: Yup.string(),
    machineType: Yup.string().required(t('validation:machine_type_required')),
    locationId: locationSchema(t),
    machineEndpoints: machineEndpointsSchema(t),
  });

  const currentEndpoints = useMemo(() => {
    if (machineData?.endpoints?.length === 0) return [{ sequence: 0, endpoint: '' }];
    return (
      machineData?.endpoints?.map((i) => ({
        sequence: i.sequence,
        endpoint: i.endpoint,
      })) || [{ sequence: 0, endpoint: '' }]
    );
  }, [machineData]);

  const defaultValues = useMemo(
    () => ({
      machineNameLocal: machineData?.machineNameLocal || '',
      machineNameEnglish: machineData?.machineNameEnglish || '',
      machineType: machineData?.machineType || '',
      locationId: machineData?.locationId || '',
      machineEndpoints: currentEndpoints || [],
    }),
    [machineData, currentEndpoints],
  );

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    control,
    formState: { isSubmitting, isDirty, dirtyFields },
  } = methods;

  const watchEndpoints = watch('machineEndpoints');

  const { append, remove, fields } = useFieldArray({
    control,
    name: 'machineEndpoints',
  });

  const addVariant = () => {
    append({ endpoint: '' });
  };

  const removeVariant = async (index, sequence) => {
    remove(index);
    if (isEdit) await deleteMachineEndpoint(machineData.machineId, sequence);
  };

  useEffect(() => {
    methods.reset(defaultValues);
    // eslint-disable-next-line
  }, [machineData]);

  const onSubmit = handleSubmit(async (data) => {
    data = getDirtyFields(dirtyFields, data);
    const apiFunction = isEdit ? updateMachine(data, machineData.machineId) : postMachine(data);
    try {
      var res = await apiFunction;
      if (res.isSuccess) {
        if (isEdit) {
          setSuccessMsg(t('settings:machine.update_success'));
        } else {
          onSuccess();
        }
      } else {
        setErrorMsg(res.errorMessages[0] ?? '');
      }
    } catch (error) {
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });

  const renderEndpoints = (
    <Card>
      <Stack spacing={1}>
        <InlineStack
          gap={200}
          blockAlign="center"
        >
          <Typography variant="heading_sm">{t('common:field.endpoint')}</Typography>
          <Badge>
            <Typography variant="heading_sm">{watchEndpoints.length}</Typography>
          </Badge>
        </InlineStack>

        <InlineGrid
          columns={2}
          gap="200"
        >
          {fields.map((field, index) => (
            <Stack key={field.id}>
              <InlineStack
                wrap={false}
                blockAlign="end"
                gap="200"
              >
                <RHFTextField
                  name={`machineEndpoints.${index}.endpoint`}
                  displayName={t('common:field.endpoint_name')}
                />
                {index === watchEndpoints.length - 1 && (
                  <div style={{ marginTop: 'var(--p-space-100)' }}>
                    <Button
                      icon={PlusIcon}
                      accessibilityLabel="Add endpoint"
                      onClick={addVariant}
                      size="large"
                    />
                  </div>
                )}
                {index > 0 && (
                  <div style={{ marginTop: 'var(--p-space-100)' }}>
                    <Button
                      icon={DeleteIcon}
                      tone="critical"
                      accessibilityLabel="Remove endpoint"
                      onClick={() => removeVariant(index, field.sequence)}
                      variant="plain"
                      size="large"
                    />
                  </div>
                )}
              </InlineStack>
            </Stack>
          ))}
        </InlineGrid>
      </Stack>
    </Card>
  );

  const renderForm = (
    <Stack spacing={1.5}>
      <Card>
        <Stack spacing={2}>
          <Typography variant="heading_sm">{t('settings:machine.machine_information')}</Typography>
          <InlineGrid
            columns={2}
            gap="200"
          >
            <RHFAPISelect
              name="locationId"
              displayName={requiredField(t('common:field.location'))}
              toOption={(r) => ({
                key: r.locationId,
                value: chooseLan(r.locationNameLocal, r.locationNameEnglish),
              })}
              useOptions={useGetLocations}
              params={{
                pageNumber: 1,
                pageSize: 100,
                isActive: true,
              }}
            />
            <CommonSelect
              data={MACHINE_TYPE}
              name="machineType"
              displayName={t('common:field.machine_type')}
            />
          </InlineGrid>
          <InlineGrid
            columns={2}
            gap="200"
          >
            <RHFTextField
              name="machineNameLocal"
              displayName={t('common:field.machine_name_local')}
            />
            <RHFTextField
              name="machineNameEnglish"
              displayName={t('common:field.machine_name_english')}
            />
          </InlineGrid>
        </Stack>
      </Card>
      {renderEndpoints}
    </Stack>
  );
  print('machine form load');
  return (
    <FormProvider
      methods={methods}
      onSubmit={onSubmit}
    >
      <Box paddingBlockEnd={300}>
        {!!errorMsg && (
          <Banner
            tone="critical"
            onDismiss={() => setErrorMsg('')}
          >
            {errorMsg}
          </Banner>
        )}
        {!!successMsg && (
          <Banner
            tone="success"
            onDismiss={() => setSuccessMsg('')}
          >
            {successMsg}
          </Banner>
        )}
      </Box>
      {renderForm}
      <Box paddingBlockStart={300}>
        <InlineStack
          align="end"
          gap={200}
        >
          <Button
            loading={isSubmitting}
            onClick={onSuccess}
          >
            {t('common:discard')}
          </Button>

          <Button
            submit={true}
            loading={isSubmitting}
            variant="primary"
            disabled={!isDirty}
          >
            {isEdit ? t('common:save') : t('common:submit')}
          </Button>
        </InlineStack>
      </Box>
    </FormProvider>
  );
};

export default MachineForm;

import { useState } from 'react';
import { label, filters, initialFilters, tabs, bulkAction } from '../channel_table_misc';
import useTable from 'src/hooks/use_table';
import Table from 'src/components/table/table';
import { print } from 'src/utils/log';
import { cleanParams } from 'src/utils/url';
import { useTranslation } from 'react-i18next';
import { useGetClients, useGetClientSortList } from 'src/api/client_api';
import RowMarkup from '../channel_table_row';
import EmptyStateCard from 'src/components/cards/empty_state_card';

export default function ChannelTableView({ handleMode, customerId }) {
  const { t } = useTranslation(['settings', 'common']);
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: 20,
    ClientType: 'channel',
    IsMapping: true,
    isActive: true,
    userId: customerId,
  });
  const { searchCount, searchResults, searchLoading } = useGetClients(cleanParams(params));

  const headers = [
    { title: t('common:field.channel') },
    { title: t('common:field.representative_name') },
    { title: t('common:field.contact_number') },
    { title: t('common:field.contact_email') },
    { title: t('common:field.country') },
  ];

  const table = useTable({
    tabItems: tabs(searchCount),
    totalDataCount: searchCount?.totalCount,
    useSortOptions: useGetClientSortList,
    data: searchResults,
    headers: headers,
    rowMarkup: (props) => (
      <RowMarkup
        {...props}
        handleMode={handleMode}
      />
    ),
    initialFilters: initialFilters,
    filters: filters,
    disambiguateLabel: label,
    bulkAction: bulkAction,
    resourceIdResolver: (d) => d.clientId,
    searching: t('channel:searching'),
    emptyStateMarkup: (
      <EmptyStateCard
        heading={t('settings:channels.empty_table')}
        body={t('settings:channels.empty_table_description')}
        image="/images/inbound_empty_icon.png"
        card={false}
        width={60}
        height={60}
      />
    ),
  });

  if (table.isRefetch.value) {
    setParams({
      userId: customerId,
      pageSize: table.rowsPerPage,
      pageNumber: table.page,
      clientType: 'channel',
      isActive: true,
      sortBy: table.sortBy,
      orderBy: table.orderBy,
      q: table.debounceSearchQuery,
    });
    table.onRefetched();
  }

  print('channel_table_view load');

  return (
    <Table
      useTable={table}
      isLoading={searchLoading}
    />
  );
}

import { Link as ReactLink } from 'react-router-dom';
import { Link as MLink } from '@mui/material';
import { forwardRef } from 'react';

// ----------------------------------------------------------------------

const RouterLink = forwardRef(({ href, ...other }, ref) => (
  <ReactLink
    ref={ref}
    to={href}
    {...other}
  />
));

const Link = ({ href, styles, children, ...other }) => (
  <MLink
    component={RouterLink}
    href={href}
    sx={{ textDecoration: 'none', ...styles }}
    {...other}
  >
    {children}
  </MLink>
);

export default Link;

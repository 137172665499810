export const LOCATION_TYPES = [
  {
    codeValue: 'warehouse',
    codeValueNameLocal: '창고',
    codeValueNameEnglish: 'warehouse',
    displayOrder: 1,
    codeReference1: null,
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
];

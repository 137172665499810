import { useState, useCallback, useEffect } from 'react';
import { TextField } from '@shopify/polaris';
import { useDebounce } from 'src/hooks/use_debounce';
import { isEmpty } from 'src/utils/type_check';

export const TextFieldFilter = ({ label, onEnter }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery);

  useEffect(() => {
    if (!isEmpty(debouncedSearchQuery)) onEnter(debouncedSearchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const onChangeSearchQuery = useCallback((value) => {
    setSearchQuery(value);
  }, []);

  return (
    <TextField
      label={label}
      value={searchQuery}
      onChange={onChangeSearchQuery}
      autoComplete="off"
    />
  );
};


export default TextFieldFilter;

import React from 'react';
import { Modal, Box, Slide } from '@mui/material';
import { XIcon } from '@shopify/polaris-icons';
import { InlineStack, Button, Text } from '@shopify/polaris';
import { modalTitleHeight } from 'src/theme/spacing';
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: 'var(--p-border-radius-400)',
  boxShadow: 'var(--p-shadow-600)',
  margin: 'auto',
  backgroundColor: 'var(--p-color-bg-surface)',
  maxHeight: '100%',
  width: { xs: '450px', sm: '550px', md: '720px' },
};

const titleStyle = {
  borderRadius: 'var(--p-border-radius-400) var(--p-border-radius-400) 0 0',
  background: 'var(--p-color-bg-surface-tertiary)',
  borderBottom: 'var(--p-border-width-025) solid var(--p-color-border)',
  padding: 'var(--p-space-400)',
  height: modalTitleHeight,
};

const footerStyle = {
  borderRadius: '0 0 var(--p-border-radius-400) var(--p-border-radius-400) ',
  background: 'var(--p-color-bg-surface)',
  borderTop: 'var(--p-border-width-025) solid var(--p-color-border)',
  padding: 'var(--p-space-400)',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 'var(--p-space-200)',
};

const StackedModal = ({
  isOpen,
  onClose,
  title,
  content,
  primaryAction,
  secondaryAction,
  footerLeftNode,
  sx,
  transitionUp = false,
}) => {
  const hasFooter = !primaryAction && !secondaryAction;

  const ModalComponent = (
    <Box sx={{ ...modalStyle, ...sx }}>
      <Box sx={titleStyle}>
        <InlineStack align="space-between">
          <Text
            variant="headingMd"
            fontWeight="bold"
            as="h2"
          >
            {title}
          </Text>
          <Button
            icon={XIcon}
            variant="tertiary"
            accessibilityLabel="Add theme"
            onClick={onClose}
          />
        </InlineStack>
      </Box>
      <Box sx={{ padding: 'var(--p-space-400)' }}>{content}</Box>
      {!hasFooter && (
        <Box sx={footerStyle}>
          {footerLeftNode && <Box sx={{ marginRight: 'auto' }}>{footerLeftNode}</Box>}
          {secondaryAction && (
            <Button
              variant="secondary"
              onClick={secondaryAction.onAction}
              loading={secondaryAction.loading}
            >
              {secondaryAction.content}
            </Button>
          )}
          {primaryAction && (
            <Button
              submit={primaryAction.isSubmit}
              variant="primary"
              onClick={primaryAction.onAction}
              loading={primaryAction.loading}
              disabled={primaryAction.disabled || false}
            >
              {primaryAction.content}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );

  if (transitionUp) {
    return (
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus
      >
        <Slide
          direction="up"
          in={isOpen}
          mountOnEnter
          unmountOnExit
          onExit={(node) => {
            node.style.transition = 'none';
          }}
        >
          {ModalComponent}
        </Slide>
      </Modal>
    );
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
    >
      {ModalComponent}
    </Modal>
  );
};

export default StackedModal;

import i18n from 'src/locales/i18n';

i18n.loadNamespaces(['settings']);

export const headers = () => {
  return [
    { title: i18n.t('common:field.location_name') },
    { title: i18n.t('settings:mapping.location_mapping_code') },
    { title: i18n.t('common:field.courier_name') },
    { title: i18n.t('common:field.courier_service_code') },
  ];
};

import i18n from 'src/locales/i18n';
import {
  OrderDraftIcon,
  PauseCircleIcon,
  PackageIcon,
  AlertTriangleIcon,
  DeliveryIcon,
  DeleteIcon,
  XCircleIcon,
  CheckCircleIcon,
} from '@shopify/polaris-icons';
import { isZeroObject, onlyContains } from 'src/utils/type_check';
import { ORDERSTATUS } from 'src/api/common_code/order_status';
import { Badge } from 'src/components/badge/badge';
import { useGetLocations } from 'src/api/location_api';
import { useGetChannels } from 'src/api/channels_api';
import { useGetUsersWithParams } from 'src/api/user_api';
import { chooseLan } from 'src/utils/language_code';
import { SingleSelectAutocomplete } from 'src/components/table/filter/autocomplete';
import { TextFieldFilter } from 'src/components/table/filter/text_field';
import { deleteConfirm, confirm } from 'src/components/dialog/confirmation';
import { deleteOrders, markShipments } from 'src/api/order_api';
import BulkButton from 'src/components/bulk_button';

i18n.loadNamespaces(['order']);

export const OSTATUS = {
  DRAFT: 'Draft',
  ONHOLD: 'OnHold',
  READYTOSHIP: 'ReadyToShip',
  NOTREADYTOSHIP: 'NotReadyToShip',
  SHIPPED: 'Shipped',
  ALL: 'All',
  CANCEL: 'Cancel',
  DELIVERED: 'Delivered',
};

export const getBadge = (status, children) => {
  if (status === OSTATUS.DRAFT)
    return (
      <Badge
        tone={'attention'}
        icon={OrderDraftIcon}
      >
        {children}
      </Badge>
    );
  if (status === OSTATUS.ONHOLD)
    return (
      <Badge
        tone="warning"
        icon={PauseCircleIcon}
      >
        {children === 'On Hold' ? 'On hold' : children}
      </Badge>
    );
  if (status === OSTATUS.READYTOSHIP)
    return (
      <Badge
        tone="info"
        icon={PackageIcon}
      >
        {children === 'Ready To Ship' ? 'Ready to ship' : children}
      </Badge>
    );
  if (status === OSTATUS.NOTREADYTOSHIP)
    return (
      <Badge
        tone="critical"
        icon={AlertTriangleIcon}
      >
        {children === 'Not Ready To Ship' ? 'Not ready to ship' : children}
      </Badge>
    );
  if (status === OSTATUS.SHIPPED)
    return (
      <Badge
        icon={DeliveryIcon}
        tone="success"
      >
        {children}
      </Badge>
    );
  if (status === OSTATUS.CANCEL) return <Badge icon={XCircleIcon}>{children}</Badge>;
  if (status === OSTATUS.DELIVERED) return <Badge icon={CheckCircleIcon}>{children ?? 0}</Badge>;
  if (status === OSTATUS.ALL) return <Badge tone="neutral"> {children} </Badge>;
};

export const tabs = (count) => {
  return [
    {
      label: i18n.t('common:tab.all'),
      value: '',
      onClick: () => {},
      badge: getBadge('All', count.allCount),
      dataCount: count.allCount,
    },
    ...ORDERSTATUS.map((status) => {
      return {
        label: chooseLan(status.codeValueNameLocal, status.codeValueNameEnglish),
        value: status.codeValue,
        onClick: () => {},
        badge: getBadge(
          status.codeValue,
          count[`${status.codeValue.charAt(0).toLowerCase()}${status.codeValue.slice(1)}Count`],
        ),
        dataCount:
          count[`${status.codeValue.charAt(0).toLowerCase()}${status.codeValue.slice(1)}Count`],
      };
    }),
  ];
};

export const bulkAction = (
  data,
  selectedIds,
  handleSelectionChange,
  setBannerText,
  setBannerVisible,
  setBannerSuccess,
) => {
  var status = new Set();
  data.forEach((d) => {
    if (selectedIds.includes(d.orderId)) {
      status.add(d.orderStatus);
    }
  });
  if (status.size === 0) return [];

  if (onlyContains(status, [OSTATUS.DRAFT, OSTATUS.NOTREADYTOSHIP, OSTATUS.ONHOLD])) {
    return [
      {
        content: (
          <BulkButton
            icon={PackageIcon}
            content={i18n.t('common:tab.readytoship')}
          />
        ),
        onAction: async () => {
          const confirmation = await confirm({
            title: i18n.t('order:shipment'),
            content: i18n.t('order:shipment_body'),
          });
          if (confirmation) {
            const res = await markShipments(selectedIds);
            if (!isZeroObject(res)) {
              if (res.notReadyToShip && res.notReadyToShip > 0) {
                setBannerText(
                  i18n.t('order:banner_text_fail', {
                    all: res.readyToShip + res.notReadyToShip,
                    readytoship: res.readyToShip,
                    notreadytoship: res.notReadyToShip,
                  }),
                );
                setBannerSuccess(false);
              } else {
                setBannerText(
                  i18n.t('order:banner_text_success', {
                    all: res.readyToShip + res.notReadyToShip,
                    readytoship: res.readyToShip,
                  }),
                );
                setBannerSuccess(true);
              }
              setBannerVisible(true);
            }
            handleSelectionChange('page', false);
          }
        },
      },
      {
        content: (
          <BulkButton
            icon={DeleteIcon}
            content={i18n.t('common:delete')}
          />
        ),
        destructive: true,
        icon: DeleteIcon,
        onAction: async () => {
          const confirmation = await deleteConfirm();
          if (confirmation) {
            await deleteOrders(selectedIds);
            handleSelectionChange('page', false);
          }
        },
      },
    ];
  }
};

export const orderInitialFilters = (storedParams) => {
  if (
    storedParams?.locationId?.value ||
    storedParams?.clientId?.value ||
    storedParams?.userId?.label ||
    storedParams?.sku?.label
  ) {
    return storedParams;
  }

  return {
    locationId: { label: '', value: '' },
    clientId: { label: '', value: '' },
    userId: { label: '', value: '' },
    sku: { label: '', value: '' },
  };
};

export const orderFilters = (state, setState, customerId) => {
  var filters = [
    {
      key: 'locationId',
      label: i18n.t('common:field.location'),
      filter: (
        <SingleSelectAutocomplete
          label={i18n.t('common:field.location')}
          useOptions={useGetLocations}
          params={{
            pageSize: 5,
            pageNumber: 1,
            isActive: true,
          }}
          searchParamName={'locationName'}
          toOption={(d) => ({
            label: chooseLan(d.locationNameLocal, d.locationNameEnglish),
            value: d.locationId,
          })}
          value={state?.locationId?.value ?? ''}
          onSelect={(value) => setState('locationId', value)}
        />
      ),
      shortcut: true,
      pinned: true,
    },
    {
      key: 'clientId',
      label: i18n.t('common:field.channel'),
      filter: (
        <SingleSelectAutocomplete
          label={i18n.t('common:field.channel')}
          useOptions={useGetChannels}
          params={{
            pageSize: 5,
            pageNumber: 1,
            isActive: true,
          }}
          searchParamName={'q'}
          toOption={(d) => ({
            label: chooseLan(d.clientNameLocal, d.clientNameEnglish),
            value: d.clientId,
          })}
          value={state?.clientId?.value ?? ''}
          onSelect={(value) => setState('clientId', value)}
        />
      ),
      shortcut: true,
      pinned: true,
    },

    {
      key: 'sku',
      label: i18n.t('common:field.sku'),
      filter: (
        <TextFieldFilter
          label={i18n.t('common:field.sku')}
          value={state?.sku?.value ?? ''}
          onEnter={(value) => {
            setState('sku', value);
          }}
        />
      ),
      shortcut: true,
      pinned: true,
    },
  ];

  if (!customerId) {
    filters.push({
      key: 'userId',
      label: i18n.t('common:field.user'),
      filter: (
        <SingleSelectAutocomplete
          label={i18n.t('common:field.user')}
          useOptions={useGetUsersWithParams}
          params={{
            pageSize: 5,
            pageNumber: 1,
            isActive: true,
            roleType: 'customer',
          }}
          searchParamName={'userName'}
          toOption={(d) => ({
            label: chooseLan(d.userFirstNameLocal, d.userFirstNameEnglish),
            value: d.userId,
          })}
          value={state?.userId?.value ?? ''}
          onSelect={(value) => setState('userId', value)}
        />
      ),
      shortcut: true,
      pinned: true,
    });
  }
  return filters;
};

export function orderDisambiguateLabel(key, value) {
  switch (key) {
    case 'userId':
      return i18n.t('common:label.customer', { customer: value });
    case 'locationId':
      return i18n.t('common:label.location', { location: value });
    case 'clientId':
      return i18n.t('common:label.channel', { channel: value });
    case 'sku':
      return i18n.t('common:label.sku', { sku: value });
    default:
      return value;
  }
}

import Table from 'src/components/table/table';

export default function PackManHistoryTableView({ loading, table }) {
  return (
    <Table
      useTable={table}
      isLoading={loading}
    />
  );
}

import { headers } from './location_mapping_table_misc';
import useTable from 'src/hooks/use_table';
import Table from 'src/components/table/table';
import { print } from 'src/utils/log';
import rowMarkup from './location_mapping_table_row';

export default function LocationMappingTableView({ data, searchLoading }) {
  const table = useTable({
    data: data ?? [],
    headers: headers(),
    rowMarkup: rowMarkup,
    resourceIdResolver: (d) => d.locationId,
    showRowsPerPage: false,
    showPagination: false,
    showSearchFilter: false,
    debounceDelay: 0,
    selectable: false,
  });

  print('location mapping table load');

  return (
    <Table
      useTable={table}
      isLoading={searchLoading}
    />
  );
}

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Icon } from '@shopify/polaris';
import { ChevronRightIcon } from '@shopify/polaris-icons';

// ----------------------------------------------------------------------

export default function Breadcrumb({ links, sx, ...other }) {
  const lastLink = links[links.length - 1].name;

  return (
    <Box sx={{ ...sx }}>
      {!!links.length && (
        <Breadcrumbs
          separator={<Icon source={ChevronRightIcon} />}
          {...other}
        >
          {links.map((link) => (
            <LinkItem
              key={link.name || ''}
              link={link}
              disabled={link.name === lastLink}
              onClick={link.onClick}
            />
          ))}
        </Breadcrumbs>
      )}
    </Box>
  );
}

const LinkItem = ({ link, disabled, onClick }) => {
  const { name, href } = link;
  const styles = {
    typography: 'body_md_medium',
    alignItems: 'center',
    color: 'text.secondary',
    display: 'inline-flex',
    ...(disabled && {
      typography: 'body_md',
      cursor: 'default',
      pointerEvents: 'none',
    }),
  };
  if (href) {
    return (
      <Link
        href={href}
        sx={styles}
        underline="none"
      >
        {name}
      </Link>
    );
  }
  if (onClick) {
    return (
      <Box
        component="div"
        onClick={onClick}
        sx={{
          ...styles,
          cursor: 'pointer',
        }}
      >
        {name}
      </Box>
    );
  }

  return <Box sx={styles}> {name} </Box>;
};

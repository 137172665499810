import {
  checkEmail,
  forgotPassword,
  login,
  logout,
  refresh,
  resetPassword,
} from 'src/api/auth_api';
import { PATHS } from 'src/routes/paths';
import { getLongLangCode } from 'src/utils/language_code';
import { deleteToken, setToken } from 'src/utils/token';
import { create } from 'zustand';
import dateStore from './date_store';
import locationStore from './location_store';
import settingStore from './setting_store';

const initialState = {
  email: '',
};

const store = (set, get) => ({
  ...initialState,

  setEmail: (value) => {
    set({ email: value });
  },
  checkEmail: async (email) => {
    var res = await checkEmail(email);
    if (res.isSuccess) {
      set({ email: email });
    }
    return res;
  },
  login: async (email, password) => {
    var res = await login(email, password);
    if (res.isSuccess && res.result.isApprove) {
      dateStore.getState().reset();
      locationStore.getState().reset();
      settingStore.getState().reset();
      authStore.getState().reset();
      setToken(res.result.token);
    }
    return res;
  },
  logout: async (api = true) => {
    await logoutAuthStore(api);
  },
  forgotPassword: async (email) => {
    var res = await forgotPassword(email, getLongLangCode());
    return res;
  },
  resetPassword: async (newPassword, token) => {
    var res = await resetPassword(newPassword, token);
    return res;
  },
  refresh: async () => {
    var res = await refresh();
    if (res.isSuccess) {
      setToken(res.result.token);
    } else {
      window.location.replace(PATHS.auth.email);
    }
    return res;
  },
  reset: () => {
    set(initialState);
  },
});

const authStore = create(store);

const logoutAuthStore = async (api) => {
  if (api) {
    await logout();
  }
  dateStore.getState().reset();
  locationStore.getState().reset();
  settingStore.getState().reset();
  authStore.getState().reset();
  deleteToken();
};

export default authStore;

import { createConfirmationCreater, createMountPoint, createReactTreeMounter } from 'react-confirm';
import DeleteDialog from './delete_dialog';
import ConfirmDialog from './confirm_dialog';
import TokenDialog from './token_dialog';
import DeactivateDialog from './deactivate_dialog';
import ImportDialog from './import_dialog';
import ExportDialog from './export_dialog';
import LogoutDialog from './logout_dialog';
import IssueDialog from './issue_dialog';
import DisconnectDialog from './disconnect_dialog';
import tracking_number_range_dialog from './tracking_number_range_dialog';
import import_dialog_courier from './import_dialog_courier';
import stock_management_dialog from './stock_management_dialog';

const mounter = createReactTreeMounter();

export const createConfirmation = createConfirmationCreater(mounter);
export const MountPoint = createMountPoint(mounter);

export const deleteConfirm = createConfirmation(DeleteDialog);
export const disconnectConfirm = createConfirmation(DisconnectDialog);
export const deactivateConfirm = createConfirmation(DeactivateDialog);
export const confirm = createConfirmation(ConfirmDialog);
export const tokenConfirm = createConfirmation(TokenDialog);
export const importConfirm = createConfirmation(ImportDialog);
export const exportConfirm = createConfirmation(ExportDialog);
export const logoutConfirm = createConfirmation(LogoutDialog);
export const issueConfirm = createConfirmation(IssueDialog);
export const trackingConfirm = createConfirmation(tracking_number_range_dialog);
export const importDialogCourier = createConfirmation(import_dialog_courier);
export const stockConfirm = createConfirmation(stock_management_dialog);

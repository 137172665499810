import { useFormContext, Controller } from 'react-hook-form';
import { TextField, Autocomplete, Box, InputLabel, Typography, Paper } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'src/hooks/use_debounce';
import { Icon } from '@shopify/polaris';
import { CheckIcon, SearchIcon } from '@shopify/polaris-icons';
import i18n from 'src/locales/i18n';
import { cleanParams } from 'src/utils/url';
import { inputStyles } from 'src/utils/style';
import { isObject } from 'src/utils/type_check';

export function RHFAutocomplete({ name, displayName, placeholder, helperText, ...other }) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box sx={{ width: '100%' }}>
          {displayName ?? (
            <InputLabel>
              <Typography
                fontWeight={450}
                fontSize={'13px'}
                sx={{ color: 'text.primary' }}
              >
                {displayName}
              </Typography>
            </InputLabel>
          )}
          <Autocomplete
            {...field}
            onChange={(event, newValue) => setValue(name, newValue, { shouldValidate: true })}
            size="small"
            renderInput={(params) => (
              <TextField
                sx={inputStyles}
                label={displayName}
                placeholder={placeholder}
                error={!!error}
                helperText={error ? error?.message : helperText}
                {...params}
              />
            )}
            {...other}
          />
        </Box>
      )}
    />
  );
}

export function RHFAutocompleteMultiple({
  name,
  displayName,
  helperText,
  options,
  onClick,
  onChange = () => {},
  disabled,
  placeholder,
  initialValue = [],
  ...others
}) {
  const { control, getValues, setValue } = useFormContext();
  const [text, setText] = useState('');

  const value = getValues(name);
  useEffect(() => {
    if (value === '') {
      setText('');
    }
  }, [value]);

  useEffect(() => {
    if (initialValue.length > 0 && options.length > 0) {
      const mappedValues = initialValue
        .map((val) => {
          if (val) {
            const matchingOption = options.find((opt) => opt.value === val);
            return matchingOption ? matchingOption : { value: val, label: '' };
          }
          return null;
        })
        .filter(Boolean);

      setValue(name, mappedValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box sx={{ width: '100%' }}>
          {displayName && (
            <InputLabel>
              <Typography
                fontWeight={450}
                fontSize={'13px'}
                sx={{ color: 'text.primary' }}
              >
                {displayName}
              </Typography>
            </InputLabel>
          )}

          <Autocomplete
            {...field}
            sx={inputStyles}
            value={
              Array.isArray(field.value)
                ? field.value.map((val) => options.find((opt) => opt.value === val) || null)
                : []
            }
            inputValue={text}
            size="small"
            clearOnBlur={false}
            onInputChange={(e, val, reason) => (e ? setText(val) : null)}
            onChange={(e, val) => {
              const isOption = options.some((option) => option.value === val.value);
              if (isOption) {
                onChange(val);
              } else {
                onChange(null);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={inputStyles}
                error={!!error}
                placeholder={placeholder || i18n.t('common:searching')}
                helperText={error ? error?.message : helperText}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <Icon source={SearchIcon} />,
                  onKeyDown: (event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                    }
                  },
                }}
                variant={disabled ? 'filled' : 'outlined'}
                onClick={onClick}
              />
            )}
            options={options}
            freeSolo
            getOptionLabel={(option) => ''}
            renderOption={(props, option, { inputValue }) => (
              <Box
                component="li"
                {...props}
                key={props.id}
              >
                <Typography fontWeight={option.value === field.value ? '700' : '400'}>
                  {option.label}
                </Typography>
              </Box>
            )}
            disabled={disabled}
            {...others}
          />
        </Box>
      )}
    />
  );
}

export function RHFAPIAutocomplete({
  name,
  displayName,
  params,
  helperText,
  searchParamName,
  useOptions,
  toOption,
  onClick,
  onChange = () => {},
  disabled,
  placeholder,
  onChangeWithValue = false,
  ...others
}) {
  const { control, getValues } = useFormContext();

  const [text, setText] = useState('');
  const debounceText = useDebounce(text);

  const debounceParams = useMemo(
    () => ({
      ...params,
      [searchParamName]: debounceText,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debounceText, params],
  );

  const { searchResults } = useOptions(cleanParams(debounceParams));
  const options = useMemo(() => {
    return searchResults.map((r) => toOption(r));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults]);
  const value = getValues(name);
  useEffect(() => {
    if (value === '') {
      setText('');
    }
  }, [value]);

  useEffect(() => {
    if (value !== '' && text === '') {
      const label = options.find((x) => x.value === value)?.label || '';
      setText(label);
    }
  }, [options, text, value]);

  const CustomPaper = (props) => {
    return (
      <Paper
        sx={{
          borderRadius: '12px',
          boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          marginTop: '6px',
          '& .MuiAutocomplete-listbox': {
            paddingY: '0px',
          },
        }}
        {...props}
      />
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box sx={{ width: '100%' }}>
          {displayName && (
            <InputLabel>
              <Typography
                fontWeight={450}
                fontSize={'13px'}
                sx={{ color: 'text.primary' }}
              >
                {displayName}
              </Typography>
            </InputLabel>
          )}
          <Autocomplete
            {...field}
            value={field.value === '' ? null : field.value}
            inputValue={text}
            size="small"
            clearOnBlur={false}
            onInputChange={(e, val, reason) => (e ? setText(val) : null)}
            onChange={(e, val) => {
              if (!val || field.value !== val.value) {
                onChangeWithValue ? onChange(val) : onChange();
              }
              if (!val) {
                setText('');
                field.onChange('');
              } else {
                setText(val.label);
                field.onChange(val.value);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={inputStyles}
                error={!!error}
                placeholder={placeholder || i18n.t('common:searching')}
                helperText={error ? error?.message : helperText}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <Icon source={SearchIcon} />,
                }}
                variant={disabled ? 'filled' : 'outlined'}
                onClick={onClick}
              />
            )}
            options={options}
            isOptionEqualToValue={(option, value) => option.value === value}
            getOptionLabel={(option) => (isObject(option) ? option.label : '')}
            PaperComponent={CustomPaper}
            renderOption={(props, option, { inputValue }) => (
              <Box
                {...props}
                key={props.id}
                sx={{
                  '&.Mui-selected': {
                    borderRadius: '8px !important',
                    backgroundColor: 'rgba(235, 235, 235, 1)',
                  },
                  '&.MuiAutocomplete-option.Mui-focused': {
                    borderRadius: '8px',
                  },
                  '&:hover': {
                    borderRadius: '8px',
                    backgroundColor: 'rgba(241, 241, 241, 1) !important',
                  },
                  '&.MuiAutocomplete-option': {
                    paddingX: '6px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderRadius: '8px',
                  },

                  paddingX: '0px',
                  margin: '6px',
                }}
              >
                <Typography
                  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  fontWeight={option.value === field.value ? '650' : '400'}
                >
                  {option.label}
                </Typography>
                {option.value === field.value && (
                  <CheckIcon
                    height={'20px'}
                    style={{ paddingLeft: '6px' }}
                  />
                )}
              </Box>
            )}
            disabled={disabled}
            {...others}
          />
        </Box>
      )}
    />
  );
}

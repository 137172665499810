import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import Favicon from 'react-favicon';
import { lowerCaseWithoutSpace } from './utils/format_data';
import { APP_NAME } from './config';
import 'src/locales/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <Favicon url={`/assets/logo/${lowerCaseWithoutSpace(APP_NAME)}_white.png`} />
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>,
);

import { Button, ChoiceList, Divider, Icon, Popover } from '@shopify/polaris';
import { TableButton } from './table_filter';
import { Box, Typography } from '@mui/material';
import { ArrowDownIcon, ArrowUpIcon, SortIcon } from '@shopify/polaris-icons';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { chooseLan } from 'src/utils/language_code';
import { isEmpty } from 'src/utils/type_check';

const TableSort = ({ onChangeSort, onChangeOrder, sortBy, orderBy, useOptions }) => {
  const { t } = useTranslation();

  const { searchResults } = useOptions();

  const [sortColumn, setSortColumn] = useState(sortBy);
  const [sortDirection, setSortDirection] = useState(orderBy);
  const [sortPopoverActive, setSortPopoverActive] = useState(false);
  const openSortPopoverActive = useCallback(() => setSortPopoverActive(true), []);
  const closeSortPopoverActive = useCallback(() => {
    setSortPopoverActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const uniqueSortItems = useMemo(() => {
    var temp = [];
    if (!isEmpty(searchResults.sortBy)) {
      temp = searchResults.sortBy.map((x) => ({
        label: chooseLan(x.nameLocal, x.nameEnglish),
        value: x.code,
      }));
    }
    return temp;
  }, [searchResults]);
  const orderItems = useMemo(() => {
    var temp = [];
    if (!isEmpty(searchResults.orderBy)) {
      temp = searchResults.orderBy.map((x) => ({
        label: chooseLan(x.nameLocal, x.nameEnglish),
        value: x.code,
      }));
    }
    return temp;
  }, [searchResults]);
  const handleSortColumnChange = useCallback((value) => {
    var val = value[0];
    setSortColumn(val);
    onChangeSort(val);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSortDirectionChange = useCallback((value) => {
    setSortDirection(value);
    onChangeOrder(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Popover
      active={sortPopoverActive}
      activator={
        <TableButton onClick={openSortPopoverActive}>
          <Icon source={SortIcon} />
        </TableButton>
      }
      autofocusTarget="first-node"
      onClose={closeSortPopoverActive}
    >
      <Box
        width={'11rem'}
        height={'100%'}
        padding={1.5}
      >
        <Box
          px={1}
          pb={1}
        >
          <ChoiceList
            title={<Typography variant="body_md_medium">{t('common:sort.sort_by')}</Typography>}
            choices={uniqueSortItems}
            selected={sortColumn}
            onChange={handleSortColumnChange}
          />
        </Box>
        <Divider />
        <Box
          width={'100%'}
          pt={1}
        >
          {orderItems.map((i, idx) => {
            return (
              <Button
                key={idx}
                pressed={sortDirection === i.value}
                variant="tertiary"
                icon={i.value === 'Ascending' ? ArrowUpIcon : ArrowDownIcon}
                textAlign="left"
                fullWidth
                onClick={() => handleSortDirectionChange(i.value)}
              >
                <Typography variant="body_md_medium">{i.label}</Typography>
              </Button>
            );
          })}
        </Box>
      </Box>
    </Popover>
  );
};

export default TableSort;

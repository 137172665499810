import { confirmable } from 'react-confirm';
import StackedModal from './stacked_modal';
import { useTranslation } from 'react-i18next';

function DeactivateDialog({ show, proceed, title, content }) {
  const { t } = useTranslation('common');
  return (
    <StackedModal
      isOpen={show}
      title={title || t('common:deactivate')}
      content={content || t('common:deactivate_confirmation')}
      primaryAction={{
        content: t('common:deactivate'),
        onAction: () => {
          proceed(true);
        },
      }}
      secondaryAction={{
        content: t('common:cancel'),
        onAction: () => {
          proceed(false);
        },
      }}
      onClose={() => {
        proceed(false);
      }}
    />
  );
}

export default confirmable(DeactivateDialog);

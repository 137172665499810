import { useEffect, useState } from 'react';
import { headers, tabs, bulkAction } from './sorter_table_misc';
import rowMarkup from './sorter_table_row';
import useTable from 'src/hooks/use_table';
import Table from 'src/components/table/table';
import { useGetSorter } from 'src/api/machine_api';
import { Button } from '@shopify/polaris';
import EmptyStateCard from 'src/components/cards/empty_state_card';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

export default function SorterTableView({
  selectedLocation,
  createSorterConfirmation,
  setIsEmpty,
}) {
  selectedLocation = selectedLocation === 'all' ? '' : selectedLocation;
  const [params, setParams] = useState({
    pageSize: 20,
    pageNumber: 1,
    locationId: selectedLocation,
  });

  const { searchResults: data, searchCount: count, searchLoading } = useGetSorter(params);
  const { t } = useTranslation(['settings']);

  useEffect(() => {
    if (!data.length) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, [data, searchLoading, setIsEmpty]);

  const table = useTable({
    tabItems: tabs(count),
    totalDataCount: count?.totalCount,
    data: data,
    headers: headers(),
    rowMarkup: rowMarkup,
    bulkAction: bulkAction,
    resourceIdResolver: (d) => d.doorId,
    showSearchFilter: false,
  });

  if (table.isRefetch.value) {
    setParams((prevParams) => ({
      ...prevParams,
      pageSize: table.rowsPerPage,
      pageNumber: table.page,
      locationId: selectedLocation,
    }));
    table.onRefetched();
  }

  useEffect(() => {
    table.isRefetch.onTrue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  if (!searchLoading && data.length === 0) {
    return (
      <Box>
        <EmptyStateCard
          heading={t('settings:sorter.no_sorters')}
          body={
            <>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                {t('settings:sorter.create_description')}
                <br />
                <br />
                <Button
                  variant="secondary"
                  onClick={async () => {
                    await createSorterConfirmation({ selectedLocation });
                  }}
                >
                  {t('settings:sorter.create_sorter')}
                </Button>
              </Box>
            </>
          }
          image="/images/sorter_empty.png"
          card={true}
          width={60}
          height={60}
        />
      </Box>
    );
  }

  return (
    <Table
      useTable={table}
      isLoading={searchLoading}
    />
  );
}

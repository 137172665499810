import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthGuard from 'src/layouts/guard/auth_guard';
import DashboardLayout from 'src/layouts/dashboard/dashboard_layout';
import { PATHS } from './paths';
import RoleGuard, { ROLES } from 'src/layouts/guard/role_guard';
import ShippingContainerScanPage from 'src/pages/dashboard/outbound/shipping/shipping_containers_scan_page';

const DashboardPage = lazy(() => import('src/pages/dashboard/dashboard_page'));
const OrderPage = lazy(() => import('src/pages/dashboard/order/order_page'));
const OrderAddPage = lazy(() => import('src/pages/dashboard/order/order_add_page'));
const OrderEditPage = lazy(() => import('src/pages/dashboard/order/order_edit_page'));
const OrderMergePage = lazy(() => import('src/pages/dashboard/order/order_merge_page'));
const PurchaseOrderPage = lazy(() => import('src/pages/dashboard/purchasing/purchase_order_page'));
const PurchaseOrderAddPage = lazy(
  () => import('src/pages/dashboard/purchasing/purchase_order_add_page'),
);
const PurchaseOrderEditPage = lazy(
  () => import('src/pages/dashboard/purchasing/purchase_order_edit_page'),
);
const SupplierPage = lazy(() => import('src/pages/dashboard/purchasing/supplier_page'));
const SupplierAddPage = lazy(() => import('src/pages/dashboard/purchasing/supplier_add_page'));
const SupplierEditPage = lazy(() => import('src/pages/dashboard/purchasing/supplier_edit_page'));

const InboundPage = lazy(() => import('src/pages/dashboard/inbound/inbound_page'));
const InboundEditPage = lazy(() => import('src/pages/dashboard/inbound/inbound_edit_page'));
const InboundItemHistoryPage = lazy(
  () => import('src/pages/dashboard/inbound/inbound_item_history_page'),
);
const PutawayPage = lazy(() => import('src/pages/dashboard/inbound/putaway_page'));

const OutboundPage = lazy(() => import('src/pages/dashboard/outbound/outbound/outbound_page'));
const OutboundEditPage = lazy(
  () => import('src/pages/dashboard/outbound/outbound/outbound_edit_page'),
);

const ShippingScanPage = lazy(
  () => import('src/pages/dashboard/outbound/shipping/shipping_scan_page'),
);
const ShippingContainersPage = lazy(
  () => import('src/pages/dashboard/outbound/shipping/shipping_containers_page'),
);
const ShippingContainerPage = lazy(
  () => import('src/pages/dashboard/outbound/shipping/shipping_container_page'),
);
const TrackingLabelPage = lazy(
  () => import('src/pages/dashboard/outbound/tracking_label/tracking_label_page'),
);
const PickingPage = lazy(() => import('src/pages/dashboard/outbound/picking/picking_page'));
const PickingTotalPage = lazy(
  () => import('src/pages/dashboard/outbound/picking/picking_total_page'),
);
const PickingWavePage = lazy(
  () => import('src/pages/dashboard/outbound/picking/picking_wave_page'),
);
const PickingSortPage = lazy(
  () => import('src/pages/dashboard/outbound/picking/picking_sort_page'),
);
const PickingTrackingPage = lazy(
  () => import('src/pages/dashboard/outbound/picking/picking_tracking_page'),
);
const PackingPage = lazy(() => import('src/pages/dashboard/outbound/packing/packing_page'));
const PackingMultiPage = lazy(
  () => import('src/pages/dashboard/outbound/packing/multi_packing/packing_multi_page'),
);
const PackingSinglePage = lazy(
  () => import('src/pages/dashboard/outbound/packing/single_packing/packing_single_page'),
);

const ProductPage = lazy(() => import('src/pages/dashboard/inventory/product/product_page'));
const ProductAddPage = lazy(() => import('src/pages/dashboard/inventory/product/product_add_page'));
const ProductEditPage = lazy(
  () => import('src/pages/dashboard/inventory/product/product_edit_page'),
);

const StockPage = lazy(() => import('src/pages/dashboard/inventory/stock/stock_page'));
const InventoryTransferPage = lazy(
  () => import('src/pages/dashboard/inventory/transfer/transfer_page'),
);
const InventoryTransferAddPage = lazy(
  () => import('src/pages/dashboard/inventory/transfer/transfer_add_page'),
);
const InventoryTransferEditPage = lazy(
  () => import('src/pages/dashboard/inventory/transfer/transfer_edit_page'),
);

const order = {
  path: 'order',
  element: (
    <RoleGuard allowedRoles={[ROLES.ADMIN, ROLES.MANAGER, ROLES.CUSTOMER, ROLES.STAFF]}>
      <Outlet />
    </RoleGuard>
  ),
  children: [
    {
      path: 'order',
      children: [
        { element: <OrderPage />, index: true },
        {
          path: 'new',
          element: <OrderAddPage />,
        },
        {
          path: 'edit/:id',
          element: <OrderEditPage />,
        },
      ],
    },
    { path: 'merge', element: <OrderMergePage /> },
  ],
};

const purchasing = {
  path: 'purchasing',
  element: (
    <RoleGuard allowedRoles={[ROLES.ADMIN, ROLES.MANAGER, ROLES.CUSTOMER, ROLES.STAFF]}>
      <Outlet />
    </RoleGuard>
  ),
  children: [
    {
      path: 'purchase-order',
      children: [
        { element: <PurchaseOrderPage />, index: true },
        {
          path: 'new',
          element: <PurchaseOrderAddPage />,
        },
        {
          path: 'edit/:id',
          element: <PurchaseOrderEditPage />,
        },
      ],
    },
    {
      path: 'supplier',
      children: [
        { element: <SupplierPage />, index: true },
        {
          path: 'new',
          element: <SupplierAddPage />,
        },
        {
          path: 'edit/:id',
          element: <SupplierEditPage />,
        },
      ],
    },
    {
      element: (
        <Navigate
          to={PATHS.dashboard.purchasing.purchase_order.root}
          replace
        />
      ),
      index: true,
    },
  ],
};

const inbound = {
  path: 'inbound',
  element: (
    <RoleGuard allowedRoles={[ROLES.ADMIN, ROLES.MANAGER, ROLES.LOCATION_MANAGER, ROLES.WORKER]}>
      <Outlet />
    </RoleGuard>
  ),
  children: [
    {
      path: 'inbound',
      children: [
        { element: <InboundPage />, index: true },
        {
          path: 'edit/:id',
          element: <InboundEditPage />,
        },
        {
          path: 'edit/:purchaseOrderId/:purchaseOrderLine',
          element: <InboundItemHistoryPage />,
        },
      ],
    },
    {
      path: 'putaway',
      children: [{ element: <PutawayPage />, index: true }],
    },
  ],
};

const outbound = {
  path: 'outbound',
  element: (
    <RoleGuard allowedRoles={[ROLES.ADMIN, ROLES.MANAGER, ROLES.LOCATION_MANAGER, ROLES.WORKER]}>
      <Outlet />
    </RoleGuard>
  ),
  children: [
    {
      path: 'outbound',
      children: [
        { element: <OutboundPage />, index: true },
        {
          path: 'edit/:id',
          element: <OutboundEditPage />,
        },
      ],
    },
    {
      path: 'shipping',
      children: [
        { path: 'scan', element: <ShippingScanPage />, index: true },
        { path: 'containers', element: <ShippingContainersPage /> },
        { path: 'containers/scan', element: <ShippingContainerScanPage /> },
        {
          path: 'containers/:id',
          element: <ShippingContainerPage />,
        },
      ],
    },
    {
      path: 'tracking-label',
      children: [{ element: <TrackingLabelPage />, index: true }],
    },
    {
      path: 'picking',
      children: [
        { element: <PickingPage />, index: true },
        {
          path: 'total/:id',
          element: <PickingTotalPage />,
        },
        {
          path: 'wave/:id',
          element: <PickingWavePage />,
        },
        {
          path: 'sort/:id',
          element: <PickingSortPage />,
        },
        {
          path: 'tracking/:id',
          element: <PickingTrackingPage />,
        },
      ],
    },
    {
      path: 'packing',
      children: [
        { element: <PackingPage />, index: true },
        {
          path: 'multi/:locationId/:type/:scanNumber',
          element: <PackingMultiPage />,
        },
        {
          path: 'single/:locationId/:type',
          element: <PackingSinglePage />,
        },
      ],
    },
  ],
};

const inventory = {
  path: 'inventory',
  element: (
    <RoleGuard
      allowedRoles={[
        ROLES.ADMIN,
        ROLES.MANAGER,
        ROLES.LOCATION_MANAGER,
        ROLES.WORKER,
        ROLES.STAFF,
        ROLES.CUSTOMER,
      ]}
    >
      <Outlet />
    </RoleGuard>
  ),
  children: [
    {
      path: 'products',
      children: [
        { element: <ProductPage />, index: true },
        {
          path: 'new',
          element: <ProductAddPage />,
        },
        {
          path: 'edit/:userId/:countryCode/:id',
          element: <ProductEditPage />,
        },
      ],
    },
    {
      path: 'stock',
      children: [{ element: <StockPage />, index: true }],
    },
    {
      path: 'transfer',
      children: [
        { element: <InventoryTransferPage />, index: true },
        {
          path: 'new',
          element: <InventoryTransferAddPage />,
        },
        {
          path: 'edit/:id',
          element: <InventoryTransferEditPage />,
        },
      ],
    },
  ],
};

const dashboard = {
  path: 'root',
  element: <DashboardPage />,
  index: true,
};

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [dashboard, order, purchasing, inbound, outbound, inventory],
  },
];

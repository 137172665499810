import i18n from 'src/locales/i18n';
import { isStringEmpty } from './type_check';

export const getLongLangCode = () => {
  const code = i18n.language;
  if (code === 'ko') return 'ko-KR';
  if (code === 'en') return 'en-US';
  return 'en-US';
};

export const getShortLangCode = () => {
  return i18n.language;
};

export const getShortLangCodeUser = (code) => {
  if (code === 'ko-KR') return 'ko';
  if (code === 'en-US') return 'en';
  return 'en';
};

export const chooseLan = (local, eng) => {
  const lan = getShortLangCode();
  var ret = lan === 'ko' ? local : eng;
  if (!isStringEmpty(ret)) return ret ?? local ?? eng ?? '';
  if (isStringEmpty(local)) return eng ?? local ?? eng ?? '';
  else return local ?? eng ?? '';
};

export const chooseLanProduct = (lan, local, eng) => {
  var ret = lan === 'KR' || lan === 'KO' ? local : eng;
  if (!isStringEmpty(ret)) return ret ?? local ?? eng ?? '';
  if (isStringEmpty(local)) return eng ?? local ?? eng ?? '';
  else return local ?? eng ?? '';
};

export const changeKoreaTitle = (country) => {
  if (country === 'Korea, Republic of') {
    return 'Republic of Korea';
  } else return country;
};

export const getCurrencyCode = () => {
  const code = i18n.language;
  if (code === 'en') return 'USD';
  if (code === 'ko') return 'KRW';
  return '';
};

export const getCurrencyCodeFromLocation = (location) => {
  if (location === 'US') return 'USD';
  if (location === 'KR') return 'KRW';
  return '';
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Page,
  Bleed,
  BlockStack,
  Box,
  Card,
  Text,
  Button,
  InlineStack,
  Banner,
} from '@shopify/polaris';
import CustomerInfo from 'src/components/cards/customer_info';
import UserForm from 'src/sections/settings/users/users_form';
import { getUserName } from 'src/utils/format_data';
import Loading from 'src/components/loading';
import { useGetMe } from 'src/api/user_api';
import { print } from 'src/utils/log';
import { Typography, Stack } from '@mui/material';
import UsersTable from './users_table';
import LocationTable from './location_table';
import StaffTable from './staff_table';
import { ROLES } from 'src/layouts/guard/role_guard';
import Breadcrumb from 'src/components/breadcrumb/breadcrumb';
import { isEmpty } from 'src/utils/type_check';
import { useBoolean } from 'src/hooks/use_boolean';
import { forgotPassword } from 'src/api/auth_api';
import { getLongLangCode } from 'src/utils/language_code';
import { confirm } from 'src/components/dialog/confirmation';
import authStore from 'src/stores/auth_store';

const MODES = {
  VIEW: 'view',
  FORM: 'form',
  DETAIL_LOCATION: 'detail_location',
  DETAIL_CUSTOMER: 'detail_customer',
};

export default function UsersView() {
  const { t } = useTranslation(['settings']);
  const [mode, setMode] = useState(MODES.VIEW);
  const [prevMode, setPrevMode] = useState(MODES.VIEW);
  const { user: userData, searchLoading, role, customerId: userCustomerId, userId } = useGetMe();
  const [data, setData] = useState({});
  const [location, setLocation] = useState({ name: '', locationId: '' });
  const [customer, setCustomer] = useState({ name: '', customerId: userCustomerId });
  const [mainTabType, setMainTabType] = useState('');
  const [locTabType, setLocTabType] = useState('');
  const resetPasswordClick = useBoolean();
  const { logout } = authStore();

  const handleCustomerInfoClick = (user, type) => {
    setData(user);
    if ([ROLES.ADMIN, ROLES.MANAGER, ROLES.CUSTOMER].includes(user.roleType)) {
      setMainTabType(type);
    }
    if ([ROLES.LOCATION_MANAGER, ROLES.WORKER].includes(user.roleType)) {
      setLocTabType(type);
    }
    handleMode(MODES.FORM);
    resetPasswordClick.onFalse();
  };

  const handleLocationDetail = (curLocation, type) => {
    setLocation(curLocation);
    setMainTabType(type);
    setLocTabType('');
    handleMode(MODES.DETAIL_LOCATION);
  };

  const handleCustomerDetail = (curCustomer, type) => {
    setCustomer(curCustomer);
    setMainTabType(type);
    handleMode(MODES.DETAIL_CUSTOMER);
  };

  const handleMode = (newMode, backMode) => {
    if (newMode !== MODES.FORM) setData({});
    setPrevMode(backMode ?? mode);
    setMode(newMode);
  };

  const renderMainTable = () => {
    if (role === ROLES.CUSTOMER) {
      return (
        <StaffTable
          customerId={customer.customerId}
          handleClick={handleCustomerInfoClick}
        />
      );
    }
    return (
      <UsersTable
        handleClick={handleCustomerInfoClick}
        handleLocationDetail={handleLocationDetail}
        handleCustomerDetail={handleCustomerDetail}
        tabType={mainTabType}
      />
    );
  };

  const renderUserPage = () => {
    return mode === MODES.VIEW ? (
      <Stack spacing={2}>
        <Card roundedAbove="sm">
          <BlockStack gap="200">
            <Text
              as="h2"
              variant="headingSm"
            >
              {t('settings:users.current_user')}
            </Text>
            <Box paddingBlockEnd="200">
              <CustomerInfo
                name={getUserName(userData)}
                description={`${t('settings:users.last_login')}: ${userData?.lastLoginFormattedDate2 ?? ''}`}
                onClick={() => handleCustomerInfoClick(userData)}
              />
            </Box>
          </BlockStack>
          <Bleed
            marginBlockEnd="400"
            marginInline="400"
          >
            <Box
              background="bg-surface-secondary"
              padding="400"
            >
              <BlockStack gap="200">
                <Text variant="bodySm">
                  {t('common:field.note')}: {t('settings:users.note')}
                </Text>
              </BlockStack>
            </Box>
          </Bleed>
        </Card>

        <Card>
          <InlineStack align="space-between">
            <Text
              as="h2"
              variant="headingSm"
            >
              {t('settings:users.staff_accounts')}
            </Text>
            <Button
              onClick={() => handleMode(MODES.FORM)}
              variant="plain"
            >
              <Typography variant="body_md_medium">{t('settings:users.create')}</Typography>
            </Button>
          </InlineStack>
          <div style={{ height: '1rem' }} />
          {renderMainTable()}
        </Card>
      </Stack>
    ) : mode === MODES.DETAIL_CUSTOMER ? (
      <StaffTable
        customerId={customer.customerId}
        handleClick={handleCustomerInfoClick}
      />
    ) : mode === MODES.DETAIL_LOCATION ? (
      <LocationTable
        locationId={location.locationId}
        handleClick={handleCustomerInfoClick}
        tabType={locTabType}
      />
    ) : (
      <UserForm
        onSuccess={() => handleMode(MODES.VIEW)}
        userData={data}
      />
    );
  };

  print('users_view load');

  if (searchLoading) {
    return <Loading />;
  }

  return (
    <Page
      fullWidth
      backAction={
        mode === MODES.FORM || mode === MODES.DETAIL_CUSTOMER || mode === MODES.DETAIL_LOCATION
          ? {
              content: t('Back'),
              onAction: () => handleMode(prevMode, MODES.VIEW),
            }
          : null
      }
      title={
        mode === MODES.VIEW
          ? t('settings:users.title')
          : mode === MODES.FORM
            ? Object.keys(data).length === 0
              ? t('settings:users.create')
              : t('settings:users.edit')
            : mode === MODES.DETAIL_CUSTOMER
              ? customer.name
              : mode === MODES.DETAIL_LOCATION
                ? location.name
                : ''
      }
      secondaryActions={
        mode === MODES.FORM &&
        !isEmpty(data) && [
          {
            content: t('settings:users.send_email_password_reset'),
            onAction: async () => {
              var validation = true;
              if (data.userId === userId) validation = false;
              if (!validation) {
                validation = await confirm({
                  title: t('settings:users.logged_out'),
                  content: t('settings:users.logged_out_body'),
                });
              }
              if (validation) {
                var res = await forgotPassword(data.loginId, getLongLangCode());
                if (res.isSuccess) {
                  if (data.userId === userId) await logout();
                  resetPasswordClick.onTrue();
                }
              }
            },
          },
        ]
      }
      additionalMetadata={
        (mode === MODES.DETAIL_CUSTOMER || mode === MODES.DETAIL_LOCATION) && (
          <Breadcrumb
            links={[
              {
                name: t('settings:users.title'),
                onClick: () => handleMode(MODES.VIEW),
              },
              {
                name:
                  mode === MODES.DETAIL_CUSTOMER
                    ? customer.name
                    : mode === MODES.DETAIL_LOCATION
                      ? location.name
                      : '',
              },
            ]}
          />
        )
      }
    >
      {mode === MODES.FORM && !isEmpty(data) && resetPasswordClick.value && (
        <>
          <Banner
            tone="success"
            onDismiss={resetPasswordClick.onFalse}
          >
            <Typography variant={'heading_sm'}>
              {t('settings:users.transfer_complete_to_email')}
            </Typography>
          </Banner>
          <div style={{ height: '1rem' }} />
        </>
      )}
      {renderUserPage()}
      <div className="bottom-padding"></div>
    </Page>
  );
}

import axiosInstance from 'src/utils/axios';
import { ENDPOINTS } from './endpoints';

export async function checkEmail(email) {
  const data = { email };
  var ret = await axiosInstance.post(ENDPOINTS.auth.check_email, data);
  return ret.data;
}

export async function login(email, password) {
  const data = { email, password };
  var ret = await axiosInstance.post(ENDPOINTS.auth.login, data);
  return ret.data;
}

export async function logout() {
  var ret = await axiosInstance.post(ENDPOINTS.auth.logout);
  return ret.data;
}

export async function refresh() {
  var ret = await axiosInstance.post(ENDPOINTS.auth.refresh);
  return ret.data;
}

export async function forgotPassword(email, languageCode) {
  const data = { email, languageCode };
  var ret = await axiosInstance.post(ENDPOINTS.auth.password_otp, data);
  return ret.data;
}

export async function resetPassword(newPassword, token) {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  const data = { newPassword };
  var ret = await axiosInstance.post(ENDPOINTS.auth.password_reset, data);
  delete axiosInstance.defaults.headers.common.Authorization;
  return ret.data;
}

import React, { useState, useRef, useEffect } from 'react';
import Navbar from './navbar';
import { Frame } from '@shopify/polaris';
import Topbar from './topbar';
import { PATHS } from 'src/routes/paths';
import SettingsModal from 'src/sections/settings/settings_modal';
import { Box } from '@mui/material';
import { lowerCaseWithoutSpace } from 'src/utils/format_data';
import { APP_NAME } from 'src/config';

const logo = {
  topBarSource: `/assets/logo/${lowerCaseWithoutSpace(APP_NAME)}_white.png`,
  width: 32,
  url: PATHS.dashboard.root,
};

export const addEditPadding = {
  px: { xs: 0, md: 5, lg: 10 },
};

export default function DashboardLayout({ children }) {
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const hasSettings = useRef(false);
  const [showSettings, setShowSettings] = useState(false);

  const toggleSettingsModal = () => {
    setIsSettingsModalOpen(!isSettingsModalOpen);
  };

  useEffect(() => {
    if (hasSettings.current) {
      setShowSettings(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSettings.current]);

  return (
    <Frame
      logo={logo}
      topBar={<Topbar />}
      navigation={
        <Navbar
          toggleSettingsModal={toggleSettingsModal}
          hasSettings={hasSettings}
        />
      }
    >
      <Box sx={{ pb: 5, position: 'relative' }}>{children}</Box>

      {showSettings && (
        <SettingsModal
          isOpen={isSettingsModalOpen}
          action={toggleSettingsModal}
        />
      )}
    </Frame>
  );
}

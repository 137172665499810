import { Box, Stack, Typography } from '@mui/material';
import { Card } from '@shopify/polaris';
import Image from '../image/image';

const EmptyStateCard = ({
  image,
  heading,
  body,
  children,
  center = false,
  card = true,
  width = 200,
  height = 100,
  pt = 2,
}) => {
  const renderContent = (
    <Box sx={{ p: 3, pb: 6 }}>
      {image && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pb: width === 200 ? 0 : 2,
          }}
        >
          <Image
            src={image}
            sx={{
              width: width,
              height: height,
            }}
          />
        </Box>
      )}
      <Stack
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        spacing={1}
      >
        <Typography variant={'heading_md'}>{heading}</Typography>
        {body && <Typography variant={'body_sm'}>{body}</Typography>}
        {children && (
          <Box
            sx={{
              minWidth: '400px',
              pt: pt,
              display: center ? 'flex' : null,
              alignItems: center ? 'center' : null,
              justifyContent: center ? 'center' : null,
            }}
          >
            {children}
          </Box>
        )}
      </Stack>
    </Box>
  );

  return card ? <Card>{renderContent}</Card> : renderContent;
};

export default EmptyStateCard;

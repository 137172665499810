import i18n from 'src/locales/i18n';
import { Badge } from 'src/components/badge/badge';
import BulkButton from 'src/components/bulk_button';
import { DeleteIcon } from '@shopify/polaris-icons';
import { deleteConfirm } from 'src/components/dialog/confirmation';
import { deleteMachine } from 'src/api/machine_api';

export const tabs = (count) => [
  {
    label: i18n.t('common:tab.all'),
    value: 'All',
    badge: <Badge>{count?.totalCount ?? '0'}</Badge>,
  },
];

export const bulkAction = (data, selectedIds, handleSelectionChange) => {
  var selectedData = [];
  for (var i = 0; i < data.length; i++) {
    if (selectedData.length === selectedIds.length) break;
    if (selectedIds.includes(data[i].machineId)) {
      selectedData.push(data[i]);
    }
  }
  if (selectedData.length === 0) return [];
  return [
    {
      content: (
        <BulkButton
          icon={DeleteIcon}
          content={i18n.t('common:delete')}
        />
      ),
      destructive: true,
      icon: DeleteIcon,
      onAction: async () => {
        const confirmation = await deleteConfirm();
        if (confirmation) {
          await deleteMachine(selectedData);
          handleSelectionChange('page', false);
        }
      },
    },
  ];
};

export const headers = () => {
  return [
    { title: i18n.t('common:field.machine_name') },
    { title: i18n.t('common:field.location') },
    { title: i18n.t('common:field.machine_type') },
    { title: i18n.t('common:field.endpoint_qty') },
  ];
};

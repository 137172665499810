import { Icon, InlineStack } from '@shopify/polaris';
import { Box, Typography } from '@mui/material';

const BulkButton = ({ content, icon, tone = 'base' }) => {
  return (
    <Box paddingInline={'var(--p-space-100)'}>
      <InlineStack
        gap={100}
        blockAlign="center"
      >
        <Box
          width="var(--p-space-400)"
          height="var(--p-space-400)"
        >
          <Icon
            source={icon}
            tone={`${tone} !important`}
          />
        </Box>
        <Typography
          color={tone !== 'base' ? (tone !== '' ? tone : 'rgba(144, 35, 51, 1)') : 'base'}
          variant="body_sm"
        >
          {content}
        </Typography>
      </InlineStack>
    </Box>
  );
};

export default BulkButton;

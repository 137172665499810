import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import GuestGuard from 'src/layouts/guard/guest_guard';
import AuthLayout from 'src/layouts/auth/auth_layout';

const LoginPage = lazy(() => import('src/pages/auth/login_page'));
const EmailPage = lazy(() => import('src/pages/auth/email_page'));
const ForgotPasswordPage = lazy(() => import('src/pages/auth/forgot_password_page'));
const ResetPasswordPage = lazy(() => import('src/pages/auth/reset_password_page'));
const ChooseLocationPage = lazy(() => import('src/pages/auth/choose_location_page'));

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <Suspense>
          <Outlet />
        </Suspense>
      </GuestGuard>
    ),
    children: [
      {
        path: 'login',
        element: (
          <AuthLayout>
            <LoginPage />
          </AuthLayout>
        ),
      },
      {
        path: 'email',
        element: (
          <AuthLayout>
            <EmailPage />
          </AuthLayout>
        ),
      },
      {
        path: 'forgot-password',
        element: (
          <AuthLayout>
            <ForgotPasswordPage />
          </AuthLayout>
        ),
      },
      {
        path: 'reset-password',
        element: (
          <AuthLayout>
            <ResetPasswordPage />
          </AuthLayout>
        ),
      },
      {
        path: 'choose-location',
        element: (
          <AuthLayout>
            <ChooseLocationPage />
          </AuthLayout>
        ),
      },
    ],
  },
];

import { confirmable } from 'react-confirm';
import { useTranslation } from 'react-i18next';
import StackedModal from './stacked_modal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { TextField } from '@shopify/polaris';
import i18n from 'src/locales/i18n';
import { fStringToStringDate } from 'src/utils/format_time';

function StockDialog({ show, proceed, title, data }) {
  const { t } = useTranslation(['common', 'transfer']);

  const expirationDate = data?.expirationDt;

  const [inputValue, setInputValue] = useState({
    lotNumber: data?.lotNumber,
    expirationDt: data?.expirationDt,
    quantity: 0,
    onHand: data?.onHandQuantity,
  });

  const defaultInputValue = useMemo(
    () => ({
      lotNumber: data?.lotNumber,
      expirationDt: fStringToStringDate(data?.expirationDt) || '',
      quantity: 0,
      onHand: data?.onHandQuantity,
    }),
    [data],
  );

  const isDirty = useMemo(() => {
    return (
      inputValue.lotNumber !== defaultInputValue.lotNumber ||
      inputValue.expirationDt !== defaultInputValue.expirationDt ||
      inputValue.quantity !== defaultInputValue.quantity ||
      inputValue.onHand !== defaultInputValue.onHand
    );
  }, [inputValue, defaultInputValue]);

  useEffect(() => {
    if (expirationDate) {
      setInputValue((oldValue) => ({
        ...oldValue,
        expirationDt: fStringToStringDate(expirationDate),
      }));
    } else {
      setInputValue((oldValue) => ({ ...oldValue, expirationDt: '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [error, setError] = useState('');

  const [hasBlurred, setHasBlurred] = useState(false);

  const handleChange = useCallback((newValue, type) => {
    if (type === 'lot') setInputValue((oldValue) => ({ ...oldValue, lotNumber: newValue }));
    if (type === 'exp') setInputValue((oldValue) => ({ ...oldValue, expirationDt: newValue }));
    if (type === 'quant') setInputValue((oldValue) => ({ ...oldValue, quantity: newValue }));
    if (type === 'onHand') setInputValue((oldValue) => ({ ...oldValue, onHand: newValue }));
  }, []);

  const checkDate = (date) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(date)) {
      return false;
    }
    const [year, month, day] = date.split('-').map(Number);
    const isValidDate =
      new Date(year, month - 1, day).getFullYear() === year &&
      new Date(year, month - 1, day).getMonth() === month - 1 &&
      new Date(year, month - 1, day).getDate() === day;

    return isValidDate;
  };

  const handleChangeDate = (e) => {
    const newValue = e;
    handleChange(newValue, 'exp');
    const isPartialYYYYMMDD = /^\d{0,8}$/;
    const isPartialISODate = /^\d{4}-?\d{0,2}-?\d{0,2}$/;
    if (isPartialYYYYMMDD.test(newValue) || isPartialISODate.test(newValue)) {
      if (newValue.length === 8 && /^\d{8}$/.test(newValue)) {
        const formattedDate = `${newValue.slice(0, 4)}-${newValue.slice(4, 6)}-${newValue.slice(6, 8)}`;
        handleChange(formattedDate, 'exp');
        setError('');
      } else if (newValue.length === 10 && /^\d{4}-\d{2}-\d{2}$/.test(newValue)) {
        handleChange(newValue, 'exp');
        setError('');
      }
    } else if (hasBlurred) {
      setError('Follow YYYY-MM-DD or YYYYMMDD format');
    } else {
      handleChange(null, 'exp');
    }
  };

  const handleBlur = () => {
    setHasBlurred(true);
    if (inputValue?.expirationDt && !checkDate(inputValue?.expirationDt)) {
      setError('Follow YYYY-MM-DD or YYYYMMDD format');
    }
  };

  return (
    <StackedModal
      isOpen={show}
      title={title || t('common:confirm')}
      content={
        <Box
          display={'flex'}
          flexDirection={'row'}
          width={'100%'}
          columnGap={2}
        >
          <Box
            display={'flex'}
            width="25%"
            flexDirection={'column'}
          >
            <Typography
              variant="body_md_semibold"
              style={{ paddingBottom: '12px' }}
            >
              {i18n.t('common:field.lot_number')}
            </Typography>
            <TextField
              multiline={false}
              rows={1}
              value={inputValue?.lotNumber}
              placeholder={i18n.t('common:field.lot_number')}
              onChange={(newValue) => {
                handleChange(newValue, 'lot');
              }}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            width="25%"
          >
            <Typography
              variant="body_md_semibold"
              style={{ paddingBottom: '12px' }}
            >
              {i18n.t('common:field.expiration_date')}
            </Typography>
            <TextField
              multiline={false}
              placeholder={i18n.t('common:field.expiration_date')}
              rows={1}
              value={inputValue?.expirationDt}
              onBlur={handleBlur}
              error={error}
              onChange={handleChangeDate}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            width="25%"
          >
            <Typography
              variant="body_md_semibold"
              style={{ paddingBottom: '12px' }}
            >
              {i18n.t('common:field.change_quantity')}
            </Typography>
            <TextField
              multiline={false}
              placeholder={i18n.t('common:field.change_quantity')}
              rows={1}
              value={inputValue?.quantity}
              onChange={(newValue) => {
                handleChange(newValue, 'quant');
              }}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            width="25%"
          >
            <Typography
              variant="body_md_semibold"
              style={{ paddingBottom: '12px' }}
            >
              {i18n.t('common:field.on_hand')}
            </Typography>
            <TextField
              multiline={false}
              placeholder={i18n.t('common:field.on_hand')}
              rows={1}
              value={inputValue?.onHand}
              disabled
            />
          </Box>
        </Box>
      }
      primaryAction={{
        content: t('common:confirm'),
        disabled: !isDirty || error,
        onAction: () => {
          proceed({ confirmed: true, value: inputValue });
        },
      }}
      secondaryAction={{
        content: t('common:cancel'),
        onAction: () => {
          proceed({ confirmed: false, value: null });
        },
      }}
      onClose={() => {
        proceed({ confirmed: false, value: null });
      }}
    />
  );
}

export default confirmable(StockDialog);

import { Text, Box, InlineStack, Button } from '@shopify/polaris';
import { ConnectIcon } from '@shopify/polaris-icons';
import Image from '../image/image';

const CarrierInfo = ({ avatar, name, description, onButtonClick, buttonText }) => {
  return (
    <Box background="bg-transparent">
      <InlineStack
        blockAlign="center"
        align="space-between"
      >
        <InlineStack
          blockAlign="center"
          align="start"
        >
          <Image
            src={avatar}
            sx={{
              width: '2.5rem',
              height: '2.5rem',
            }}
          />
          <Box paddingInlineStart="200">
            <Text
              variant="bodyMd"
              fontWeight="bold"
              as="h3"
            >
              {name}
            </Text>
            <Text variant="bodySm">{description}</Text>
          </Box>
        </InlineStack>

        <Button
          onClick={() => {
            onButtonClick();
          }}
          icon={ConnectIcon}
        >
          {buttonText}
        </Button>
      </InlineStack>
    </Box>
  );
};

export default CarrierInfo;

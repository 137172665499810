import { useEffect, useState } from 'react';
import { headers, tabs, bulkAction, EmptyStateMarkup } from './pack_mat_table_misc';
import rowMarkup from './pack_mat_table_row';
import useTable from 'src/hooks/use_table';
import Table from 'src/components/table/table';
import { useGetPackingMaterials } from 'src/api/packing_materials_api';
import { cleanParams } from 'src/utils/url';

export default function PackManTableView({ selectedLocation, handleRowClick, handleNewForm }) {
  const locationId = selectedLocation === 'all' ? '' : selectedLocation;
  const [params, setParams] = useState({
    pageSize: 20,
    pageNumber: 1,
    locationId: locationId,
    IsActive: true,
  });

  const {
    searchResults: data = [],
    searchCount: count = {},
    searchLoading,
  } = useGetPackingMaterials(cleanParams(params));

  const table = useTable({
    tabItems: tabs(count),
    totalDataCount: count?.allCount,
    data: data,
    headers: headers(),
    rowMarkup: (props) => rowMarkup(props, handleRowClick),
    bulkAction: bulkAction,
    resourceIdResolver: (d) => d.packingMaterialId,
    emptyStateMarkup: <EmptyStateMarkup handleNewForm={handleNewForm} />,
    showSearchFilter: false,
  });

  if (table.isRefetch.value) {
    setParams((prevParams) => ({
      ...prevParams,
      pageSize: table.rowsPerPage,
      pageNumber: table.page,
      locationId: locationId,
    }));
    table.onRefetched();
  }

  useEffect(() => {
    table.isRefetch.onTrue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  return (
    <Table
      useTable={table}
      isLoading={searchLoading}
    />
  );
}

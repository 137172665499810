import _ from 'lodash';

export function isEmpty(value) {
  if (_.isArray(value)) {
    return value.length === 0;
  } else if (_.isDate(value)) {
    return false;
  } else if (isObject(value)) {
    return _.isEmpty(value);
  } else {
    return value === '' || value == null;
  }
}

export function isStringEmpty(value) {
  if (value === '' || value === ' ') {
    return true;
  } else {
    return false;
  }
}

export function isNumber(value) {
  return _.isNumber(value);
}

export function isInteger(value) {
  return _.isInteger(value);
}

export function isString(value) {
  return _.isString(value);
}

export function isArray(value) {
  return _.isArray(value);
}

export function isObject(value) {
  return _.isObject(value);
}

export function isBoolean(value) {
  return _.isBoolean(value);
}

export function toBool(value) {
  if (isBoolean(value)) return value;
  if (value === 1) return true;
  if (value === 0) return false;
  if (value === '1') return true;
  if (value === '0') return false;
  if (value.toLowerCase() === 'false') return false;
  if (value.toLowerCase() === 'true') return true;
}

export function onlyContains(set, values) {
  var ret = true;
  set.forEach((element) => {
    if (!values.includes(element)) {
      ret = false;
    }
  });

  return ret;
}

export function cloneAndCleanIsActive(params, id) {
  var clone = _.cloneDeep(params);
  if (!isEmpty(id)) delete clone.isActive;
  return clone;
}

export function isEmptyObject(obj) {
  return Object.values(obj).every((value) => value === '');
}

export function isZeroObject(obj) {
  return Object.values(obj).every((value) => value === 0);
}

export function deepEqual(a, b, skip = '') {
  if (a === b) return true;

  if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) {
    return false;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  for (let key of keysA) {
    if (key === skip) {
      continue;
    }
    if (!keysB.includes(key)) return false;
    if (!deepEqual(a[key], b[key])) return false;
  }
  return true;
}

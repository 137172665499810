import { Box, Card, Divider } from '@shopify/polaris';
import { LocationInfoCard } from 'src/components/cards/location_info';
import { print } from 'src/utils/log';
import { useGetUserLocations } from 'src/api/mapping_api';
import Loading from 'src/components/loading';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function LocationList({ customerId }) {
  const { t } = useTranslation(['settings']);
  const { searchResults: mappings = [], searchLoading: mapLoading } = useGetUserLocations({
    UserId: customerId,
    IsMapping: true,
  });

  print('location_customer_list load');

  if (mapLoading) return <Loading />;
  return (
    <Card padding={0}>
      {(mappings.length === 0 || !mappings) && (
        <Card>
          <Box paddingBlockEnd={200}>
            <Typography variant="heading_sm">{t('settings:locations.no_locations')}</Typography>
          </Box>
          <Typography variant="body_md">
            {t('settings:locations.add_location_description')}
          </Typography>
        </Card>
      )}
      {mappings.map((loc) => (
        <Box key={loc.locationId}>
          <Box padding={300}>
            <LocationInfoCard
              key={loc.locationId}
              id={loc.locationId}
              loc={loc}
            />
          </Box>
          <Divider />
        </Box>
      ))}
    </Card>
  );
}

import { Box } from '@mui/material';
import { Badge, IndexTable } from '@shopify/polaris';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { toast } from 'react-toastify';
import { deleteConfirm, exportConfirm } from 'src/components/dialog/confirmation';
import Table from 'src/components/table/table';
import useTable from 'src/hooks/use_table';
import i18n from 'src/locales/i18n';
import { print } from 'src/utils/log';
import { fDate, fStringToStringDate } from 'src/utils/format_time';
import { couriersExport, deleteTrackingNumberBulk, useGetCourierRange } from 'src/api/courier_api';
import { DeleteIcon } from '@shopify/polaris-icons';
import { cleanParams } from 'src/utils/url';
import BulkButton from 'src/components/bulk_button';

const headers = () => {
  var trackingNumber = { title: i18n.t('common:field.tracking_number') };
  var avaliable = { title: i18n.t('common:field.avaliable') };
  return [trackingNumber, avaliable];
};

const bulkAction = (data, selectedIds, handleSelectionChange, locationId, courierId) => {
  return [
    {
      content: (
        <BulkButton
          icon={DeleteIcon}
          content={i18n.t('common:delete')}
        />
      ),

      destructive: true,
      onAction: async () => {
        const confirmed = await deleteConfirm();
        if (confirmed) {
          try {
            const count = await deleteTrackingNumberBulk(selectedIds, locationId, courierId);
            if (count.failed > 0) {
              toast.success(i18n.t('settings:mapping.successful_delete_tracking_number'));
              handleSelectionChange('page', false, selectedIds, '');
            } else {
              toast.error(
                i18n.t('settings:mapping.failed_delete_count', {
                  all: selectedIds.length,
                  success: count.success,
                  failed: count.failed,
                }),
              );
            }
          } catch (err) {
            toast.error(err.toString());
          }
        }
      },
    },
  ];
};

const rowMarkup = (props) => {
  return props.data.map((d, i) => {
    return (
      <Row
        key={d.trackingNumber}
        data={d}
        index={i}
        selectedItems={props.selectedItems}
      />
    );
  });
};

const Row = ({ data, index, selectedItems }) => {
  const key = data?.trackingNumber;

  return (
    <IndexTable.Row
      id={key}
      key={key}
      selected={selectedItems.includes(key)}
      position={index}
    >
      <>
        <Box component={IndexTable.Cell}>{data?.trackingNumber}</Box>
        <Box component={IndexTable.Cell}>
          {
            <Badge tone={data?.isAvailable ? 'success' : 'critical'}>
              {data?.isAvailable ? 'Avaliable' : 'Unavaliable'}
            </Badge>
          }
        </Box>
      </>
    </IndexTable.Row>
  );
};

const tabs = (count) => {
  var total = {
    label: i18n.t('common:field.total'),
    value: '',
    onClick: () => {},
    badge: <Badge>{count.totalCount}</Badge>,
  };
  var avaliable = {
    label: i18n.t('common:field.avaliable'),
    value: true,
    onClick: () => {},
    badge: <Badge>{count.availableCount}</Badge>,
  };
  var unavaliable = {
    label: i18n.t('common:field.unavaliable'),
    value: false,
    onClick: () => {},
    badge: <Badge>{count.unavailableCount}</Badge>,
  };
  return [total, avaliable, unavaliable];
};

const CourierRangeTableView = forwardRef(({ selectedLocation, type, courierId, tableRef }) => {
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    isAvailable: '',
  });

  const {
    searchCount,
    list: data,
    searchLoading,
  } = useGetCourierRange(selectedLocation, courierId, cleanParams(params));

  const table = useTable({
    data: data ?? [],
    headers: headers(),
    tabItems: tabs(searchCount ?? {}),
    defaultTab: type,
    defaultRowsPerPage: 10,
    rowMarkup: (props) => rowMarkup(props),
    showSearchFilter: false,
    totalDataCount:
      params.isAvailable === true
        ? searchCount.availableCount
        : params.isAvailable === false
          ? searchCount.unavailableCount
          : searchCount.totalCount,
    bulkAction: (data, selectedIds, handleSelectionChange) =>
      bulkAction(data, selectedIds, handleSelectionChange, selectedLocation, courierId),
    selectable: true,
    resourceIdResolver: (d) => d.trackingNumber,
  });

  useImperativeHandle(tableRef, () => ({
    async exportData() {
      let param = {
        locationId: selectedLocation,
        courierId: courierId,
        pageNumber: 1,
        pageSize: 50000,
      };

      const currentPageData = await couriersExport({
        ...params,
        locationId: selectedLocation,
        courierId: courierId,
      });
      await exportConfirm({
        pluralName: i18n.t('settings:mapping.tracking_number_range').toLowerCase(),
        singularName: i18n.t('settings:mapping.tracking_number_ranges').toLowerCase(),
        exportData: couriersExport,
        params: param,
        data: currentPageData,
        multipleItem: true,
        exportSelect: false,
        currentPage: false,
        excelData: (data) => {
          const rows =
            {
              'Courier Id': data?.courierId ?? '',
              'Tracking Number': data?.trackingNumber ?? '',
              'Is in use': data?.isUse ?? 'false',
              'Is Active': data?.isActive ?? 'false',
              'Created Date': data?.createdDate
                ? fStringToStringDate(data?.createdDate)
                  ? fStringToStringDate(data?.createdDate)
                  : fDate(data?.createdDate)
                    ? fDate(data?.createdDate)
                    : ''
                : '',
            } ?? [];
          return rows;
        },
      });
    },
  }));

  if (table.isRefetch.value) {
    setParams({
      pageNumber: table.page,
      pageSize: table.rowsPerPage,

      isAvailable: table.tabItems[table.tabIndex].value,
    });
    table.onRefetched();
  }

  print('users location table load');

  return (
    <Table
      useTable={table}
      isLoading={searchLoading}
    />
  );
});

export default CourierRangeTableView;

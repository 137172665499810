import { Page, Button, Card } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Loading from 'src/components/loading';
import { chooseLan } from 'src/utils/language_code';
import { toast } from 'react-toastify';
import ChannelForm from 'src/sections/settings/channels/channel_form';
import { print } from 'src/utils/log';
import { getURL } from 'src/utils/url';
import { mutate } from 'swr';
import { ENDPOINTS } from 'src/api/endpoints';
import EmptyStateCard from 'src/components/cards/empty_state_card';
import { useGetChannels } from 'src/api/channels_api';
import ChannelTableView from 'src/sections/channel/view/channel_table_view';
import { deleteConfirm } from 'src/components/dialog/confirmation';
import { deleteClient } from 'src/api/client_api';
import { useGetMe } from 'src/api/user_api';

const MODES = {
  VIEW: 'view',
  FORM: 'form',
};

export default function ChannelView() {
  const [mode, setMode] = useState(MODES.VIEW);
  const [channelData, setChannelData] = useState({});
  const { customerId } = useGetMe();
  const onFormSuccess = () => {
    setChannelData({});
    mutate(currentURL);
    setMode(MODES.VIEW);
  };

  const handleMode = (channelData) => {
    setChannelData(channelData);
    setMode(mode === MODES.VIEW ? MODES.FORM : MODES.VIEW);
  };

  const { t } = useTranslation(['settings', 'validation']);
  const [params] = useState({
    pageNumber: 1,
    pageSize: 20,
    ClientType: 'channel',
    IsMapping: true,
    isActive: true,
    userId: customerId,
  });

  const {
    searchResults: channels = [],
    searchLoading: loading,
    searchError: error,
  } = useGetChannels(params);

  const currentURL = getURL(ENDPOINTS.mapping.get_channels, params);

  const handleDelete = async () => {
    try {
      const confirmation = await deleteConfirm();
      if (confirmation) {
        await deleteClient(channelData?.clientId);
        toast.success(t('settings:channels.delete_success'));
        onFormSuccess();
      }
    } catch (error) {
      console.log(error);
      toast.error(typeof error === 'string' ? error : error.message);
    }
  };

  const renderChannels = () => {
    if (error) {
      return <div> {error}</div>;
    }
    if (loading) {
      return <Loading />;
    }
    if (channels.length === 0) {
      return (
        <EmptyStateCard
          heading={t('settings:channels.no_channels')}
          body={t('settings:channels.channels_description')}
          image="/images/alertIcon.png"
          center
        >
          <Button
            variant={'secondary'}
            onClick={() => {}}
          >
            {t('settings:channels.register_channels')}
          </Button>
        </EmptyStateCard>
      );
    }
    return (
      <Card padding={0}>
        <ChannelTableView
          customerId={customerId}
          handleMode={handleMode}
        />
      </Card>
    );
  };

  const renderChannelPage = () => {
    return mode === MODES.VIEW ? (
      renderChannels()
    ) : (
      <ChannelForm
        onSuccess={onFormSuccess}
        channelData={channelData}
      />
    );
  };
  print('channel_view page');

  return (
    <Page
      fullWidth
      title={
        mode === MODES.VIEW
          ? t('settings:channels.title')
          : channelData && channelData.clientId
            ? chooseLan(channelData.clientNameLocal, channelData.clientNameEnglish)
            : t('settings:channels.new_channel')
      }
      backAction={mode === MODES.FORM ? { content: t('common:Back'), onAction: handleMode } : null}
      primaryAction={
        mode === MODES.VIEW ? (
          <Button
            variant="primary"
            onClick={handleMode}
          >
            {t('settings:channels.create')}
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={handleDelete}
          >
            {t('settings:channels.delete')}
          </Button>
        )
      }
    >
      {renderChannelPage()}
    </Page>
  );
}

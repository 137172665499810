import { Typography } from '@mui/material';
import { IndexTable } from '@shopify/polaris';
import { chooseLan } from 'src/utils/language_code';
import { fDateTime } from 'src/utils/format_time';

const RowMarkup = ({ data }) => {
  return data.map(
    (
      {
        changeDate,
        actionTypeCode,
        actionType,
        reason,
        adjustedByLocal,
        adjustedByEnglish,
        adjustment,
      },
      index,
    ) => {
      return (
        <IndexTable.Row
          id={index}
          key={index}
          position={index}
          onClick={() => {}}
        >
          <IndexTable.Cell>{fDateTime(changeDate)}</IndexTable.Cell>
          <IndexTable.Cell>
            {actionTypeCode} {actionType}
          </IndexTable.Cell>
          <IndexTable.Cell>{reason}</IndexTable.Cell>
          <IndexTable.Cell>{chooseLan(adjustedByLocal, adjustedByEnglish)}</IndexTable.Cell>
          <IndexTable.Cell>
            <Typography
              variant="body_md"
              color={actionTypeCode === '+' ? 'text.success' : 'text.critical'}
            >
              {actionTypeCode} {adjustment}
            </Typography>
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    },
  );
};
export default RowMarkup;

import { confirmable } from 'react-confirm';
import { useTranslation } from 'react-i18next';
import StackedModal from './stacked_modal';

function ConfirmDialog({ show, proceed, title, content }) {
  const { t } = useTranslation('common');
  return (
    <StackedModal
      isOpen={show}
      title={title || t('common:confirm')}
      content={content || t('common:confirm_confirmation')}
      primaryAction={{
        content: t('common:confirm'),
        onAction: () => {
          proceed(true);
        },
      }}
      secondaryAction={{
        content: t('common:cancel'),
        onAction: () => {
          proceed(false);
        },
      }}
      onClose={() => {
        proceed(false);
      }}
    />
  );
}

export default confirmable(ConfirmDialog);

export const WEIGHT_UNIT = [
  {
    codeValue: 'kg',
    codeValueNameLocal: '킬로그램',
    codeValueNameEnglish: 'Kilogram',
    displayOrder: 1,
    codeReference1: 'kg',
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
  {
    codeValue: 'g',
    codeValueNameLocal: '그램',
    codeValueNameEnglish: 'Gram',
    displayOrder: 2,
    codeReference1: 'g',
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
  {
    codeValue: 'lb',
    codeValueNameLocal: '파운드',
    codeValueNameEnglish: 'Pound',
    displayOrder: 3,
    codeReference1: 'lb',
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
  {
    codeValue: 't',
    codeValueNameLocal: '톤',
    codeValueNameEnglish: 'Ton',
    displayOrder: 4,
    codeReference1: 't',
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
  {
    codeValue: 'oz',
    codeValueNameLocal: '온스',
    codeValueNameEnglish: 'Ounce',
    displayOrder: 5,
    codeReference1: 'oz',
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
  {
    codeValue: 'st',
    codeValueNameLocal: '스톤',
    codeValueNameEnglish: 'Stone',
    displayOrder: 6,
    codeReference1: 'st',
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
];

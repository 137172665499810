import { chooseLan } from './language_code';
import { isEmpty } from './type_check';

export function getUserName(userData) {
  if (!userData) return '';
  const { userFirstNameEnglish, userLastNameEnglish, userFirstNameLocal, userLastNameLocal } =
    userData;

  const firstName = chooseLan(userFirstNameLocal, userFirstNameEnglish);
  const lastName = chooseLan(userLastNameLocal, userLastNameEnglish);

  const userName = firstName || lastName ? `${firstName} ${lastName}` : '';

  return (userName ?? '').trim();
}

export function getUserInitial(userData) {
  var userName = getUserName(userData);
  if (userName.length === 0) return '';
  return userName[0].toUpperCase();
}

export function getLocationName(locData) {
  if (!locData) return '';
  const { address1Local, address2Local, address1English, address2English } = locData;

  const address1 = chooseLan(address1Local, address1English);
  const address2 = chooseLan(address2Local, address2English);

  const addressString =
    address1 && address2
      ? `${address1}, ${address2}`
      : address1 || address2
        ? `${address1} ${address2}`
        : null;

  return (addressString ?? '').trim();
}

export function requiredField(data) {
  return `* ${data}`;
}

export function getDirtyFields(dirtyFields, data) {
  const res = Object.keys(dirtyFields)
    .filter((key) => dirtyFields[key])
    .reduce((acc, key) => {
      acc[key] = data[key];
      return acc;
    }, {});
  return res;
}

export function makeCombinedId(value1, value2) {
  if (isEmpty(value1)) {
    value1 = '';
  }
  if (isEmpty(value2)) {
    value2 = '';
  }

  return `${value1}_WITH_${value2}`;
}

export function getCombinedIds(combinedId) {
  return combinedId ? combinedId.split('_WITH_') : '';
}

export function lowerCaseWithoutSpace(str) {
  str = str.replace(/\s+/g, '');
  return str.toLowerCase();
}

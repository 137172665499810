import { Stack } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import NavList from './nav_list';

function NavSectionVertical({ data, sx, currentNav }) {
  const [openIndex, setOpenIndex] = useState(null);

  useEffect(() => {
    const selectedIndex = data.findIndex((item) => item.key === currentNav);
    if (selectedIndex !== -1) {
      setOpenIndex(selectedIndex);
    }
  }, [data, currentNav]);

  const handleNavListToggle = (index, open) => {
    setOpenIndex((prevIndex) => (open ? index : null));
  };

  const renderContent = data.map((list, index) => {
    return (
      <NavList
        key={index}
        data={list}
        depth={0}
        hasChild={!!list.children}
        isSelected={currentNav}
        open={openIndex === index}
        onToggle={(open) => handleNavListToggle(index, open)}
        setOpenIndex={setOpenIndex}
        index={index}
      />
    );
  });

  return <Stack sx={sx}>{renderContent}</Stack>;
}

export default memo(NavSectionVertical);

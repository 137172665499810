import { merge } from 'lodash';
import TextField from './textfield';
import Alert from './alert';
import Progress from './linear_progress';

// ----------------------------------------------------------------------

export function componentsOverrides(theme) {
  const components = merge(TextField(theme), Alert(theme), Progress(theme));

  return components;
}

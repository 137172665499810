import { confirmable } from 'react-confirm';
import StackedModal from './stacked_modal';
import { useTranslation } from 'react-i18next';

function DisconnectDialog({ show, proceed, title, content }) {
  const { t } = useTranslation();
  return (
    <StackedModal
      isOpen={show}
      title={title || t('common:disconnect')}
      content={content || t('common:disconnect_confirmation')}
      primaryAction={{
        content: t('common:disconnect'),
        onAction: () => {
          proceed(true);
        },
      }}
      secondaryAction={{
        content: t('common:cancel'),
        onAction: () => {
          proceed(false);
        },
      }}
      onClose={() => {
        proceed(false);
      }}
    />
  );
}

export default confirmable(DisconnectDialog);

export const COUNTY_CODE_SHORT = [
  {
    codeValue: 'KR',
    codeValueNameLocal: '대한민국',
    codeValueNameEnglish: 'Republic of Korea',
    displayOrder: 122,
    codeReference1: 'KOR',
    codeReference2: '410',
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
  {
    codeValue: 'US',
    codeValueNameLocal: '미국',
    codeValueNameEnglish: 'United States of America',
    displayOrder: 233,
    codeReference1: 'USA',
    codeReference2: '840',
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
  {
    codeValue: 'JP',
    codeValueNameLocal: '일본',
    codeValueNameEnglish: 'Japan',
    displayOrder: 114,
    codeReference1: 'JPN',
    codeReference2: '392',
    codeReference3: 'JPY',
    codeReference4: null,
    description: null,
    isActive: true,
  },
];

import { Alert } from '@mui/material';

export const LogInAlert = ({ onClose, errorMsg, sx = {}, onClick = null }) => {
  return (
    <Alert
      sx={{
        color: '#902333',
        backgroundColor: '#FDE1E6',
        '& .MuiAlert-icon': {
          color: '#902333',
        },
        paddingY: 0,
        borderRadius: '8px',
        ...sx,
      }}
      onClose={onClose}
      onClick={onClick}
      severity="error"
    >
      {errorMsg}
    </Alert>
  );
};

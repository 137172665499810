export const ORDER_SHIPMENT_STATUS = [
  {
    codeValue: 'Picking',
    codeValueNameLocal: '피킹',
    codeValueNameEnglish: 'Picking',
    displayOrder: 1,
    codeReference1: 'true',
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: 'CodeReference1:issue변경가능여부',
    isActive: true,
  },
  {
    codeValue: 'Packing',
    codeValueNameLocal: '패킹',
    codeValueNameEnglish: 'Packing',
    displayOrder: 2,
    codeReference1: 'true',
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: 'CodeReference1:issue변경가능여부',
    isActive: true,
  },
  {
    codeValue: 'Shipping',
    codeValueNameLocal: '패킹완료',
    codeValueNameEnglish: 'Shipping',
    displayOrder: 3,
    codeReference1: 'false',
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: 'CodeReference1:issue변경가능여부',
    isActive: true,
  },
  {
    codeValue: 'Shipped',
    codeValueNameLocal: '출고완료',
    codeValueNameEnglish: 'Shipped',
    displayOrder: 4,
    codeReference1: 'false',
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: 'CodeReference1:issue변경가능여부',
    isActive: true,
  },
  {
    codeValue: 'Issue',
    codeValueNameLocal: '이슈',
    codeValueNameEnglish: 'Issue',
    displayOrder: 5,
    codeReference1: 'false',
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: 'CodeReference1:issue변경가능여부',
    isActive: true,
  },
];

import { Box, Stack, Typography } from '@mui/material';
import { Card, Icon, TextField, InlineStack } from '@shopify/polaris';
import { BarcodeIcon } from '@shopify/polaris-icons';
import { useState } from 'react';
import Image from '../image/image';

const ScanCard = ({
  transparent,
  onEnter,
  heading,
  headingTag,
  image = '/images/search_icon.png',
  body,
  label,
  placeholder,
  node,
  bottomPadding = 6,
  error,
  setError,
  width = 200,
  height = 100,
}) => {
  const [search, setSearch] = useState('');

  const renderContent = (
    <Box sx={{ p: 3, pb: bottomPadding }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pb: width === 200 ? 0 : 2,
        }}
      >
        <Image
          src={image}
          sx={{
            width: width,
            height: height,
          }}
        />
      </Box>
      <Stack
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        spacing={1}
      >
        <InlineStack gap={200}>
          <Typography variant={'heading_md'}>{heading}</Typography>
          {headingTag}
        </InlineStack>
        <Typography variant={'body_sm'}>{body}</Typography>
        <Box sx={{ pt: 1, width: '400px' }}>
          <div
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onEnter(search);
                setSearch('');
              }
            }}
          >
            <TextField
              label={label}
              value={search}
              onChange={(val) => {
                setError && setError();
                setSearch(val);
              }}
              prefix={<Icon source={BarcodeIcon} />}
              autoComplete="off"
              size="slim"
              focused={true}
              placeholder={placeholder}
              error={error}
            />
          </div>
        </Box>
        {node && node}
      </Stack>
    </Box>
  );
  return transparent ? <Box>{renderContent}</Box> : <Card>{renderContent}</Card>;
};

export default ScanCard;

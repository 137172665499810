import { IndexTable } from '@shopify/polaris';
import { changeKoreaTitle, chooseLan } from 'src/utils/language_code';
import { Typography } from '@mui/material';

const RowMarkup = ({ data, selectedItems, handleMode }) => {
  return data.map((channel, index) => {
    const {
      clientId: id,
      clientNameLocal,
      clientNameEnglish,
      countryCodeNameLocal,
      countryCodeNameEnglish,
      representativeName,
      representativeEmail,
      representativeTelNumber,
    } = channel;

    return (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedItems.includes(id)}
        position={index}
        onClick={() => handleMode(channel)}
      >
        <IndexTable.Cell>
          <div className="black-button">
            <Typography variant="body_md_medium">
              {chooseLan(clientNameLocal, clientNameEnglish)}
            </Typography>
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>{representativeName}</IndexTable.Cell>
        <IndexTable.Cell>{representativeTelNumber}</IndexTable.Cell>
        <IndexTable.Cell>{representativeEmail}</IndexTable.Cell>
        <IndexTable.Cell>
          {changeKoreaTitle(chooseLan(countryCodeNameLocal, countryCodeNameEnglish))}
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });
};
export default RowMarkup;

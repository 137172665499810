import i18n from 'src/locales/i18n';
import { Typography } from '@mui/material';
import { Box } from '@shopify/polaris';

i18n.loadNamespaces(['settings']);

export const headers = () => {
  return [
    { title: i18n.t('common:field.date') },
    { title: i18n.t('common:field.action') },
    { title: i18n.t('common:field.reason') },
    { title: i18n.t('common:field.adjusted_by') },
    { title: i18n.t('common:field.adjustment') },
  ];
};

export const EmptyStateMarkup = () => {
  return (
    <Box>
      <Typography variant="body_md">
        {i18n.t('settings:packing_material.form.no_history')}
      </Typography>
    </Box>
  );
};

import React, { useState, useCallback, useMemo } from 'react';
import { Icon, Button, Autocomplete } from '@shopify/polaris';
import { SearchIcon } from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import { useGetUsersWithParams } from 'src/api/user_api';
import { getUserName } from 'src/utils/format_data';
import { XCircleIcon } from '@shopify/polaris-icons';
import i18n from 'src/locales/i18n';

const UserAutocomplete = ({ onSelect }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState({});
  i18n.loadNamespaces(['settings']);
  const { t } = useTranslation();

  const { searchResults: users = [], searchLoading: loading } = useGetUsersWithParams({
    pageSize: 10,
    pageNumber: 1,
    isActive: true,
    roleType: 'customer',
    userName: searchQuery,
  });

  const onChangeSearchQuery = useCallback((value) => {
    setSearchQuery(value);
  }, []);

  const options = useMemo(
    () => users.map((user) => ({ value: user, label: getUserName(user), id: user.userId })),
    [users],
  );

  const updateSelection = useCallback(
    (selected) => {
      const selectedValue = selected[0];
      const selectedUser = options.find((option) => option.value === selectedValue)?.value;

      if (selectedUser) {
        setSelectedUser(selectedUser);
        onSelect(selectedUser);
        setSearchQuery('');
      }
    },
    [options, onSelect],
  );

  return (
    <Autocomplete
      options={options}
      selected={selectedUser ? [getUserName(selectedUser)] : []}
      allowMultiple={false}
      onSelect={updateSelection}
      textField={
        <Autocomplete.TextField
          value={searchQuery}
          onChange={onChangeSearchQuery}
          prefix={<Icon source={SearchIcon} />}
          autoComplete="off"
          placeholder={t('settings:channels.search_customer')}
          loading={loading}
          clearButton={<Button icon={XCircleIcon} />}
          onClearButtonClick={() => setSearchQuery('')}
        />
      }
    />
  );
};

export default UserAutocomplete;

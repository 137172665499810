import { Box, Spinner } from '@shopify/polaris';

const Loading = () => {
  return (
    <Box
      padding="200"
      background="bg-transparent"
      width="100%"
    >
      <Spinner
        accessibilityLabel="loading"
        size="small"
      />
    </Box>
  );
};

export default Loading;

import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Typography } from '@mui/material';
import { rackFormSchema } from 'src/utils/schema';
import CommonSelect from 'src/components/hook_form/common_select';
import FormProvider from 'src/components/hook_form/form_provider';
import RHFTextField from 'src/components/hook_form/rhf_text_field';
import { InlineGrid, Box, Button, Card, InlineStack, Banner } from '@shopify/polaris';
import { createRackOrTote, editRack, useGetLocations } from 'src/api/location_api';
import { RHFAPISelect, RHFSelect } from 'src/components/hook_form/rhf_select';
import { RACK_TYPE, TEMPERATURE_TYPES } from 'src/api/common_code/location_management';
import { requiredField } from 'src/utils/format_data';
import { chooseLan } from 'src/utils/language_code';
import { isEmpty } from 'src/utils/type_check';
import { RackSlot } from './rack_slot';

const RackForm = ({ rack, onSuccess, onBack, printerConfig }) => {
  const { t } = useTranslation(['settings']);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  var isEdit = !isEmpty(rack);

  const defaultValues = useMemo(
    () => ({
      locationId: rack.locationId ?? '',
      rackNumber: rack.rackNumber ?? '',
      rackSlotXPosition: rack.maxRackSlotXPosition ?? 1,
      rackSlotYPosition: rack.maxRackSlotYPosition ?? 1,
      rackType: rack.rackType ?? '',
      temperatureType: rack.temperatureType ?? '',
      isShipmentMatchAble: rack.isShipmentMatchAble ?? true,
    }),
    [rack],
  );
  const methods = useForm({
    resolver: yupResolver(rackFormSchema(t)),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = methods;

  useEffect(() => {
    if (!isEmpty(rack)) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rack]);

  const onSubmit = handleSubmit(async (data) => {
    setErrorMsg('');
    setSuccessMsg('');
    const apiFunction = isEdit
      ? editRack(data, data.locationId, data.rackNumber)
      : createRackOrTote(data, 'rack');
    try {
      var res = await apiFunction;
      if (res.isSuccess) {
        if (isEdit) {
          setSuccessMsg(t('settings:locations_management.rack_form.update_success'));
        } else {
          onSuccess();
        }
      } else {
        setErrorMsg(res.errorMessages[0] ?? '');
      }
    } catch (error) {
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });
  const renderSlots = (
    <RackSlot
      rack={rack}
      printerConfig={printerConfig}
    />
  );

  const renderForm = (
    <Stack spacing={2}>
      <Card>
        <Box paddingBlockEnd={200}>
          <Typography variant="body_md_semibold">
            {t('settings:locations_management.rack_form.rack_information')}
          </Typography>
        </Box>
        <Stack spacing={1}>
          <InlineGrid
            columns={3}
            gap="200"
          >
            <RHFAPISelect
              name="locationId"
              displayName={requiredField(t('common:field.location'))}
              toOption={(r) => ({
                key: r.locationId,
                value: chooseLan(r.locationNameLocal, r.locationNameEnglish),
              })}
              useOptions={useGetLocations}
              params={{
                pageNumber: 1,
                pageSize: 100,
                isActive: true,
              }}
              disabled={isEdit}
            />
            <RHFTextField
              name="rackNumber"
              displayName={requiredField(t('common:field.rack_number'))}
              disabled={isEdit}
            />
            <CommonSelect
              name="rackType"
              data={RACK_TYPE}
              displayName={requiredField(t('settings:locations_management.rack_form.rack_type'))}
            />
          </InlineGrid>
          <InlineGrid
            columns={2}
            gap="200"
          >
            <CommonSelect
              data={TEMPERATURE_TYPES}
              name="temperatureType"
              displayName={requiredField(
                t('settings:locations_management.rack_form.temperature_type'),
              )}
            />
            <RHFSelect
              name="isShipmentMatchAble"
              native
              displayName={requiredField(t('common:field.shipment_match'))}
            >
              <option value={true}>
                {t('settings:locations_management.rack_form.available_for_shipment')}
              </option>
              <option value={false}>
                {t('settings:locations_management.rack_form.unavailable_for_shipment')}
              </option>
            </RHFSelect>
          </InlineGrid>
        </Stack>
      </Card>
      <Card>
        <Box paddingBlockEnd={200}>
          <Typography variant="body_md_semibold">
            {t('settings:locations_management.rack_form.slot_information')}
          </Typography>
        </Box>
        <InlineGrid
          columns={2}
          gap="200"
        >
          <RHFTextField
            name="rackSlotXPosition"
            displayName={requiredField(t('common:field.x-size'))}
            type="number"
            InputProps={{ inputProps: { min: 1, max: 200 } }}
            disabled={isEdit}
          />
          <RHFTextField
            name="rackSlotYPosition"
            displayName={requiredField(t('common:field.y-size'))}
            type="number"
            InputProps={{ inputProps: { min: 1, max: 200 } }}
            disabled={isEdit}
          />
        </InlineGrid>

        {isEdit && renderSlots}
      </Card>
    </Stack>
  );
  return (
    <FormProvider
      methods={methods}
      onSubmit={onSubmit}
    >
      <Box paddingBlockEnd={300}>
        {!!errorMsg && (
          <Banner
            tone="critical"
            onDismiss={() => setErrorMsg('')}
          >
            {errorMsg}
          </Banner>
        )}
        {!!successMsg && (
          <Banner
            tone="success"
            onDismiss={() => setSuccessMsg('')}
          >
            {successMsg}
          </Banner>
        )}
      </Box>

      {renderForm}
      <Box paddingBlockStart={400}>
        <InlineStack
          gap={200}
          align="end"
        >
          <Button
            size={'large'}
            variant="secondary"
            onClick={onBack}
          >
            {t('common:cancel')}
          </Button>
          <Button
            submit={true}
            loading={isSubmitting}
            size={'large'}
            variant="primary"
            disabled={!isDirty}
          >
            {isEdit ? t('common:save') : t('common:create_and_print')}
          </Button>
        </InlineStack>
      </Box>
    </FormProvider>
  );
};

export default RackForm;

import { fetcher, noRevalidateOnlyStaleOptions } from 'src/utils/axios';
import { ENDPOINTS } from './endpoints';
import { useMemo } from 'react';
import useSWR from 'swr';

export function useGetRoleMenu() {
  const URL = ENDPOINTS.menu.get_role_menu;
  const { data, isLoading, error, isValidating } = useSWR(
    URL,
    fetcher,
    noRevalidateOnlyStaleOptions,
  );
  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );
  return memoizedValue;
}

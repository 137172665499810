import i18n from 'src/locales/i18n';
import { Badge } from 'src/components/badge/badge';
import { ChoiceList, Button } from '@shopify/polaris';
import { TEMPERATURE_TYPES } from 'src/api/common_code/location_management';
import { LOCATION_TYPES } from 'src/api/common_code/location_type';
import { chooseLan } from 'src/utils/language_code';
import { deleteConfirm } from 'src/components/dialog/confirmation';
import { deleteRackOrTote } from 'src/api/location_api';
import { getCombinedIds } from 'src/utils/format_data';
import BulkButton from 'src/components/bulk_button';
import { DeleteIcon } from '@shopify/polaris-icons';
import EmptyStateCard from 'src/components/cards/empty_state_card';

i18n.loadNamespaces(['settings']);

const TYPES = {
  RACK: 'rack',
  TOTE: 'tote',
};

const FILTER_TYPES = {
  LOCATION: {
    key: 'locationType',
    options: LOCATION_TYPES,
  },
  TEMPERATURE: {
    key: 'temperatureType',
    options: TEMPERATURE_TYPES,
  },
};

export const tabs = (count, setType) => [
  {
    label: i18n.t('common:field.rack'),
    value: 'Rack',
    onClick: () => {
      setType(TYPES.RACK);
    },
    badge: <Badge>{count?.count?.rackCount ?? ''}</Badge>,
  },
  {
    label: i18n.t('common:field.tote'),
    value: 'Tote',
    onClick: () => {
      setType(TYPES.TOTE);
    },
    badge: <Badge>{count?.count?.toteCount ?? ''}</Badge>,
  },
];

export const bulkAction = (data, selectedIds, handleSelectionChange, type) => {
  const selectedItems = selectedIds.map((selectedId) => {
    const [id, locationId] = getCombinedIds(selectedId);
    return { id, locationId };
  });

  return [
    {
      content: (
        <BulkButton
          content={i18n.t('common:delete')}
          icon={DeleteIcon}
        />
      ),
      destructive: true,
      onAction: async () => {
        const confirmation = await deleteConfirm();
        if (confirmation) {
          deleteRackOrTote(selectedItems, type);
          handleSelectionChange('page', false);
        }
      },
    },
  ];
};

export const headers = (type) => {
  if (type === TYPES.RACK) {
    return [
      { title: i18n.t('common:field.rack_number') },
      { title: i18n.t('common:field.location') },
      { title: i18n.t('common:field.barcode') },
      { title: i18n.t('common:field.location_type') },
      { title: i18n.t('common:field.temp_type') },
    ];
  } else {
    return [
      { title: i18n.t('common:field.tote_number') },
      { title: i18n.t('common:field.location') },

      { title: i18n.t('common:field.tote_type') },
      { title: i18n.t('common:field.barcode') },
      { title: i18n.t('common:field.tote_count') },
      { title: i18n.t('common:field.description') },
    ];
  }
};

const findLabelByValue = (options, value) => {
  const chosenLabel = options.find((type) => type.value === value);
  return chosenLabel ? chosenLabel.label : '';
};

export const initialFilters = (type) => {
  if (type === TYPES.RACK) {
    return {
      locationType: { label: '', value: [] },
      temperatureType: { label: '', value: [] },
    };
  }
  return {};
};

const handleFilterChange = (key, options, selected, setState) => {
  const label = findLabelByValue(options, selected[0]);
  setState(key, { label, value: selected });
};

export const filters = (state, setState, type) => {
  if (type === TYPES.TOTE) {
    return [];
  }
  const locOptions = FILTER_TYPES.LOCATION.options.map((type) => ({
    label: chooseLan(type.codeValueNameLocal, type.codeValueNameEnglish),
    value: type.codeValue,
  }));

  const tempOptions = FILTER_TYPES.TEMPERATURE.options.map((type) => ({
    label: chooseLan(type.codeValueNameLocal, type.codeValueNameEnglish),
    value: type.codeValue,
  }));

  const filters = [
    {
      key: FILTER_TYPES.LOCATION.key,
      label: 'Location Type',
      filter: (
        <ChoiceList
          title="Location Type"
          choices={locOptions}
          selected={state.locationType?.value ?? ''}
          onChange={(selected) =>
            handleFilterChange(FILTER_TYPES.LOCATION.key, locOptions, selected, setState)
          }
        />
      ),
      shortcut: true,
    },
    {
      key: FILTER_TYPES.TEMPERATURE.key,
      label: 'Temperature Type',
      filter: (
        <ChoiceList
          title="Temperature Type"
          choices={tempOptions}
          selected={state.temperatureType?.value ?? ''}
          onChange={(selected) =>
            handleFilterChange(FILTER_TYPES.TEMPERATURE.key, tempOptions, selected, setState)
          }
        />
      ),
      shortcut: true,
    },
  ];
  return filters;
};

export const EmptyStateMarkup = ({ toggleToteModal, toggleRackView, type }) => {
  if (type === TYPES.RACK) {
    return (
      <EmptyStateCard
        heading={i18n.t('settings:locations_management.please_create_rack')}
        body={i18n.t('settings:locations_management.create_rack_description')}
        image="/images/putaway_icon.png"
        center
        width={60}
        height={60}
        card={false}
      >
        <Button
          variant={'secondary'}
          onClick={() => {
            toggleRackView();
          }}
        >
          {i18n.t('settings:locations_management.create_rack')}
        </Button>
      </EmptyStateCard>
    );
  } else {
    return (
      <EmptyStateCard
        heading={i18n.t('settings:locations_management.please_create_tote')}
        body={i18n.t('settings:locations_management.create_rack_description')}
        image="/images/total_picking.png"
        center
        card={false}
        width={60}
        height={60}
      >
        <Button
          variant={'secondary'}
          onClick={() => {
            toggleToteModal();
          }}
        >
          {i18n.t('settings:locations_management.create_tote')}
        </Button>
      </EmptyStateCard>
    );
  }
};

export function label(key, value) {
  switch (key) {
    case FILTER_TYPES.LOCATION.key:
      return `${i18n.t('settings:locations_management.location_type_is')} ${value}`;
    case FILTER_TYPES.TEMPERATURE.key:
      return `${i18n.t('settings:locations_management.temp_type_is')} ${value}`;
    default:
      return value;
  }
}

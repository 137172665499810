import i18n from 'src/locales/i18n';
import { Badge } from 'src/components/badge/badge';
import { deleteClient } from 'src/api/client_api';
import { deleteConfirm } from 'src/components/dialog/confirmation';
import BulkButton from 'src/components/bulk_button';
import { DeleteIcon } from '@shopify/polaris-icons';
import { toast } from 'react-toastify';

export const tabs = (count) => [
  {
    label: i18n.t('common:tab.all'),
    value: '',
    onClick: () => {},
    badge: <Badge>{count.totalCount}</Badge>,
  },
];

export const bulkAction = (data, selectedIds, handleSelectionChange) => {
  return [
    {
      content: (
        <BulkButton
          content={i18n.t('common:delete')}
          icon={DeleteIcon}
        />
      ),
      destructive: true,
      onAction: async () => {
        const confirmation = await deleteConfirm();
        if (confirmation) {
          try {
            deleteClient(selectedIds);
            handleSelectionChange('page', false);
            toast(i18n.t('settings:channels.delete_success_batch'));
          } catch (err) {
            toast(typeof err === 'string' ? err : err.message ? err.message : '');
          }
        }
      },
    },
  ];
};

export const initialFilters = {};

export const filters = (state, setState) => {
  return [];
};

export function label(key, value) {}

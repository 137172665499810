import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Page, Button } from '@shopify/polaris';
import LocationTitle from 'src/components/title/location_title';
import { print } from 'src/utils/log';
import { createSorterConfirmation } from './create_sorter_modal';
import SorterTableView from './table/sorter_table_view';
import locationStore from 'src/stores/location_store';

const SorterManagementView = () => {
  const { t } = useTranslation(['settings']);
  const [isEmpty, setIsEmpty] = useState(false);
  const selectedLocation = locationStore((state) => state.getLocationId('sorter'));
  const { setLocationId: setSelectedLocation } = locationStore();

  const renderTitle = (
    <LocationTitle
      handleLocationChange={(value) => setSelectedLocation('sorter', value)}
      selectedLocation={selectedLocation}
      title={t('settings:sorter.sorter_of')}
      includeAll={true}
      primaryButton={
        !isEmpty ? (
          <Button
            variant="primary"
            onClick={async () => {
              await createSorterConfirmation({ selectedLocation });
            }}
          >
            {t('settings:sorter.create_sorter')}
          </Button>
        ) : null
      }
    />
  );

  print('sorter_management load');
  return (
    <Page fullWidth>
      {renderTitle}
      <SorterTableView
        selectedLocation={selectedLocation}
        createSorterConfirmation={createSorterConfirmation}
        setIsEmpty={setIsEmpty}
      />
      <div className="bottom-padding"></div>
    </Page>
  );
};

export default SorterManagementView;

import { confirmable } from 'react-confirm';
import { useTranslation } from 'react-i18next';
import StackedModal from './stacked_modal';
import { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { TextField } from '@shopify/polaris';

function IssueDialog({ show, proceed, title, content }) {
  const { t } = useTranslation(['common', 'transfer']);
  const [inputValue, setInputValue] = useState('');
  const handleChange = useCallback((newValue) => {
    setInputValue(newValue);
  }, []);

  return (
    <StackedModal
      isOpen={show}
      title={title || t('common:confirm')}
      content={
        <Box
          display={'flex'}
          flexDirection={'column'}
        >
          <span style={{ paddingBottom: '12px' }}>{content}</span>
          <TextField
            multiline={false}
            rows={1}
            value={inputValue}
            onChange={handleChange}
          />
        </Box>
      }
      primaryAction={{
        content: t('common:confirm'),
        disabled: inputValue.length < 1,
        onAction: () => {
          proceed({ confirmed: true, value: inputValue });
        },
      }}
      secondaryAction={{
        content: t('common:cancel'),
        onAction: () => {
          proceed({ confirmed: false, value: null });
        },
      }}
      onClose={() => {
        proceed({ confirmed: false, value: null });
      }}
    />
  );
}

export default confirmable(IssueDialog);

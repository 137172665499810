import { Button } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { useGetMe } from 'src/api/user_api';
import { useRouter } from 'src/hooks/router/use_router';
import { PATHS } from 'src/routes/paths';
import { isEmpty } from 'src/utils/type_check';

export const ROLES = {
  ADMIN: 'admin', // admin
  CUSTOMER: 'customer', // order, PO manager
  WORKER: 'worker', // below location manager
  STAFF: 'staff', // below customer
  MANAGER: 'manager', // general admin
  LOCATION_MANAGER: 'location_manager', // inbound, outbound manager
};

export default function RoleGuard({ allowedRoles = [], children, returnPage }) {
  const { t } = useTranslation(['auth', 'dashboard']);
  const { role, searchLoading } = useGetMe();

  const currentRole = role;

  const goBackPage = returnPage || PATHS.dashboard.root;

  const router = useRouter();

  if (searchLoading) {
    return null;
  }

  if (isEmpty(allowedRoles) || !allowedRoles.includes(currentRole)) {
    return (
      <div>
        {t('auth:not_authorized')}
        <Button onClick={() => router.push(goBackPage)}>{t('dashboard:go_to_dashboard')}</Button>
      </div>
    );
  }

  return <> {children} </>;
}

import { IndexTable, Card, useBreakpoints } from '@shopify/polaris';
import TablePagination from './table_pagination';

export default function FixedTable({ useTable }) {
  return (
    <Card padding={0}>
      <IndexTable
        condensed={useBreakpoints().smDown}
        itemCount={useTable.data.length}
        headings={useTable.headers}
        selectable={false}
      >
        {useTable.rowMarkup}
      </IndexTable>
      {useTable.showPagination && <TablePagination useTable={useTable} />}
    </Card>
  );
}

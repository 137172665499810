import useTable from 'src/hooks/use_table';
import Table from 'src/components/table/table';
import { print } from 'src/utils/log';
import i18n from 'src/locales/i18n';
import { Badge, Icon, IndexTable, OptionList } from '@shopify/polaris';
import { useGetActiveUserForLocation } from 'src/api/user_api';
import { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { EditIcon } from '@shopify/polaris-icons';
import { getUserName } from 'src/utils/format_data';
import { isEmpty } from 'src/utils/type_check';
import EmptyStateCard from 'src/components/cards/empty_state_card';

const TYPE = {
  location_manager: 'location_manager',
  worker: 'worker',
};

const headers = () => {
  var name = { title: i18n.t('common:field.name') };
  var email = { title: i18n.t('common:field.email') };
  return [name, email, { title: '' }];
};

const rowMarkup = ({ data }, handleClick, type) => {
  return data.map((user, index) => {
    var { userId, loginId } = user;
    var id = userId;
    var name = getUserName(user);
    var width = '50%';
    return (
      <IndexTable.Row
        id={id}
        key={id}
        position={index}
      >
        <Box
          component={IndexTable.Cell}
          sx={{ width: width }}
        >
          {name}
        </Box>
        <Box
          component={IndexTable.Cell}
          sx={{ width: width }}
        >
          {loginId}
        </Box>
        <Box
          component={IndexTable.Cell}
          sx={{ width: '20px' }}
        >
          <IconButton
            sx={{ padding: 0.1 }}
            onClick={() => handleClick(user, type)}
          >
            <Icon source={EditIcon} />
          </IconButton>
        </Box>
      </IndexTable.Row>
    );
  });
};

const tabs = (count) => {
  var locationManager = {
    label: i18n.t('common:roles.location_manager'),
    value: TYPE.location_manager,
    onClick: () => {},
    badge: <Badge>{count.locationManager}</Badge>,
  };
  var worker = {
    label: i18n.t('common:roles.worker'),
    value: TYPE.worker,
    onClick: () => {},
    badge: <Badge>{count.worker}</Badge>,
  };
  return [locationManager, worker];
};

export const initialFilters = {
  isActive: {
    label: i18n.t('common:active'),
    value: true,
  },
};

const options = [
  {
    label: i18n.t('common:inactive'),
    value: false,
  },
];

export const filters = (state, setState) => {
  return [
    {
      key: 'isActive',
      label: i18n.t('common:active'),
      filter: (
        <OptionList
          onChange={(selected) => setState('isActive', selected[0])}
          options={options}
          selected={[state.isActive.value]}
        />
      ),
      pinned: true,
      shortcut: true,
    },
  ];
};

export function label(key, value) {
  switch (key) {
    case 'isActive':
      return value ? i18n.t('common:active') : i18n.t('common:inactive');
    default:
      return value;
  }
}

export default function LocationTable({ locationId, handleClick, tabType }) {
  const [getActive, setGetActive] = useState(true);
  const [q, setQ] = useState('');
  const { searchCount, locationManagerList, workerList, searchLoading } =
    useGetActiveUserForLocation(locationId, { q, isActive: getActive });
  const [type, setType] = useState(!isEmpty(tabType) ? tabType : TYPE.location_manager);

  const getData = () => {
    if (type === TYPE.location_manager) return locationManagerList;
    if (type === TYPE.worker) return workerList;
    return [];
  };

  const table = useTable({
    data: getData() ?? [],
    headers: headers(type),
    tabItems: tabs(searchCount ?? {}),
    defaultTab: type,
    rowMarkup: (props) => rowMarkup(props, handleClick, type),
    showRowsPerPage: false,
    showPagination: false,
    selectable: false,
    initialFilters: initialFilters,
    filters: (state, setState) => filters(state, setState),
    disambiguateLabel: label,
    emptyStateMarkup: (
      <EmptyStateCard
        heading={i18n.t('settings:users.no_user_data')}
        body={i18n.t('settings:users.no_user_data_body')}
        image="/images/user_empty_icon.png"
        card={false}
        width={60}
        height={60}
      />
    ),
  });

  if (table.isRefetch.value) {
    setType(table.tabItems[table.tabIndex].value);
    setGetActive(table.filterValues.isActive);
    setQ(table.debounceSearchQuery);
    table.onRefetched();
  }

  print('users location table load');

  return (
    <Table
      useTable={table}
      isLoading={searchLoading}
    />
  );
}

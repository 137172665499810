import { MenuItem, Typography, Select } from '@mui/material';
import { Box, InlineStack, Button } from '@shopify/polaris';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ArrowLeftIcon, CheckIcon } from '@shopify/polaris-icons';
import { useGetLocations } from 'src/api/location_api';
import Loading from '../loading';
import { chooseLan } from 'src/utils/language_code';
import { useTranslation } from 'react-i18next';
import { useEffect, Fragment } from 'react';
import { useRouter } from 'src/hooks/router/use_router';

const LocationTitle = ({
  title,
  selectedLocation,
  handleLocationChange,
  primaryButton,
  secondaryButtons = [],
  includeAll = true,
  hasBottomPadding = true,
  backButtonUrl,
  backButton,
  setLocationName,
  setLocationUnit,
}) => {
  const { t } = useTranslation();
  const router = useRouter();

  const ALL = {
    locationId: 'all',
    locationNameLocal: t('common:all'),
    locationNameEnglish: t('common:all'),
  };
  const { searchResults: locations = [], searchLoading: loading } = useGetLocations({
    pageSize: 100,
    pageNumber: 1,
    isActive: true,
  });
  var selectLocations = includeAll ? [ALL, ...locations] : locations;

  useEffect(() => {
    if (selectedLocation === '' && locations.length > 0) {
      const defaultLocation = locations[0];
      setLocationName &&
        setLocationName(
          chooseLan(defaultLocation.locationNameLocal, defaultLocation.locationNameEnglish),
        );
      setLocationUnit && setLocationUnit({ dimensionUnit: defaultLocation.dimensionUnit });
      handleLocationChange(defaultLocation.locationId || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectLocations, selectedLocation]);
  const renderTitle = (
    <InlineStack
      align="space-between"
      blockAlign="start"
    >
      <Box paddingBlockEnd={hasBottomPadding ? 600 : 0}>
        <InlineStack blockAlign="center">
          {backButtonUrl && (
            <Button
              icon={ArrowLeftIcon}
              accessibilityLabel="Go back"
              onClick={() => router.push(backButtonUrl)}
              variant="tertiary"
            ></Button>
          )}
          {backButton && (
            <>
              {backButton}
              <div style={{ width: '4px' }}></div>
            </>
          )}
          <Box paddingInlineEnd={100}>
            <Typography variant="heading_lg">{title}</Typography>
          </Box>
          <Select
            variant="standard"
            disableUnderline
            displayEmpty
            sx={{
              '& .MuiSelect-select': {
                paddingY: '4px',
                '&:focus': {
                  borderRadius: '10px',
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                },
                '&:hover': {
                  borderRadius: '10px',
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                },
              },
            }}
            IconComponent={() => (
              <KeyboardArrowDownIcon
                fontSize="small"
                sx={{ position: 'absolute', right: 0, pointerEvents: 'none', color: 'icon' }}
              />
            )}
            renderValue={(selected) => (
              <Typography
                variant="heading_lg"
                color={'text.primary'}
                sx={{ paddingLeft: '4px' }}
              >
                {selected &&
                  chooseLan(
                    selectLocations.find((loc) => loc.locationId === selected)?.locationNameLocal,
                    selectLocations.find((loc) => loc.locationId === selected)?.locationNameEnglish,
                  )}
              </Typography>
            )}
            value={selectedLocation}
            onChange={(e) => {
              var selected = e.target.value;
              var selectedObj = selectLocations.find((loc) => loc.locationId === selected);
              setLocationName &&
                setLocationName(
                  chooseLan(selectedObj?.locationNameLocal, selectedObj?.locationNameEnglish),
                );
              setLocationUnit && setLocationUnit({ dimensionUnit: selectedObj?.dimensionUnit });
              handleLocationChange(selected);
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              MenuListProps: {
                disablePadding: true,
              },
              PaperProps: {
                sx: {
                  width: '121px',
                  borderRadius: '12px',
                  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
                  border: '1px solid rgba(0, 0, 0, 0.1)',
                },
              },
            }}
          >
            {selectLocations.map((loc) => (
              <MenuItem
                key={loc.locationId}
                value={loc.locationId}
                sx={{
                  '&.Mui-selected': {
                    borderRadius: '10px',
                    backgroundColor: 'rgba(128, 128, 128, 0.2)',
                  },
                  '&:hover': {
                    borderRadius: '10px',
                    backgroundColor: 'rgba(241, 241, 241, 1)',
                  },
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  margin: '6px',
                  paddingX: '6px',
                }}
              >
                <Typography
                  sx={{
                    flexGrow: 1,
                    fontWeight: `${loc.locationId === selectedLocation && 'bold'}`,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {chooseLan(loc.locationNameLocal, loc.locationNameEnglish)}
                </Typography>
                {loc.locationId === selectedLocation && (
                  <CheckIcon
                    height={'20px'}
                    style={{ paddingLeft: '6px' }}
                  />
                )}
              </MenuItem>
            ))}
          </Select>{' '}
        </InlineStack>
      </Box>
      <InlineStack
        align="center"
        gap={200}
      >
        {secondaryButtons.map((btn, index) => (
          <Fragment key={index}>{btn}</Fragment>
        ))}
        {primaryButton}
      </InlineStack>
    </InlineStack>
  );

  return <div style={{ width: '100%' }}>{loading ? <Loading /> : renderTitle}</div>;
};

export default LocationTitle;

import { confirmable } from 'react-confirm';
import { useTranslation } from 'react-i18next';
import StackedModal from './stacked_modal';
import { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { TextField } from '@shopify/polaris';
import i18n from 'src/locales/i18n';

function TrackingNumberDialog({ show, proceed }) {
  const { t } = useTranslation(['common', 'transfer']);
  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('');
  const handleChange = useCallback((newValue, type) => {
    if (type === 'from') {
      setFromValue(newValue);
    } else {
      setToValue(newValue);
    }
  }, []);

  return (
    <StackedModal
      isOpen={show}
      title={'Create Tracking Number' || t('common:confirm')}
      content={
        <Box
          display={'flex'}
          flexDirection={'row'}
          width={'100%'}
          columnGap={2}
        >
          <Box
            display={'flex'}
            width="50%"
            flexDirection={'column'}
          >
            <span style={{ paddingBottom: '12px' }}>{i18n.t('common:from')}</span>
            <TextField
              multiline={false}
              rows={1}
              value={fromValue}
              placeholder={i18n.t('common:field.range_from')}
              onChange={(newValue) => {
                handleChange(newValue, 'from');
              }}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            width="50%"
          >
            <span style={{ paddingBottom: '12px' }}>{i18n.t('common:to')}</span>
            <TextField
              multiline={false}
              placeholder={i18n.t('common:field.range_to')}
              rows={1}
              value={toValue}
              onChange={(newValue) => {
                handleChange(newValue, 'to');
              }}
            />
          </Box>
        </Box>
      }
      primaryAction={{
        content: t('common:confirm'),
        disabled: fromValue.length < 1 || toValue.length < 1,
        onAction: () => {
          proceed({ confirmed: true, fromValue: fromValue, toValue: toValue });
        },
      }}
      secondaryAction={{
        content: t('common:cancel'),
        onAction: () => {
          proceed({ confirmed: false, fromValue: null, toValue: null });
        },
      }}
      onClose={() => {
        proceed({ confirmed: false, fromValue: null, toValue: null });
      }}
    />
  );
}

export default confirmable(TrackingNumberDialog);

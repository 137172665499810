import { IndexTable } from '@shopify/polaris';
import { chooseLan } from 'src/utils/language_code';

const rowMarkup = ({ data, selectedItems }) => {
  let previousLocationId = null;

  return data.map(
    (
      {
        locationId,
        locationMappingCode,
        locationNameLocal,
        locationNameEnglish,
        courierNameLocal,
        courierNameEnglish,
        courierServiceCode,
      },
      index,
    ) => {
      const hideLocationId = locationId === previousLocationId;
      previousLocationId = locationId;

      return (
        <IndexTable.Row
          id={locationId}
          key={index}
          selected={selectedItems.includes(locationId)}
          position={index}
          onClick={() => {}}
        >
          <IndexTable.Cell>
            {hideLocationId ? null : chooseLan(locationNameLocal, locationNameEnglish)}
          </IndexTable.Cell>
          <IndexTable.Cell>{hideLocationId ? null : locationMappingCode}</IndexTable.Cell>
          <IndexTable.Cell>{chooseLan(courierNameLocal, courierNameEnglish)}</IndexTable.Cell>
          <IndexTable.Cell>{courierServiceCode}</IndexTable.Cell>
        </IndexTable.Row>
      );
    },
  );
};

export default rowMarkup;

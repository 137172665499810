import { confirmable } from 'react-confirm';
import StackedModal from './stacked_modal';
import { useTranslation } from 'react-i18next';
import { Button, DropZone } from '@shopify/polaris';
import { Alert, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useGetMe } from 'src/api/user_api';

import FormProvider from '../hook_form/form_provider';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { isEmpty } from 'src/utils/type_check';
import { getExcelTemplate } from 'src/api/excel_api';
import { LogInAlert } from '../alert/alert';

function ImportDialogCourier({ show, proceed, name, type, importData, locationId, courierId }) {
  const { t } = useTranslation(['excel']);
  const [file, setFile] = useState(null);
  const { customerId } = useGetMe();
  const [warningMessage, setWarningMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const schema = Yup.object().shape({
    customerId: Yup.string(),
  });

  const defaultValues = {
    customerId: customerId,
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    setWarningMessage('');
    try {
      if (data === null) return;
      var res = await importData(locationId, courierId, file);
      if (res.isSuccess) {
        setSuccessMessage(t('excel:import_success_no_count'));
      } else {
        setWarningMessage(res.errorMessages[0]);
      }
    } catch (error) {
      setWarningMessage(t('excel:generic_error'));
    }
  });

  const handleDropZoneDrop = async (_dropFiles, acceptedFiles, _rejectedFiles) => {
    setFile(acceptedFiles[0]);
  };

  const dropFile =
    file === null ? (
      <DropZone.FileUpload
        actionTitle={t('excel:add')}
        actionHint={`${t('excel:accepts')} .xlsx`}
      />
    ) : (
      <Stack
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        direction={'row'}
        px={2}
        py={4}
      >
        <Typography variant="body_md_semibold">{file?.name}</Typography>
        <Button onClick={() => setFile(null)}>{t('excel:replace')}</Button>
      </Stack>
    );

  const renderAlert = (
    <>
      {!isEmpty(warningMessage) && (
        <LogInAlert
          onClose={() => setWarningMessage('')}
          severity="error"
          sx={{ py: 0 }}
          errorMsg={warningMessage}
        />
      )}
      {!isEmpty(successMessage) && (
        <Alert
          onClose={() => setSuccessMessage('')}
          sx={{ py: 0 }}
        >
          {successMessage}
        </Alert>
      )}
    </>
  );

  const content = (
    <FormProvider
      methods={methods}
      onSubmit={onSubmit}
    >
      <Stack spacing={1.5}>
        {renderAlert}
        <DropZone
          onDrop={handleDropZoneDrop}
          allowMultiple={false}
          variableHeight
          accept=".xlsx"
        >
          {dropFile}
        </DropZone>
      </Stack>
    </FormProvider>
  );

  return (
    <StackedModal
      isOpen={show}
      title={t('excel:import', { name: name })}
      content={content}
      primaryAction={{
        content: t('common:upload'),
        onAction: onSubmit,
        loading: isSubmitting,
        disabled: file === null,
        isSubmit: true,
      }}
      secondaryAction={{
        content: t('common:cancel'),
        onAction: () => {
          proceed(null);
        },
      }}
      footerLeftNode={
        <Button
          variant="tertiary"
          onClick={async () => {
            await getExcelTemplate(type);
          }}
        >
          <Typography
            variant={'body_md'}
            sx={{ color: 'link', textDecoration: 'underline' }}
          >
            {t('excel:download')}
          </Typography>
        </Button>
      }
      onClose={() => {
        proceed(null);
      }}
    />
  );
}

export default confirmable(ImportDialogCourier);

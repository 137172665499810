import { FormProvider as Form } from 'react-hook-form';

export default function FormProvider({ children, onSubmit, methods, enterAllowed = false }) {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !enterAllowed) {
      e.preventDefault();
    }
  };

  return (
    <Form {...methods}>
      <form
        onSubmit={onSubmit}
        onKeyDown={handleKeyDown}
      >
        {children}
      </form>
    </Form>
  );
}

import React, { useMemo } from 'react';
import { RHFSelect } from 'src/components/hook_form/rhf_select';
import { chooseLan } from 'src/utils/language_code';

function CommonSelect({
  data,
  name,
  displayName,
  disabled = false,
  labelAsValue = false,
  defaultValue,
}) {
  const commons = data;
  const memoizedData = useMemo(() => commons, [commons]);
  return (
    <RHFSelect
      name={name}
      native
      displayName={displayName}
      disabled={disabled ?? false}
      defaultValue={defaultValue}
    >
      <option
        key={'blank_option'}
        value={''}
        style={{ display: 'none' }}
      />
      {memoizedData.map((elem) => (
        <option
          key={elem.codeValue}
          value={elem.codeValue}
        >
          {labelAsValue
            ? elem.codeValue
            : chooseLan(elem.codeValueNameLocal, elem.codeValueNameEnglish)}
        </option>
      ))}
    </RHFSelect>
  );
}

export default CommonSelect;

import { format, lastDayOfMonth } from 'date-fns';
import { fAPIDate, fUTCDate } from 'src/utils/format_time';

export const today = new Date(new Date());
export const yesterday = today.getDate() - 1;
export const firstDayOfWeek = today.getDate() - today.getDay();
export const firstDateOfMonth = format(today, 'yyyy-MM-01');
export const lastDateOfMonth = format(lastDayOfMonth(today), 'yyyy-MM-dd');
export const lastMonth = new Date(new Date(today).setDate(0));
export const firstDateOfLastMonth = format(lastMonth, 'yyyy-MM-01');
export const lastDateOfLastMonth = format(lastDayOfMonth(lastMonth), 'yyyy-MM-dd');
export const last3Month = new Date(new Date().setMonth(today.getMonth() - 2));
export const firstDateOfLast3Month = format(last3Month, 'yyyy-MM-01');
export const last6Month = new Date(new Date().setMonth(today.getMonth() - 5));
export const firstDateOfLast6Month = format(last6Month, 'yyyy-MM-01');
export const firstDateOfYear = format(today, 'yyyy-01-01');
export const lastDateOfYear = format(today, 'yyyy-12-31');
export const lastYear = new Date(new Date().setFullYear(today.getFullYear() - 1));
export const firstDateOfLastYear = format(lastYear, 'yyyy-01-01');
export const lastDateOfLastYear = format(lastYear, 'yyyy-12-31');

const defaultObj = {
  today: {
    alias: 'today',
    period: {
      since: today,
      until: today,
    },
  },
  yesterday: {
    alias: 'yesterday',
    period: {
      since: new Date(new Date(new Date().setDate(yesterday))),
      until: new Date(new Date(new Date().setDate(yesterday))),
    },
  },
  thisweek: {
    alias: 'thisweek',
    period: {
      since: new Date(new Date().setDate(firstDayOfWeek)),
      until: new Date(new Date().setDate(firstDayOfWeek + 6)),
    },
  },
  thismonth: {
    alias: 'thismonth',
    period: {
      since: fUTCDate(new Date(new Date(firstDateOfMonth))),
      until: fUTCDate(new Date(new Date(lastDateOfMonth))),
    },
  },
  lastmonth: {
    alias: 'lastmonth',
    period: {
      since: fUTCDate(new Date(new Date(firstDateOfLastMonth))),
      until: fUTCDate(new Date(new Date(lastDateOfLastMonth))),
    },
  },
  past3months: {
    alias: 'past3months',
    period: {
      since: fUTCDate(new Date(new Date(firstDateOfLast3Month))),
      until: fUTCDate(new Date(new Date(lastDateOfMonth))),
    },
  },
  past6months: {
    alias: 'past6months',
    period: {
      since: fUTCDate(new Date(new Date(firstDateOfLast6Month))),
      until: fUTCDate(new Date(new Date(lastDateOfMonth))),
    },
  },
  thisyear: {
    alias: 'thisyear',
    period: {
      since: fUTCDate(new Date(new Date(firstDateOfYear))),
      until: fUTCDate(new Date(new Date(lastDateOfYear))),
    },
  },
  lastyear: {
    alias: 'lastyear',
    period: {
      since: fUTCDate(new Date(new Date(firstDateOfLastYear))),
      until: fUTCDate(new Date(new Date(lastDateOfLastYear))),
    },
  },
};

export const rangeObj = () => {
  var dates = defaultObj;
  var ret = {};
  Object.keys(dates).forEach((key) => {
    ret[key] = {
      ...dates[key],
      fromDt: fAPIDate(dates[key].period.since, true),
      toDt: fAPIDate(dates[key].period.until, true),
    };
  });
  return ret;
};

import { useCallback, useEffect } from 'react';
import { useGetMe } from 'src/api/user_api';
import { usePathname } from 'src/hooks/router/use_pathname';
import { useRouter } from 'src/hooks/router/use_router';
import { useSearchParams } from 'src/hooks/router/use_search_params';
import { PATHS } from 'src/routes/paths';
import authStore from 'src/stores/auth_store';
import { isEmpty } from 'src/utils/type_check';

export default function GuestGuard({ children }) {
  const router = useRouter();

  const { email } = authStore();

  const { user, searchLoading } = useGetMe();

  const searchParams = useSearchParams();

  const curPath = usePathname();

  const returnTo = searchParams.get('returnTo') ?? PATHS.dashboard.root;

  const check = useCallback(() => {
    if (!searchLoading) {
      if (curPath === PATHS.auth.chooseLocation) return;
      else if (!isEmpty(user)) {
        router.replace(returnTo);
      } else if (curPath === PATHS.auth.resetPassword) {
        return;
      } else if (isEmpty(email)) {
        router.replace(PATHS.auth.email);
      }
    }
  }, [user, searchLoading, returnTo, router, email, curPath]);

  useEffect(() => {
    check();
  }, [check, searchLoading, user]);

  if (searchLoading) {
    return null;
  }

  return <>{children}</>;
}

import { Box, Stack, Typography } from '@mui/material';
import { Badge as SBadge, Icon } from '@shopify/polaris';
import { isInteger } from 'src/utils/type_check';

export const Badge = ({ tone, icon, children }) => {
  return (
    <SBadge tone={tone}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        spacing={0.3}
      >
        {icon !== undefined && (
          <Box>
            <Icon source={icon} />
          </Box>
        )}
        <Typography variant={isInteger(children) ? 'body_sm_medium' : 'body_md_medium'}>
          {children}
        </Typography>
      </Stack>
    </SBadge>
  );
};

import useTable from 'src/hooks/use_table';
import Table from 'src/components/table/table';
import { print } from 'src/utils/log';
import i18n from 'src/locales/i18n';
import { IndexTable } from '@shopify/polaris';
import { chooseLan } from 'src/utils/language_code';

i18n.loadNamespaces(['settings']);

export const headers = () => {
  return [
    { title: i18n.t('common:field.channel_name') },
    { title: i18n.t('settings:mapping.channel_mapping_code') },
  ];
};

const rowMarkup = ({ data, selectedItems }) => {
  return data.map(
    ({ clientId, channelNameLocal, channelNameEnglish, channelMappingCode }, index) => {
      return (
        <IndexTable.Row
          id={clientId}
          key={clientId}
          selected={selectedItems.includes(clientId)}
          position={index}
          onClick={() => {}}
        >
          <IndexTable.Cell>{chooseLan(channelNameLocal, channelNameEnglish)}</IndexTable.Cell>
          <IndexTable.Cell>{channelMappingCode}</IndexTable.Cell>
        </IndexTable.Row>
      );
    },
  );
};

export default function ChannelMappingTableView({ data, searchLoading }) {
  const table = useTable({
    data: data ?? [],
    headers: headers(),
    rowMarkup: rowMarkup,
    resourceIdResolver: (d) => d.clientId,
    showRowsPerPage: false,
    showPagination: false,
    showSearchFilter: false,
    debounceDelay: 0,
    selectable: false,
  });

  print('channel mapping table load');

  return (
    <Table
      useTable={table}
      isLoading={searchLoading}
    />
  );
}

import axiosInstance from 'src/utils/axios';
import { ENDPOINTS } from './endpoints';

export async function getExcelTemplate(type) {
  var response = await axiosInstance.get(ENDPOINTS.excel.get_template(type), {
    responseType: 'blob',
    // withCredentials: true,
  });
  const contentDisposition = response.headers.get('content-disposition');
  let fileName = 'sample_file.xlsx';
  // let fileName = 'downloaded_file.xlsx'; // 기본 파일명
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename=(.+?);/);
    if (fileNameMatch.length === 2) {
      fileName = fileNameMatch[1];
    }
  }

  // Blob 생성
  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  // 다운로드 링크 생성 및 클릭
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(link);
  return;
}

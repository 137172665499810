import { Icon } from '@shopify/polaris';
import { Box, ListItemButton } from '@mui/material';
import { useEffect } from 'react';
import { useHover } from 'src/hooks/use_hover';

// ----------------------------------------------------------------------

export default function NavItem({ item, active, setHovering, hovering, ...other }) {
  const { icon, selectedIcon } = item;
  const [ref, isHover] = useHover();

  useEffect(() => {
    if (!hovering && isHover) {
      setHovering(isHover);
    }
  }, [isHover, setHovering, hovering]);

  const renderContent = (
    <ListItemButton
      disableGutters
      sx={{
        backgroundColor: active ? 'var(--p-color-nav-bg-surface-selected)' : 'inherit',
        borderRadius: 'var(--p-border-radius-200)',
        p: 'var(--p-space-200)',
        display: 'flex',
        justifyContent: 'space-between',
        mx: '12px',
      }}
      ref={ref}
      {...other}
    >
      <Box
        display="flex"
        justifyContent={'space-between'}
        width={'100%'}
        height={'1.2rem'}
      >
        <Box
          display="flex"
          alignItems={'center'}
        >
          <Box>{selectedIcon && icon && <Icon source={active ? selectedIcon : icon} />}</Box>
        </Box>
      </Box>
    </ListItemButton>
  );

  return renderContent;
}

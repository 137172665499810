import CheckIcon from '@mui/icons-material/Check';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { alertClasses } from '@mui/material';

const COLORS = ['info', 'success', 'warning', 'error'];

export default function Alert(theme) {
  const rootStyles = (ownerState) => {
    const standardVariant = ownerState.variant === 'standard';
    const outlinedVariant = ownerState.variant === 'outlined';

    const colorStyle = COLORS.map((color) => ({
      ...(ownerState.severity === color && {
        // STANDARD
        ...(standardVariant && {
          color: theme.palette.alert[color].contrastText,
          backgroundColor: theme.palette.alert[color].main,
          [`& .${alertClasses.icon}`]: {
            color: theme.palette.alert[color].contrastText,
          },
        }),
        ...(outlinedVariant && {
          color: theme.palette.common.black,
          backgroundColor: theme.palette.common.white,
          borderRadius: '12px',
          border: 'none',
          boxShadow:
            '0px 3px 1px -1px #1A1A1A12,0px 1px 0px 0px #CCCCCC80 inset,0px -1px 0px 0px #0000002B inset,-1px 0px 0px 0px #00000021 inset,1px 0px 0px 0px #00000021 inset',
          alignItems: 'center',
          [`& .${alertClasses.icon}`]: {
            backgroundColor: theme.palette.background[color].main,
            color: theme.palette.common.white,
            borderRadius: '8px',
            padding: '4px',
            marginTop: '5px',
            marginBottom: '5px',
            alignItems: 'center',
          },
        }),
      }),
    }));

    return [...colorStyle];
  };
  return {
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          error: <ErrorOutlineOutlinedIcon fontSize="small" />,
          info: <InfoOutlinedIcon fontSize="small" />,
          success: <CheckIcon fontSize="small" />,
          warning: <WarningAmberOutlinedIcon fontSize="small" />,
        },
      },
      styleOverrides: {
        root: ({ ownerState }) => rootStyles(ownerState),
        icon: {
          opacity: 1,
        },
        message: {
          fontWeight: theme.typography.heading_sm.fontWeight,
          fontSize: theme.typography.heading_sm.fontSize,
          lineHeight: theme.typography.heading_sm.lineHeight,
        },
      },
    },
    MuiAlertOutlined: {
      defaultProps: {
        iconMapping: {
          error: (
            <ErrorOutlineOutlinedIcon
              fontSize="small"
              sx={{ color: 'alert.error.contrastText' }}
            />
          ),
          info: (
            <InfoOutlinedIcon
              fontSize="small"
              sx={{ color: 'alert.info.contrastText' }}
            />
          ),
          success: (
            <InfoOutlinedIcon
              fontSize="large"
              sx={{ color: 'alert.success.contrastText' }}
            />
          ),
          warning: (
            <WarningAmberOutlinedIcon
              fontSize="small"
              sx={{ color: 'alert.warning.contrastText' }}
            />
          ),
        },
      },
    },
  };
}

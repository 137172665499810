import { useEffect, useState } from 'react';
import { rowMarkup, headers, bulkAction, tabs } from './tote_item_table_row_misc';
import useTable from 'src/hooks/use_table';
import Table from 'src/components/table/table';
import { useTranslation } from 'react-i18next';
import { useGetToteItem } from 'src/api/location_api';
import { BarcodeIcon } from '@shopify/polaris-icons';

export default function ToteItemTableView({ selectedLocation, selectedTote, printerConfig }) {
  const [params] = useState({
    locationId: selectedLocation,
    toteNumber: selectedTote,
  });
  const { searchList: data, searchLoading } = useGetToteItem(params);
  const { t } = useTranslation(['settings']);
  const [fullData, setFullData] = useState([]);
  const [showData, setShowData] = useState([]);

  const table = useTable({
    tabItems: tabs(fullData?.length),
    totalDataCount: fullData?.length,
    searchQueryIcon: BarcodeIcon,
    data: showData ?? [],
    headers: headers(),
    rowMarkup: (props) => rowMarkup(props),
    filters: () => [],
    bulkAction: (showData, selectedIds, handleSelectionChange) =>
      bulkAction(
        showData,
        selectedIds,
        handleSelectionChange,
        selectedLocation,
        selectedTote,
        printerConfig,
      ),
    resourceIdResolver: (d) => d.toteItemNumber,

    searching: t('settings:locations_management.search_tote_number'),
  });

  useEffect(() => {
    if (!searchLoading) {
      setFullData(data);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchLoading]);

  if (table.isRefetch.value) {
    if (table.searchQuery) {
      setFullData(
        data.filter((data) => {
          return data.toteBarcode.toLowerCase().includes(table.searchQuery.toLowerCase());
        }),
      );
    } else {
      setFullData(data);
    }
    const startIndex = (table.page - 1) * table.rowsPerPage;
    const endIndex = startIndex + table.rowsPerPage;

    setShowData(fullData.slice(startIndex, endIndex));
    table.onRefetched();
  }

  useEffect(() => {
    table.isRefetch.onTrue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  return (
    <Table
      useTable={table}
      isLoading={searchLoading}
    />
  );
}

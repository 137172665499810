import { Box, Stack } from '@mui/material';
import Logo from 'src/components/logo/logo';

export default function AuthLayout({ children }) {
  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
        top: '0 !important',
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        width={'100%'}
      >
        <Box
          sx={{
            bgcolor: 'white',
            p: '3rem',
            borderRadius: '1rem',
            width: '524px',
            boxShadow: '1px 1px 0 rgba(0, 0, 0, 0.05)',
          }}
        >
          <Logo />
          <Box height={'1rem'} />
          {children}
        </Box>
      </Box>
    </Stack>
  );
}

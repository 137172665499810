import qz from 'qz-tray';
import { getCertificate, getSignature } from 'src/api/qz_tray';

export const initializeQzTray = () => {
  const startConnection = () => {
    const config = { retries: 5, delay: 1 };
    if (!qz.websocket.isActive()) {
      qz.websocket.connect(config).catch((error) => {
        console.log(error);
      });
    } else {
      console.log('An active connection with QZ already exists.', 'alert-warning');
    }
  };

  try {
    qz.security.setCertificatePromise(function (resolve, reject) {
      getCertificate()
        .then((data) => {
          const signedCertificate = data.searchResults;
          if (!signedCertificate.length) {
            throw new Error('No certificate received');
          }
          resolve(signedCertificate);
        })
        .catch((error) => {
          console.log(error);
          reject(error.message);
        });
    });
    qz.security.setSignatureAlgorithm('SHA512');
    qz.security.setSignaturePromise(function (toSign) {
      return async function (resolve, reject) {
        try {
          const response = await getSignature(toSign);
          resolve(response);
        } catch (error) {
          reject(error.message);
        }
      };
    });
    startConnection();
  } catch (e) {
    alert(`Error: ${e.message}`);
  }
};

export const TEMPERATURE_TYPES = [
  {
    codeValue: 'ambient',
    codeValueNameLocal: '상온',
    codeValueNameEnglish: 'ambient',
    displayOrder: 1,
    codeReference1: null,
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
  {
    codeValue: 'refrigerated',
    codeValueNameLocal: '냉장',
    codeValueNameEnglish: 'refrigerated',
    displayOrder: 2,
    codeReference1: null,
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
  {
    codeValue: 'frozen',
    codeValueNameLocal: '냉동',
    codeValueNameEnglish: 'frozen',
    displayOrder: 3,
    codeReference1: null,
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
  {
    codeValue: 'general',
    codeValueNameLocal: '일반',
    codeValueNameEnglish: 'general',
    displayOrder: 4,
    codeReference1: null,
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
];

export const TOTE_TYPES = [
  {
    codeValue: 'shipment',
    codeValueNameLocal: '출고용',
    codeValueNameEnglish: 'shipment',
    displayOrder: 1,
    codeReference1: null,
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
  {
    codeValue: 'inventory',
    codeValueNameLocal: '적치용',
    codeValueNameEnglish: 'inventory',
    displayOrder: 2,
    codeReference1: null,
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
];

export const RACK_TYPE = [
  {
    codeValue: 'pallet',
    codeValueNameLocal: '팔레트',
    codeValueNameEnglish: 'pallet',
    displayOrder: 1,
    codeReference1: null,
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
  {
    codeValue: 'carton',
    codeValueNameLocal: '카톤',
    codeValueNameEnglish: 'carton',
    displayOrder: 2,
    codeReference1: null,
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
  {
    codeValue: 'shelving',
    codeValueNameLocal: '선반',
    codeValueNameEnglish: 'shelving or Rack',
    displayOrder: 3,
    codeReference1: null,
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
  {
    codeValue: 'hanger',
    codeValueNameLocal: '행거',
    codeValueNameEnglish: 'hanger',
    displayOrder: 4,
    codeReference1: null,
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
];

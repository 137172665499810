export const DIMENSION_UNIT = [
  {
    codeValue: 'm',
    codeValueNameLocal: '미터',
    codeValueNameEnglish: 'Meter',
    displayOrder: 1,
    codeReference1: 'm',
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
  {
    codeValue: 'cm',
    codeValueNameLocal: '센티미터',
    codeValueNameEnglish: 'Centimeter',
    displayOrder: 2,
    codeReference1: 'cm',
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
  {
    codeValue: 'mm',
    codeValueNameLocal: '밀리미터',
    codeValueNameEnglish: 'Millimeter',
    displayOrder: 3,
    codeReference1: 'mm',
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
  {
    codeValue: 'in',
    codeValueNameLocal: '인치',
    codeValueNameEnglish: 'Inch',
    displayOrder: 4,
    codeReference1: 'in',
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
  {
    codeValue: 'ft',
    codeValueNameLocal: '피트',
    codeValueNameEnglish: 'Feet',
    displayOrder: 5,
    codeReference1: 'ft',
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
  {
    codeValue: 'yd',
    codeValueNameLocal: '야드',
    codeValueNameEnglish: 'Yard',
    displayOrder: 6,
    codeReference1: 'yd',
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
];

import { TopBar } from '@shopify/polaris';
import { ArrowLeftIcon } from '@shopify/polaris-icons';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import authStore from 'src/stores/auth_store';
import { getUserInitial, getUserName } from 'src/utils/format_data';
import { useGetMe } from 'src/api/user_api';
import Loading from 'src/components/loading';

export default function Topbar() {
  const { t } = useTranslation('auth');
  const { logout } = authStore();
  const { user, searchLoading } = useGetMe();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
    [],
  );

  const handleNavigationToggle = useCallback(() => {
    console.log('toggle navigation visibility');
  }, []);

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={[
        {
          items: [
            {
              content: t('auth:logout'),
              icon: ArrowLeftIcon,
              onAction: async () => await logout(),
            },
          ],
        },
      ]}
      name={getUserName(user)}
      initials={getUserInitial(user)}
      open={isUserMenuOpen}
      onToggle={toggleIsUserMenuOpen}
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      onNavigationToggle={handleNavigationToggle}
    />
  );

  if (searchLoading) {
    return <Loading />;
  }

  return topBarMarkup;
}

import axiosInstance, {
  fetcher,
  revalidateOnlyStaleOptions,
  noRevalidateOnlyStaleOptions,
} from 'src/utils/axios';
import { ENDPOINTS } from './endpoints';
import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';
import { getURL } from 'src/utils/url';
import { isArray, isEmpty } from 'src/utils/type_check';

var currentCourierURL = '';

var currentChannelsURL = '';

var currentLocationMapping = '';

export function useGetLocationCouriers(params) {
  var URL = getURL(ENDPOINTS.mapping.get_location_courier(params.locationId), params);
  if (!params.locationId) URL = null;
  currentCourierURL = URL;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);
  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );
  return memoizedValue;
}

export function useGetLocationMapping(locationId) {
  var URL = ENDPOINTS.mapping.get_location_mapping(locationId);
  if (isEmpty(locationId)) URL = null;
  currentLocationMapping = URL;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);
  const memoizedValue = useMemo(
    () => ({
      searchCount: {
        courier: data?.result?.courierMappingInfo?.count?.totalCount || 0,
        user: data?.result?.userMappingInfo?.count?.totalCount || 0,
      },
      courierList: data?.result?.courierMappingInfo?.list || [],
      userList: data?.result?.userMappingInfo?.list || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );
  return memoizedValue;
}

export async function deleteCourierFromLocation(locationId, courierId) {
  const URL = ENDPOINTS.mapping.delete_location_courier(locationId, courierId);
  var ret = await axiosInstance.delete(URL);
  await mutate(currentCourierURL);
  await mutate(currentLocationMapping);
  return ret.data;
}

export async function createCourierFromLocation(locationId, data) {
  var ret = await axiosInstance.post(ENDPOINTS.mapping.post_location_courier, data);
  await mutate(currentCourierURL);
  await mutate(currentLocationMapping);
  return ret.data;
}

export async function updateCourierFromLocation(locationId, courierId, data) {
  var ret = await axiosInstance.put(
    ENDPOINTS.mapping.put_location_courier(locationId, courierId),
    data,
  );
  await mutate(currentCourierURL);
  await mutate(currentLocationMapping);
  return ret.data;
}

export function useGetClientChannels(params) {
  const URL = getURL(ENDPOINTS.mapping.get_channels, params);
  currentChannelsURL = URL;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);
  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );
  return memoizedValue;
}

export function useGetChannelSort() {
  const URL = ENDPOINTS.mapping.get_channels_sort;
  const { data, isLoading, error, isValidating } = useSWR(
    URL,
    fetcher,
    noRevalidateOnlyStaleOptions,
  );
  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || {},
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );

  return memoizedValue;
}

export async function postClientInstallChannel(data) {
  var ret = await axiosInstance.post(ENDPOINTS.mapping.post_channel, data);
  if (ret.data.isSuccess) mutate(currentChannelsURL);
  return ret.data;
}

export async function deleteClientUninstallClient(userId, clientId, mappingCode) {
  const URL = ENDPOINTS.mapping.delete_channel(userId, clientId, mappingCode);
  var ret = await axiosInstance.delete(URL);
  if (ret.data.isSuccess) mutate(currentChannelsURL);
  return ret.data;
}
var userLocationURL = '';

export function useGetUserLocations(params) {
  var URL = getURL(ENDPOINTS.mapping.get_user_location(params.UserId), params);
  if (isEmpty(params.UserId)) URL = null;
  userLocationURL = URL;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);
  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );

  return memoizedValue;
}

export async function connectUserLocation(data) {
  var isSuccess = false;
  var ret = await axiosInstance.post(ENDPOINTS.mapping.post_user_location, data);
  if (ret.data.isSuccess) {
    isSuccess = true;
  }
  if (isSuccess) {
    mutate(userLocationURL);
    mutate(currentLocationMapping);
  }
  return ret.data;
}

export async function connectUsersLocation(users, locationId) {
  if (!isArray(users)) users = [users];
  var isSuccess = false;
  for (var i = 0; i < users.length; i++) {
    var ret = await axiosInstance.post(ENDPOINTS.mapping.post_user_location, {
      userId: users[i],
      locationId,
    });
    if (ret?.data?.isSuccess) {
      isSuccess = true;
    }
  }
  if (isSuccess) {
    mutate(currentCourierURL);
    mutate(currentLocationMapping);
  }
  return ret.data;
}

export async function deleteUserLocation(userId, locationId) {
  if (!isArray(userId)) userId = [userId];
  var isSuccess = false;
  for (var i = 0; i < userId.length; i++) {
    var URL = ENDPOINTS.mapping.delete_user_location(userId[i], locationId);
    if (!userId[i] || !locationId) URL = null;
    var ret = await axiosInstance.delete(URL);
    if (ret.data.isSuccess) isSuccess = true;
  }
  if (isSuccess) {
    mutate(userLocationURL);
    mutate(currentCourierURL);
    mutate(currentLocationMapping);
  }
  return ret.data;
}

export function useGetMappings(userId) {
  var URL = getURL(ENDPOINTS.mapping.get_all, { userId: userId });
  if (isEmpty(userId)) URL = null;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);
  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || {},
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );

  return memoizedValue;
}

import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '@shopify/polaris';
import LocManTableView from 'src/sections/settings/location/table/loc_man_table_view';
import LocationTitle from 'src/components/title/location_title';
import { print } from 'src/utils/log';
import RackForm from './rack_form';
import { isEmpty } from 'src/utils/type_check';
import locationStore from 'src/stores/location_store';
import { useGetLocationsManagement } from 'src/api/location_api';
import { cleanParams } from 'src/utils/url';
import QZPrint from 'src/components/qz-tray';
import ToteForm from './tote_form';

export const MODES = {
  VIEW: 'view',
  FORM: 'form',
  TOTEEDIT: 'edit',
};

const LocManagerView = () => {
  const { t } = useTranslation(['settings']);
  const [type, setType] = useState('rack');
  const [mode, setMode] = useState(MODES.VIEW);
  const [rack, setRack] = useState({});
  const [tote, setTote] = useState(null);
  const selectedLocation = locationStore((state) => state.getLocationId('locationManagement'));
  const { setLocationId: setSelectedLocation } = locationStore();
  const locationId = selectedLocation === 'all' ? '' : selectedLocation;
  const [printerConfig, setPrinterConfig] = useState({});

  const [params, setParams] = useState({
    pageSize: 20,
    pageNumber: 1,
    IsActive: true,
    locationId: locationId,
    locationType: '',
    temperatureType: '',
    q: '',
  });

  const { searchResults: data = {}, searchLoading } = useGetLocationsManagement(
    type,
    cleanParams(params),
  );

  const handleRowClick = (item) => {
    setRack(item);
    setMode(MODES.FORM);
  };

  const handleRowClickTote = (item) => {
    setTote(item);
  };

  const toggleCreateModal = () => {
    setMode(MODES.TOTEEDIT);
    setTote(null);
  };

  const handleView = useCallback(() => {
    setRack({});
    setMode(MODES.VIEW);
  }, []);

  const handleViewTote = useCallback(() => {
    setRack({});
    setType('tote');
    setMode(MODES.VIEW);
  }, []);

  const handleNewForm = useCallback(() => {
    setRack({});
    setMode(MODES.FORM);
  }, []);

  const renderTitle = (
    <LocationTitle
      handleLocationChange={(value) => setSelectedLocation('locationManagement', value)}
      selectedLocation={selectedLocation}
      title={t('settings:locations_management.loc_man_of')}
      hasBottomPadding={false}
    />
  );

  print('location_management load');

  const renderLocationManagementPage = () => {
    if (mode === MODES.VIEW) {
      return (
        <LocManTableView
          selectedLocation={selectedLocation}
          toggleToteModal={toggleCreateModal}
          toggleRackView={handleNewForm}
          type={type}
          setType={setType}
          handleRowClick={type === 'rack' ? handleRowClick : handleRowClickTote}
          data={data}
          setParams={setParams}
          setMode={setMode}
          searchLoading={searchLoading}
        />
      );
    } else if (mode === MODES.FORM) {
      return (
        <RackForm
          onSuccess={() => setMode(MODES.VIEW)}
          rack={rack}
          onBack={handleView}
          printerConfig={printerConfig}
        />
      );
    } else {
      return (
        <ToteForm
          tote={tote}
          isEdit={tote ? true : false}
          handleViewTote={handleViewTote}
          printerConfig={printerConfig}
        />
      );
    }
  };
  const showPrinter = mode === MODES.VIEW && type === 'tote';
  return (
    <>
      <Page
        fullWidth
        title={
          mode === MODES.FORM
            ? !isEmpty(rack)
              ? rack.rackNumber
              : t('settings:locations_management.create_rack')
            : mode === MODES.VIEW
              ? renderTitle
              : tote
                ? t('settings:locations_management.edit_tote')
                : t('settings:locations_management.create_tote')
        }
        primaryAction={
          mode === MODES.VIEW && data?.list?.length > 0
            ? type === 'rack'
              ? {
                  content: t('settings:locations_management.create_rack'),
                  onAction: handleNewForm,
                }
              : {
                  content: t('settings:locations_management.create_tote'),
                  onAction: toggleCreateModal,
                }
            : null
        }
        secondaryActions={
          data?.list?.length > 0 && !showPrinter
            ? [
                {
                  content: (
                    <QZPrint
                      setPrinterConfig={setPrinterConfig}
                      setting={true}
                    />
                  ),
                  onAction: async () => {},
                },
              ]
            : []
        }
        backAction={
          mode === MODES.FORM || mode === MODES.TOTEEDIT
            ? mode === MODES.FORM
              ? { content: t('common:Back'), onAction: handleView }
              : { content: t('common:Back'), onAction: handleViewTote }
            : undefined
        }
      >
        {renderLocationManagementPage()}
        <div className="bottom-padding"></div>
      </Page>
    </>
  );
};

export default LocManagerView;

import { fetcher, noRevalidateOnlyStaleOptions } from 'src/utils/axios';
import { ENDPOINTS } from './endpoints';
import { useMemo } from 'react';
import useSWR from 'swr';

export function useGetRoles() {
  const URL = ENDPOINTS.role.get;
  const { data, isLoading, error, isValidating } = useSWR(
    URL,
    fetcher,
    noRevalidateOnlyStaleOptions,
  );
  const memoizedValue = useMemo(
    () => ({
      roleResults: data?.result || [],
      roleLoading: isLoading,
      roleError: error,
      roleValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );
  return memoizedValue;
}

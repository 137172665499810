import axiosInstance, { fetcher, revalidateOnlyStaleOptions } from 'src/utils/axios';
import { ENDPOINTS } from './endpoints';
import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';
import { getURL } from 'src/utils/url';
import { cloneAndCleanIsActive, isArray } from 'src/utils/type_check';
import { isEmpty } from 'lodash';

var currentURL = '';

export function useGetPackingMaterials(params) {
  if (isEmpty(params)) {
    params = {
      pageSize: 5,
      pageNumber: 1,
      locationId: '',
      IsActive: true,
    };
  }
  var clone = cloneAndCleanIsActive(params, params.locationId);
  const URL = getURL(ENDPOINTS.packing_material.get_list, clone);
  currentURL = URL;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);
  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result?.list || [],
      searchCount: data?.result?.count || {},
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );
  return memoizedValue;
}

export function useGetPackingMaterial(id, params) {
  const URL = getURL(ENDPOINTS.packing_material.get(id), params);
  currentURL = URL;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);
  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result?.packingMaterial || {},
      searchHistory: data?.result?.packingMaterialHistory?.list || [],
      searchHistoryCount: data?.result?.packingMaterialHistory?.count || {},
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );

  return memoizedValue;
}

export async function createPackingMaterial(data) {
  var ret = await axiosInstance.post(ENDPOINTS.packing_material.post, data);
  await mutate(currentURL);
  return ret.data;
}

export async function editPackingMaterial(data, id) {
  const endpoint = ENDPOINTS.packing_material.put(id);
  const response = await axiosInstance.put(endpoint, data);
  await mutate(currentURL);
  return response.data;
}

export async function deletePackingMaterial(ids) {
  if (!isArray(ids)) ids = [ids];
  var isSuccess = false;
  for (var i = 0; i < ids.length; i++) {
    var ret = await axiosInstance.delete(ENDPOINTS.packing_material.delete(ids[i]));
    if (ret.data.isSuccess) {
      isSuccess = true;
    }
  }
  if (isSuccess) mutate(currentURL, (d) => d, true);
}

import i18n from 'src/locales/i18n';
import { Badge } from 'src/components/badge/badge';
import { Button } from '@shopify/polaris';
import { deleteConfirm } from 'src/components/dialog/confirmation';
import { deletePackingMaterial } from 'src/api/packing_materials_api';
import BulkButton from 'src/components/bulk_button';
import { DeleteIcon } from '@shopify/polaris-icons';
import EmptyStateCard from 'src/components/cards/empty_state_card';

i18n.loadNamespaces(['settings']);

export const tabs = (count) => [
  {
    label: i18n.t('common:tab.all'),
    value: 'All',
    badge: <Badge>{count?.allCount ?? ''}</Badge>,
  },
];

export const bulkAction = (data, selectedIds, handleSelectionChange) => {
  return [
    {
      content: (
        <BulkButton
          content={i18n.t('common:delete')}
          icon={DeleteIcon}
        />
      ),
      destructive: true,
      onAction: async () => {
        const confirmation = await deleteConfirm();
        if (confirmation) {
          deletePackingMaterial(selectedIds);
          handleSelectionChange('page', false);
        }
      },
    },
  ];
};

export const headers = () => {
  return [
    { title: i18n.t('common:field.package_name') },
    { title: i18n.t('common:field.location') },
    { title: i18n.t('common:field.package_id') },
    { title: i18n.t('common:field.package_type') },
    { title: i18n.t('common:field.barcode') },
    { title: i18n.t('common:field.dimension') },
    { title: i18n.t('common:field.weight') },
    { title: i18n.t('common:field.quantity') },
    { title: i18n.t('common:field.purchase_cost') },
  ];
};

export const EmptyStateMarkup = ({ handleNewForm }) => {
  return (
    <EmptyStateCard
      heading={i18n.t('settings:packing_material.please_create_material')}
      body={i18n.t('settings:packing_material.create_material_description')}
      image="/images/alertIcon.png"
      center
      card={false}
    >
      <Button
        variant={'secondary'}
        onClick={() => {
          handleNewForm();
        }}
      >
        {i18n.t('settings:packing_material.create_material')}
      </Button>
    </EmptyStateCard>
  );
};

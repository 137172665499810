import { IndexTable } from '@shopify/polaris';
import { chooseLan } from 'src/utils/language_code';
import { makeCombinedId } from 'src/utils/format_data';
import { Link, Typography } from '@mui/material';
import { MODES } from '../location_management_view';

const rowMarkup = ({ data, selectedItems }, type, handleRowClick, setMode) => {
  return data.map((elem, index) => {
    const uniqueId =
      type === 'rack'
        ? makeCombinedId(elem.rackNumber, elem.locationId)
        : makeCombinedId(elem.toteNumber, elem.locationId);

    return (
      <IndexTable.Row
        id={uniqueId}
        key={uniqueId}
        selected={selectedItems.includes(uniqueId)}
        position={index}
        onClick={() => {
          if (type !== 'rack') {
            setMode(MODES.TOTEEDIT);
          }
          handleRowClick(elem);
        }}
      >
        {type === 'rack' ? (
          <>
            <IndexTable.Cell>
              <Link underline="hover">
                <Typography variant="body_md_medium">{elem.rackNumber}</Typography>
              </Link>
            </IndexTable.Cell>
            <IndexTable.Cell>
              {chooseLan(elem.locationNameLocal, elem.locationNameEnglish)}
            </IndexTable.Cell>
            <IndexTable.Cell>{elem.rackSlotBarcode}</IndexTable.Cell>
            <IndexTable.Cell>
              {chooseLan(elem.locationTypeNameLocal, elem.locationTypeNameEnglish)}
            </IndexTable.Cell>
            <IndexTable.Cell>
              {chooseLan(elem.temperatureTypeNameLocal, elem.temperatureTypeNameEnglish)}
            </IndexTable.Cell>
          </>
        ) : (
          <>
            <Typography variant="body_md_medium">{elem.toteNumber}</Typography>

            <IndexTable.Cell>
              {chooseLan(elem.locationNameLocal, elem.locationNameEnglish)}
            </IndexTable.Cell>
            <IndexTable.Cell>
              {chooseLan(elem.toteTypeNameLocal, elem.toteTypeNameEnglish)}
            </IndexTable.Cell>
            <IndexTable.Cell>{elem.toteBarcode}</IndexTable.Cell>
            <IndexTable.Cell>{elem.toteCount}</IndexTable.Cell>
            <IndexTable.Cell>{elem.description}</IndexTable.Cell>
          </>
        )}
      </IndexTable.Row>
    );
  });
};
export default rowMarkup;

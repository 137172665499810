import axiosInstance from 'src/utils/axios';
import { ENDPOINTS } from './endpoints';
import { getURL } from 'src/utils/url';

export async function getSignature(toSign) {
  const toSignJSON = JSON.stringify(toSign);
  try {
    var ret = await axiosInstance.post(ENDPOINTS.qz.post_signature, toSignJSON, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return ret.data;
  } catch (error) {
    throw new Error('Error calling sign-message API: ' + error);
  }
}

export async function getCertificate() {
  const URL = getURL(ENDPOINTS.qz.get_certificate);

  try {
    const response = await axiosInstance.get(URL);
    return {
      searchResults: response.data ?? [],
    };
  } catch (error) {
    console.log(error);
    return {
      searchResults: [],
    };
  }
}

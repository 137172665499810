import { memo } from 'react';
import Stack from '@mui/material/Stack';
import NavList from './nav_list';

function NavSectionMini({ data, sx, currentNav, setHovering, hovering }) {
  const renderContent = data.map((list, index) => (
    <NavList
      key={index}
      data={list}
      isSelected={currentNav}
      hovering={hovering}
      setHovering={setHovering}
    />
  ));

  return <Stack sx={sx}>{renderContent}</Stack>;
}

export default memo(NavSectionMini);

import Collapse from '@mui/material/Collapse';
import NavItem from './nav_item';
import { useActiveLink } from 'src/hooks/router/use_active_link';

export default function NavList({
  data,
  depth,
  hasChild,
  isSelected,
  open,
  onToggle,
  child = false,
}) {
  let active = useActiveLink(data.url, true);

  if (isSelected) {
    active = isSelected === data.key;
  }

  const handleClick = () => {
    if (data.url && onToggle) {
      onToggle(!open);
    }
    if (data.onClick) {
      data.onClick();
    }
  };
  return (
    <>
      <NavItem
        item={data}
        depth={depth}
        open={open}
        active={active}
        onClick={handleClick}
        child={child}
      />
      {hasChild && (
        <Collapse
          in={open}
          unmountOnExit
        >
          <NavSubList
            data={data.children}
            depth={depth}
            isSelected={isSelected}
          />
        </Collapse>
      )}
    </>
  );
}

function NavSubList({ data, depth, isSelected }) {
  return (
    <>
      {data.map((list, index) => (
        <NavList
          key={index}
          data={list}
          child={true}
          depth={depth + 1}
          hasChild={!!list.children}
          isSelected={isSelected}
        />
      ))}
    </>
  );
}

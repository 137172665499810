import { Alert, Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getContainerByScan, postContainerScan } from 'src/api/outbound/shipping_api';
import { useBoolean } from 'src/hooks/use_boolean';
import { isEmpty } from 'src/utils/type_check';
import ScanCard from 'src/components/cards/scan_card';

const ShippingContainersScanView = ({ selectedLocation }) => {
  const { t } = useTranslation(['shipping']);
  const [shippedRes, setShippedRes] = useState({});
  const [showScan, setShowScan] = useState(false);
  const showAlert = useBoolean();
  const noOrder = useBoolean(true);
  const noContainer = useBoolean(true);

  const [shipmentDetails, setShipmentDetails] = useState({
    orderShipmentId: '',
    trackingNumber: '',
    courier: '',
  });
  const onScan = async (search) => {
    if (isEmpty(search)) return;
    if (noOrder.value) {
      showAlert.onFalse();
      await fetchOrderShipmentId(search);
    } else {
      await submitContainerScan(search);
    }
  };

  const fetchOrderShipmentId = async (search) => {
    try {
      const res = await getContainerByScan(selectedLocation, search);
      if (res.isSuccess) {
        setShipmentDetails({
          orderShipmentId: res.result?.orderShipment?.orderShipmentId || '',
          trackingNumber: res.result?.orderShipment?.courierTrackingNumber,
          courier: res.result?.orderShipment?.courierName,
        });
        noOrder.onFalse();
        noContainer.onTrue();
      }
      setShippedRes(res);
    } catch (error) {
      console.error('Error fetching order shipment ID:', error);
      setShippedRes({ isSuccess: false, errorMessages: [t('shipping:api_error')] });
    } finally {
      showAlert.onTrue();
    }
  };

  const submitContainerScan = async (search) => {
    try {
      const data = {
        orderShipmentId: shipmentDetails.orderShipmentId,
        shipmentContainerNumber: search,
      };
      const res = await postContainerScan(data);
      if (res.statusCode === 200) {
        setShippedRes(res);
        noContainer.onFalse();
        noOrder.onTrue();
      } else if (res.statusCode === 400) {
        setShippedRes(res);
      } else {
        setShowScan(true);
        setShippedRes({ isSuccess: true });
      }
    } catch (error) {
      console.error('Error submitting container scan:', error);
      const trackingRes = await getContainerByScan(selectedLocation, search);
      if (!trackingRes.isSuccess) {
        setShippedRes({ isSuccess: false, errorMessages: [t('shipping:api_error')] });
      }
    } finally {
      showAlert.onTrue();
    }
  };

  const renderAlert = () => {
    return (
      <Box sx={{ pt: 1 }}>
        <Alert
          variant={'outlined'}
          onClose={showAlert.onFalse}
          severity={noContainer.value ? 'error' : shippedRes?.isSuccess ? 'success' : 'error'}
          sx={{ minWidth: '400px', maxWidth: '100%' }}
        >
          {shippedRes?.isSuccess ? (
            noContainer.value ? (
              <>
                <Typography variant="heading_sm">
                  {t('common:order-', {
                    orderNumber: shipmentDetails.trackingNumber,
                  })}
                  {shipmentDetails.courier}
                </Typography>{' '}
                <Typography variant="body_md">
                  {t('shipping:containers.order_package', {
                    carrier: shipmentDetails.courier,
                  })}
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="body_md_medium">
                  #{shipmentDetails.trackingNumber} - {shipmentDetails.courier}
                </Typography>{' '}
                <Typography variant="body_md">
                  {t('shipping:containers.container_added')}
                </Typography>
              </>
            )
          ) : (
            shippedRes.errorMessages.length > 0 && (
              <Typography variant="body_md">{shippedRes?.errorMessages[0]}</Typography>
            )
          )}
        </Alert>
      </Box>
    );
  };

  const renderInitialScanner = (
    <ScanCard
      onEnter={onScan}
      image="/images/container_scan.png"
      width={60}
      height={60}
      heading={t('shipping:containers.please_scan')}
      body={t('shipping:containers.please_scan_text')}
      error={noOrder && showScan && 'Scan Container'}
      label={
        noOrder.value
          ? t('shipping:containers.scan_tr_number')
          : t('shipping:containers.scan_container_number')
      }
      placeholder={
        noOrder.value
          ? t('shipping:containers.scan_tr_number')
          : t('shipping:containers.scan_container_number')
      }
      node={showAlert.value ? renderAlert() : null}
    />
  );

  return <Box>{renderInitialScanner}</Box>;
};

export default ShippingContainersScanView;

export const MATERIAL_TYPE = [
  {
    codeValue: 'box',
    codeValueNameLocal: 'box',
    codeValueNameEnglish: 'box',
    displayOrder: 1,
    codeReference1: null,
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
  {
    codeValue: 'bag',
    codeValueNameLocal: 'bag',
    codeValueNameEnglish: 'bag',
    displayOrder: 2,
    codeReference1: null,
    codeReference2: null,
    codeReference3: null,
    codeReference4: null,
    description: null,
    isActive: true,
  },
];

import qz from 'qz-tray';
import { toast } from 'react-toastify';

const QZPrintFile = async (file, config) => {
  const data = [
    {
      type: 'pixel',
      format: 'pdf',
      flavor: 'base64',
      data: file,
    },
  ];
  qz.print(config, data).catch((err) => {
    toast.error(`${err}`);
  });
};

export default QZPrintFile;

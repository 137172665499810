import React, { useState, useEffect } from 'react';
import { IndexTable, Box } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { printRack, useGetRackSlots } from 'src/api/location_api';
import { print } from 'src/utils/log';
import { cleanParams } from 'src/utils/url';
import useTable from 'src/hooks/use_table';
import { BarcodeIcon } from '@shopify/polaris-icons';
import i18n from 'src/locales/i18n';
import Table from 'src/components/table/table';
import BulkButton from 'src/components/bulk_button';
import QZPrintFile from 'src/utils/print';
import { toast } from 'react-toastify';

i18n.loadNamespaces(['settings']);

const headers = () => {
  return [{ title: i18n.t('common:field.slot') }];
};

const rowMarkup = ({ data, selectedItems, handleSelectionChange, searchQuery }, text, setText) => {
  return data.map(({ rackSlotNumber }, index) => {
    const isMatch = text === rackSlotNumber;
    const isSelected = selectedItems.includes(rackSlotNumber);
    if (isMatch && !isSelected) {
      setText('');
      handleSelectionChange('single', true, rackSlotNumber);
    }

    return (
      <IndexTable.Row
        id={rackSlotNumber}
        key={rackSlotNumber}
        position={index}
        selected={isSelected}
        onClick={() => {}}
      >
        <IndexTable.Cell>{rackSlotNumber}</IndexTable.Cell>
      </IndexTable.Row>
    );
  });
};

export const bulkAction = (
  data,
  selectedIds,
  handleSelectionChange,
  handleEmpty,
  printerConfig,
  locationId,
  rackNumber,
) => {
  return [
    {
      content: (
        <BulkButton
          content={i18n.t('common:print_barcode')}
          icon={BarcodeIcon}
        />
      ),
      onAction: async () => {
        try {
          const selectedIdData = selectedIds.map((ids) => {
            return { rackSlotNumber: ids };
          });
          const data = {
            locationId: locationId,
            rackNumber: rackNumber,
            rackSlotNumbers: selectedIdData,
          };

          const racks = await printRack(data);
          if (racks.isSuccess) {
            await Promise.all(
              racks.map(async (data) => {
                await QZPrintFile(data?.pdfFile, printerConfig);
              }),
            );
          } else {
            toast.error('Could not print racks');
          }
          handleSelectionChange('page', false);
          handleEmpty();
        } catch (err) {
          toast.error(err);
        }
      },
    },
  ];
};

export function RackSlot({ rack, printerConfig }) {
  const { t } = useTranslation();
  const [text, setText] = useState('');

  const [params, setParams] = useState({
    pageSize: 20,
    pageNumber: 1,
    RackSlotBarcode: '',
  });

  const { searchResults: rackData, searchLoading: loading } = useGetRackSlots(
    rack.locationId,
    rack.rackNumber,
    cleanParams(params),
  );

  const handleEmpty = () => {
    setText('');
    setParams((prevParams) => ({
      ...prevParams,
      RackSlotBarcode: '',
    }));
  };
  const table = useTable({
    data: rackData?.list?.length > 0 ? rackData.list[0].rackSlots : [],
    totalDataCount: rackData.count?.totalCount ?? 0,
    bulkAction: (data, selectedIds, handleSelectionChange) =>
      bulkAction(
        data,
        selectedIds,
        handleSelectionChange,
        handleEmpty,
        printerConfig,
        rack.locationId,
        rack.rackNumber,
      ),
    resourceIdResolver: (d) => d.rackSlotNumber,
    headers: headers(),
    rowMarkup: (props) => rowMarkup(props, text, setText),
    searching: t('settings:locations_management.search_for_slot'),
    searchQueryIcon: BarcodeIcon,
    debounceDelay: 0,
    searchOnEnter: true,
    keepSelectedOnPagination: true,
  });

  useEffect(() => {
    if (table.isRefetch.value) {
      setParams((prevParams) => ({
        ...prevParams,
        pageSize: table.rowsPerPage,
        pageNumber: table.page,
        RackSlotBarcode: table.debounceSearchQuery,
      }));
      setText(table.debounceSearchQuery);
      table.onRefetched(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.debounceSearchQuery, table.rowsPerPage, table.page, text]);

  print('rack_slot load');

  return (
    <Box
      paddingInline={0}
      paddingBlock={400}
    >
      <Table
        useTable={table}
        isLoading={loading}
      />
    </Box>
  );
}

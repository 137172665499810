import axiosInstance, { fetcher, revalidateOnlyStaleOptions } from 'src/utils/axios';
import { ENDPOINTS } from './endpoints';
import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';
import { cleanParams, getURL } from 'src/utils/url';
import { isArray } from 'src/utils/type_check';
import { isEmptyObject, isEmpty } from 'src/utils/type_check';
import _ from 'lodash';

var currentURL = '';

export function useGetSorter(params) {
  const URL = getURL(ENDPOINTS.machine.sorter.get_list, params);
  currentURL = URL;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result?.list || [],
      searchCount: data?.result?.count || {},
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data, error, isLoading, isValidating],
  );
  return memoizedValue;
}

export async function postSorter(data) {
  var ret = await axiosInstance.post(ENDPOINTS.machine.sorter.post, data);
  await mutate(currentURL);
  return ret.data;
}

export async function putSorter(data, locationId, doorId) {
  var ret = await axiosInstance.put(ENDPOINTS.machine.sorter.put(locationId, doorId), data);
  await mutate(currentURL);
  return ret.data;
}

export async function deleteSorter(ids) {
  if (!isArray(ids)) ids = [ids];
  var isSuccess = false;
  for (var i = 0; i < ids.length; i++) {
    var ret = await axiosInstance.delete(
      ENDPOINTS.machine.sorter.delete(ids[i].locationId, ids[i].doorId),
    );
    if (ret.data.isSuccess) {
      isSuccess = true;
    }
  }
  if (isSuccess) mutate(currentURL, (d) => d, true);
}

export function useGetMachines(params) {
  const URL = getURL(ENDPOINTS.machine.get_list, params);
  currentURL = URL;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result?.list || [],
      searchCount: data?.result?.count || {},
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data, error, isLoading, isValidating],
  );
  return memoizedValue;
}

function checkEndpoints(clone) {
  if (clone.machineEndpoints) {
    clone.machineEndpoints = clone.machineEndpoints.filter((variant) => !isEmptyObject(variant));
  }
  if (
    !clone.machineEndpoints ||
    isEmpty(clone.machineEndpoints) ||
    (clone.machineEndpoints.length === 1 && isEmptyObject(clone.machineEndpoints[0]))
  ) {
    clone.machineEndpoints = [];
  }
  return clone.machineEndpoints;
}

export async function postMachine(data) {
  var clone = _.cloneDeep(cleanParams(data));
  clone.machineEndpoints = checkEndpoints(clone);

  var ret = await axiosInstance.post(ENDPOINTS.machine.post_machine, clone);
  await mutate(currentURL);
  return ret.data;
}

export async function updateMachine(data, machineId) {
  var clone = _.cloneDeep(cleanParams(data));
  clone.machineEndpoints = checkEndpoints(clone);
  console.log('CLONE: ', clone);
  var ret = await axiosInstance.put(ENDPOINTS.machine.put_machine(machineId), clone);
  console.log(ret.data);
  await mutate(currentURL);
  return ret.data;
}

export function useGetMachine(machineId) {
  var URL = ENDPOINTS.machine.get(machineId);
  currentURL = URL;
  if (!machineId) URL = null;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || {},
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data, error, isLoading, isValidating],
  );
  return memoizedValue;
}

export async function deleteMachine(ids) {
  if (!isArray(ids)) ids = [ids];
  var isSuccess = false;
  for (var i = 0; i < ids.length; i++) {
    var ret = await axiosInstance.delete(ENDPOINTS.machine.delete_machine(ids[i].machineId));
    if (ret.data.isSuccess) {
      isSuccess = true;
    }
  }
  if (isSuccess) mutate(currentURL, (d) => d, true);
}

export async function deleteMachineEndpoint(machineId, sequence) {
  const endpoint = ENDPOINTS.machine.delete_machine_endpoint(machineId, sequence);
  const response = await axiosInstance.delete(endpoint);
  await mutate(currentURL);
  return response.data;
}

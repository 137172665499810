import { deleteToken } from 'src/utils/token';
import { useCountdownSeconds } from 'src/hooks/use_countdown';
import { useEffect } from 'react';
import { useBoolean } from 'src/hooks/use_boolean';
import { useTranslation } from 'react-i18next';
import authStore from 'src/stores/auth_store';
import { toBool } from 'src/utils/type_check';
import { print } from 'src/utils/log';
import { confirmable } from 'react-confirm';
import StackedModal from './stacked_modal';

function TokenDialog({ show, proceed }) {
  const { t } = useTranslation();
  const { refresh } = authStore();
  const { countdown, startCountdown, stopCountdown } = useCountdownSeconds(60);
  const refreshClicked = useBoolean();

  useEffect(() => {
    if (toBool(show)) {
      startCountdown();
      refreshClicked.onFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (!countdown && !refreshClicked.value) {
      deleteToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);

  print('token_dialog load');

  return (
    <StackedModal
      isOpen={show}
      title={t('common:timeout.title')}
      content={`${t('common:timeout.content')} ${countdown}`}
      primaryAction={{
        content: t('common:refresh'),
        onAction: async () => {
          stopCountdown();
          refreshClicked.onTrue();
          await refresh();
          proceed(true);
        },
      }}
      onClose={() => {
        deleteToken();
        stopCountdown();
        proceed(false);
      }}
    />
  );
}

export default confirmable(TokenDialog);

import React, { useMemo, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { channelFormSchema } from 'src/utils/schema';
import FormProvider from 'src/components/hook_form/form_provider';
import RHFTextField from 'src/components/hook_form/rhf_text_field';
import { InlineGrid, Card, Button, Box, InlineStack, Banner } from '@shopify/polaris';
import CommonSelect from 'src/components/hook_form/common_select';
import { COUNTY_CODE_SHORT } from 'src/api/common_code/country_code';
import { createChannel, putClient } from 'src/api/client_api';
import { requiredField } from 'src/utils/format_data';
import { toast } from 'react-toastify';

const ChannelForm = ({ onSuccess, channelData }) => {
  const { t } = useTranslation(['settings']);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const clientId = channelData?.clientId;
  const defaultValues = useMemo(
    () => ({
      clientNameLocal: channelData.clientNameLocal || '',
      clientNameEnglish: channelData.clientNameEnglish || '',
      clientType: 'channel',
      userId: channelData.userId || null,
      countryCode: channelData.countryCode || '',
      postalCode: channelData.postalCode || '',
      state: channelData.state || '',
      city: channelData.city || '',
      address1Local: channelData.address1Local || '',
      address2Local: channelData.address2Local || '',
      representativeName: channelData.representativeName || '',
      businessRegistrationNumber: channelData.businessRegistrationNumber || '',
      representativeTelNumber: channelData.representativeTelNumber || '',
      representativeEmail: channelData.representativeEmail || '',
      managerTelNumber: channelData.managerTelNumber || '',
      managerEmail: channelData.managerEmail || '',
      description: channelData.description || '',
    }),
    [channelData],
  );

  const isEdit = !!clientId;
  const methods = useForm({
    resolver: yupResolver(channelFormSchema(t)),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = methods;
  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      var ret;
      data.clientType = 'channel';
      if (isEdit) {
        ret = await putClient(channelData.clientId, data);
        if (ret.isSuccess) {
          setSuccessMsg(t('settings:channels.update_success'));
        } else {
          throw Error(ret.errorMessage[0]);
        }
      } else {
        const res = await createChannel(data);
        if (res.isSuccess) {
          onSuccess();
          toast.success(t('settings:channels.create_success'));
        } else {
          setErrorMsg(res.errorMessages[0] ?? '');
        }
      }
    } catch (error) {
      reset();
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });

  const renderForm = (
    <Stack spacing={1}>
      <Card>
        <Box paddingBlockEnd={200}>
          <Typography variant="body_md_semibold">{t('common:field.channel_name')}</Typography>
        </Box>
        <InlineGrid
          columns={4}
          gap="200"
        >
          <RHFTextField
            name="clientNameLocal"
            displayName={requiredField(t('common:field.nameLocal'))}
          />
          <RHFTextField
            name="clientNameEnglish"
            displayName={t('common:field.nameEnglish')}
          />
          <RHFTextField
            name="representativeName"
            displayName={t('common:field.representative_name')}
          />
          <RHFTextField
            name="representativeEmail"
            displayName={t('common:field.representative_email')}
          />
        </InlineGrid>
      </Card>

      <Card>
        <Box paddingBlockEnd={200}>
          <Typography variant="body_md_semibold">{t('common:field.section_address')}</Typography>
        </Box>
        <Stack spacing={2}>
          <CommonSelect
            data={COUNTY_CODE_SHORT}
            name="countryCode"
            displayName={t('common:field.country')}
          />

          <InlineGrid
            columns={2}
            gap="200"
          >
            <RHFTextField
              name="state"
              displayName={t('common:field.state')}
            />
            <RHFTextField
              name="city"
              displayName={t('common:field.city')}
            />
          </InlineGrid>
          <InlineGrid
            columns={2}
            gap="200"
          >
            <RHFTextField
              name="address1Local"
              displayName={t('common:field.address1')}
            />
            <RHFTextField
              name="address2Local"
              displayName={t('common:field.address2')}
            />
          </InlineGrid>
          <InlineGrid
            columns={2}
            gap="200"
          >
            <RHFTextField
              name="postalCode"
              displayName={t('common:field.postal_code')}
            />

            <RHFTextField
              name="representativeTelNumber"
              displayName={t('common:field.phone')}
              placeholder="000-0000-0000"
            />
          </InlineGrid>
        </Stack>
      </Card>
    </Stack>
  );

  return (
    <FormProvider
      methods={methods}
      onSubmit={onSubmit}
    >
      <Stack spacing={1}>
        {!!errorMsg && (
          <Banner
            tone="critical"
            onDismiss={() => setErrorMsg('')}
          >
            {errorMsg}
          </Banner>
        )}
        {!!successMsg && (
          <Banner
            tone="success"
            onDismiss={() => setSuccessMsg('')}
          >
            {successMsg}
          </Banner>
        )}
        {renderForm}
        <InlineStack
          align="end"
          gap={200}
          direction={'row'}
        >
          <Button
            submit={onSubmit}
            loading={isSubmitting}
            size="large"
            variant="primary"
            disabled={!isDirty}
          >
            {isEdit ? t('common:save') : t('common:submit')}
          </Button>
        </InlineStack>
      </Stack>
    </FormProvider>
  );
};

export default ChannelForm;

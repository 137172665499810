import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

const initialState = {
  packingIsSingle: true,
};

const store = (set, get) => ({
  ...initialState,

  setPackingIsSingle: (val) => {
    set((prev) => ({ ...prev, packingIsSingle: val }));
  },
  reset: () => {
    set(initialState);
  },
});

const settingStore = create(
  persist(store, {
    name: 'setting',
    storage: createJSONStorage(() => localStorage),
  }),
);

export default settingStore;

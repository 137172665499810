import numeral from 'numeral';
import { isEmpty } from './type_check';

// ----------------------------------------------------------------------

const engFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const korFormatter = new Intl.NumberFormat('ko-KR', {
  style: 'currency',
  currency: 'KRW',
});

const jpyFormatter = new Intl.NumberFormat('ja-JP', {
  style: 'currency',
  currency: 'JPY',
});

export function fNumber(number) {
  return numeral(number).format();
}

export function getCurrencySymbol(code) {
  code = code.toUpperCase();
  if (code === 'KRW') return '₩';
  else if (code === 'USD') return '$';
  else if (code === 'JPY') return '¥';
}

export function fCurrency(number, code = 'USD') {
  if (isEmpty(number) || isEmpty(code)) return '';
  code = code.toUpperCase();
  let formatter;
  if (code === 'KRW') formatter = korFormatter;
  else if (code === 'USD') formatter = engFormatter;
  else if (code === 'JPY') formatter = jpyFormatter;
  else formatter = engFormatter; // Default to USD if code is unsupported
  return formatter.format(number);
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function formatSlotNumber(number) {
  if (number >= 1 && number <= 200) {
    return number.toString().padStart(3, '0');
  } else {
    return '';
  }
}

export function getDimension(l, w, h, unit) {
  let dimensions = [];
  if (!isEmpty(l)) dimensions.push(l);
  if (!isEmpty(w)) dimensions.push(w);
  if (!isEmpty(h)) dimensions.push(h);
  if (dimensions.length === 0) {
    return '';
  }
  const dimensionValue = `${dimensions.join('×')} ${unit}`;
  return dimensionValue;
}

export function getWeight(weight, unit) {
  if (isEmpty(weight)) return '';
  return `${weight} ${unit}`;
}

export function dimensionUnitChange(dimension, dimensionToChange, number) {
  const conversionFactors = {
    m: { m: 1, cm: 100, mm: 1000, in: 39.3701, ft: 3.28084, yd: 1.09361 },
    cm: { m: 0.01, cm: 1, mm: 10, in: 0.393701, ft: 0.0328084, yd: 0.0109361 },
    mm: { m: 0.001, cm: 0.1, mm: 1, in: 0.0393701, ft: 0.00328084, yd: 0.00109361 },
    in: { m: 0.0254, cm: 2.54, mm: 25.4, in: 1, ft: 0.0833333, yd: 0.0277778 },
    ft: { m: 0.3048, cm: 30.48, mm: 304.8, in: 12, ft: 1, yd: 0.333333 },
    yd: { m: 0.9144, cm: 91.44, mm: 914.4, in: 36, ft: 3, yd: 1 },
  };
  if (!conversionFactors[dimension] || !conversionFactors[dimensionToChange]) {
    return;
  }
  return parseFloat((number * conversionFactors[dimension][dimensionToChange]).toFixed(10));
}
export function weightUnitChange(weight, weightToChange, number) {
  const conversionFactors = {
    kg: { kg: 1, g: 1000, lbs: 2.20462, t: 0.001, oz: 35.274, st: 0.157473 },
    g: { kg: 0.001, g: 1, lbs: 0.00220462, t: 1e-6, oz: 0.035274, st: 0.000157473 },
    lbs: { kg: 0.453592, g: 453.592, lbs: 1, t: 0.000453592, oz: 16, st: 0.0714286 },
    t: { kg: 1000, g: 1e6, lbs: 2204.62, t: 1, oz: 35274, st: 157.473 },
    oz: { kg: 0.0283495, g: 28.3495, lbs: 0.0625, t: 2.835e-5, oz: 1, st: 0.00446429 },
    st: { kg: 6.35029, g: 6350.29, lbs: 14, t: 0.00635029, oz: 224, st: 1 },
  };

  if (!conversionFactors[weight] || !conversionFactors[weightToChange]) {
    return;
  }

  return parseFloat((number * conversionFactors[weight][weightToChange]).toFixed(10));
}

import { filledInputClasses } from '@mui/material/FilledInput';
import { inputBaseClasses } from '@mui/material/InputBase';
// ----------------------------------------------------------------------

export default function TextField(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        input: {
          value: theme.typography.body_md,
          color: theme.palette.text.secondary,
          [`&.${inputBaseClasses.disabled}`]: {
            WebkitTextFillColor: theme.palette.text.secondary,
            color: theme.palette.text.secondary,
          },
          // '&::placeholder': {
          //   opacity: 1,
          //   color: theme.palette.text.disabled,
          // },
        },
      },
    },

    // FILLED
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 'var(--p-space-100)',
          [`&.${filledInputClasses.disabled}`]: {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        input: {
          padding: '8.5px 14px',
        },
      },
    },
  };
}

import useSWR, { mutate } from 'swr';
import { ENDPOINTS } from '../endpoints';
import axiosInstance, {
  fetcher,
  revalidateOnlyStaleOptions,
  noRevalidateOnlyStaleOptions,
} from 'src/utils/axios';
import { useMemo } from 'react';
import { getURL } from 'src/utils/url';
import { isEmpty, isZeroObject } from 'src/utils/type_check';
import _ from 'lodash';
import { cleanParams } from 'src/utils/url';
import { isArray } from 'src/utils/type_check';

var currentURL = '';
var currentItemURL = '';

export function useGetShippingContainers(params) {
  const URL = getURL(ENDPOINTS.outbound.shipping.get_list, params);
  currentURL = URL;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);
  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || {},
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );
  return memoizedValue;
}

export function useGetShippingScan(locationId, courierTrackingNumber) {
  var URL = ENDPOINTS.outbound.shipping.get(locationId, courierTrackingNumber);
  if (locationId === '' || courierTrackingNumber === '') {
    URL = null;
  }
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);
  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || {},
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );
  return memoizedValue;
}

export function useGetShippingSortList() {
  const URL = ENDPOINTS.outbound.shipping.sort_list;

  const { data, isLoading, error, isValidating } = useSWR(
    URL,
    fetcher,
    noRevalidateOnlyStaleOptions,
  );

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || {},
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );

  return memoizedValue;
}

export async function postShipped(locationId, courierTrackingNumber) {
  var URL = ENDPOINTS.outbound.shipping.post;
  if (locationId === '' || courierTrackingNumber === '') {
    URL = null;
  }
  var ret = await axiosInstance.post(URL, { locationId, courierTrackingNumber });
  return ret.data;
}

function checkContainers(clone) {
  if (clone.containers) {
    clone.containers = clone.containers.filter((variant) => !isZeroObject(variant));
  }
  if (
    !clone.containers ||
    isEmpty(clone.containers) ||
    (clone.containers.length === 1 && isZeroObject(clone.containers[0]))
  ) {
    clone.containers = [];
  }
  return clone.containers;
}

export async function postContainer(data) {
  data.containers = checkContainers(data);
  var ret = await axiosInstance.post(ENDPOINTS.outbound.shipping.post_container, data);
  if (ret.data.isSuccess) mutate(currentURL, (d) => d, true);
  return ret.data;
}

export function useGetContainer(id, params) {
  const URL = getURL(ENDPOINTS.outbound.shipping.get_container(id), params);
  currentItemURL = URL;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);
  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || {},
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );
  return memoizedValue;
}

export async function updateContainer(id, data) {
  var clone = _.cloneDeep(cleanParams(data));
  delete clone.courier;
  var ret = await axiosInstance.put(ENDPOINTS.outbound.shipping.put_container(id), clone);
  mutate(currentItemURL, (d) => d, true);
  return ret.data;
}

export async function deleteContainer(id) {
  var ret = await axiosInstance.delete(ENDPOINTS.outbound.shipping.delete_container(id));
  mutate(currentURL, (d) => d, true);
  return ret.data;
}

export async function deleteMultipleContainers(ids) {
  let values = {
    success: 0,
    failed: 0,
  };
  await Promise.all(
    ids.map(async (id) => {
      try {
        await axiosInstance.delete(
          ENDPOINTS.outbound.shipping.delete_container(id.shipmentContainerId),
        );
        values.success++;
      } catch {
        values.failed++;
      }
    }),
  );
  mutate(currentURL, (d) => d, true);
  return values;
}

export async function markShipped(ids) {
  if (!isArray(ids)) ids = [ids];
  var items = [];
  for (var i = 0; i < ids.length; i++) {
    items.push({
      shipmentContainerId: ids[i],
    });
  }
  var ret = await axiosInstance.post(ENDPOINTS.outbound.shipping.mark_shipped_container, {
    containers: items,
  });
  if (ret.data.isSuccess) mutate(currentURL, (d) => d, true);
  return ret.data;
}

export async function getContainerByScan(locationId, courierTrackingNumber) {
  var ret = await axiosInstance.get(
    ENDPOINTS.outbound.shipping.get_container_by_scan(locationId, courierTrackingNumber),
  );
  return ret.data;
}

export async function postContainerScan(data) {
  var ret = await axiosInstance.post(ENDPOINTS.outbound.shipping.post_scan_container, data);
  mutate(currentItemURL, (d) => d, true);

  return ret.data;
}

export async function printContainers(data) {
  var ret = await axiosInstance.post(ENDPOINTS.outbound.shipping.print_container, data);
  return ret.data;
}

export async function deletePackage(ids) {
  if (!isArray(ids)) ids = [ids];
  var isSuccess = true;
  for (var i = 0; i < ids.length; i++) {
    var ret = await axiosInstance.delete(
      ENDPOINTS.outbound.shipping.delete_package(
        ids[i].shipmentContainerId,
        ids[i].orderShipmentId,
      ),
    );
    if (!ret.data.isSuccess) {
      isSuccess = false;
    }
  }
  mutate(currentItemURL, (d) => d, true);

  return isSuccess;
}

export async function manifestContainer(shipmentContainerId) {
  const URL = getURL(ENDPOINTS.outbound.shipping.manifest_excel_container(shipmentContainerId));
  const res = await axiosInstance.get(URL);
  return res.data?.result?.list || [];
}

import { IndexTable, Badge } from '@shopify/polaris';
import i18n from 'src/locales/i18n';
import { OUTSTATUS } from 'src/sections/outbound/outbound/outbound_table_misc';
import { createSorterConfirmation } from '../create_sorter_modal';
import { Link, Typography } from '@mui/material';
import { chooseLan } from 'src/utils/language_code';

i18n.loadNamespaces('settings');

export const getBadge = (status, children) => {
  if (status === OUTSTATUS.PICKING) return <Badge tone={'attention'}>{children}</Badge>;
  if (status === OUTSTATUS.PACKING) return <Badge tone="warning">{children}</Badge>;
  if (status === OUTSTATUS.SHIPPING) return <Badge tone="info">{children}</Badge>;
  if (status === OUTSTATUS.SHIPPED) return <Badge tone="success">{children}</Badge>;
  if (status === OUTSTATUS.ISSUE) return <Badge tone="critical">{children}</Badge>;
  if (status === 'All') return <Badge tone="neutral"> {children} </Badge>;
};

const rowMarkup = ({ data, selectedItems }) => {
  return data.map((sorter, index) => {
    return (
      <IndexTable.Row
        id={sorter.doorId}
        key={index}
        selected={selectedItems.includes(sorter.doorId)}
        position={index}
        onClick={async (e) => {
          await createSorterConfirmation({
            selectedLocation: sorter.locationId,
            currentData: sorter,
          });
        }}
      >
        <IndexTable.Cell>
          <Link underline="hover">
            <Typography variant="body_md_medium">
              {i18n.t('settings:sorter.door_num', { id: sorter.doorId })}
            </Typography>
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {chooseLan(sorter.locationNameLocal, sorter.locationNameEnglish)}
        </IndexTable.Cell>
        <IndexTable.Cell>{sorter.doorName}</IndexTable.Cell>
        <IndexTable.Cell>{sorter.sortingOrder}</IndexTable.Cell>
        <IndexTable.Cell>{sorter.countryCode}</IndexTable.Cell>
        <IndexTable.Cell>{sorter.courierName}</IndexTable.Cell>
        <IndexTable.Cell>{sorter.courierServiceCode}</IndexTable.Cell>
        <IndexTable.Cell>
          {sorter.weightMin ? `${sorter.weightMin} ${sorter.weightUnit}` : ''}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {sorter.weightMax ? `${sorter.weightMax} ${sorter.weightUnit}` : ''}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {sorter.lengthMax ? `${sorter.lengthMax} ${sorter.dimensionUnit}` : ''}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {sorter.girthMax ? `${sorter.girthMax} ${sorter.dimensionUnit}` : ''}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {getBadge(sorter.orderShipmentStatus, sorter.orderShipmentStatus)}
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });
};
export default rowMarkup;

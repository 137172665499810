import { ActionList, Button, Pagination, Popover } from '@shopify/polaris';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';

export default function TablePagination({ useTable, isLoading }) {
  const { t } = useTranslation();
  const [rowsPopoverActive, setRowsPopoverActive] = useState(false);
  const toggleRowsPopoverActive = useCallback(
    () => setRowsPopoverActive((rowsPopoverActive) => !rowsPopoverActive),
    [],
  );

  const rowsPerPageList = useMemo(() => {
    return useTable.rowsPerPageList.map((x) => ({
      content: x,
      onAction: () => useTable.onChangeRowsPerPage(x),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: 'grid' }}>
      <Pagination
        onPrevious={useTable.pagination.onPrevious}
        onNext={useTable.pagination.onNext}
        type="table"
        hasNext={useTable.pagination.hasNext}
        hasPrevious={useTable.pagination.hasPrevious}
        label={isLoading ? '' : useTable.pagination.label}
      />
      {useTable.showRowsPerPage && (
        <Stack
          display={isLoading ? 'none' : null}
          direction="row"
          alignItems={'center'}
          spacing={1}
          sx={{ position: 'absolute', zIndex: 9999, right: 10, transform: 'translateY(20%)' }}
        >
          <Typography
            variant="body_sm_medium"
            color="text.brand"
            noWrap={true}
          >
            {t('common:sort.rows_per_page')}
          </Typography>
          <Popover
            active={rowsPopoverActive}
            activator={
              <Button
                disclosure="select"
                onClick={toggleRowsPopoverActive}
              >
                <p>{useTable.rowsPerPage}</p>
              </Button>
            }
            autofocusTarget="first-node"
            onClose={toggleRowsPopoverActive}
          >
            <ActionList
              actionRole="menuitem"
              items={rowsPerPageList}
              onActionAnyItem={toggleRowsPopoverActive}
            />
          </Popover>
        </Stack>
      )}
    </Box>
  );
}

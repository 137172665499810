// ----------------------------------------------------------------------

export function remToPx(value) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

const primaryFont = 'Inter, sans-serif'; // Google Font
// const secondaryFont = 'CircularStd, sans-serif'; // Local Font

// ----------------------------------------------------------------------
const fontWeightRegular = 450;
const fontWeightMedium = 550;
const fontWeightSemiBold = 650;
const fontWeightBold = 700;

export const typography = {
  fontFamily: primaryFont,
  fontWeightRegular: fontWeightRegular,
  fontWeightMedium: fontWeightMedium,
  fontWeightSemiBold: fontWeightSemiBold,
  fontWeightBold: fontWeightBold,
  // h1: {
  //   fontWeight: 800,
  //   lineHeight: 80 / 64,
  //   fontSize: pxToRem(40),
  //   ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  // },
  // h2: {
  //   fontWeight: 800,
  //   lineHeight: 64 / 48,
  //   fontSize: pxToRem(32),
  //   ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  // },
  // h3: {
  //   fontWeight: 700,
  //   lineHeight: 1.5,
  //   fontSize: pxToRem(24),
  //   ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
  // },
  // h4: {
  //   fontWeight: 700,
  //   lineHeight: 1.5,
  //   fontSize: pxToRem(20),
  //   ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  // },
  // h5: {
  //   fontWeight: 700,
  //   lineHeight: 1.5,
  //   fontSize: pxToRem(18),
  //   ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  // },
  heading_xl: {
    lineHeight: pxToRem(32),
    fontSize: pxToRem(24),
    fontWeight: fontWeightBold,
  },
  heading_lg: {
    lineHeight: pxToRem(24),
    fontSize: pxToRem(20),
    fontWeight: fontWeightSemiBold,
  },
  heading_md: {
    lineHeight: pxToRem(20),
    fontSize: pxToRem(14),
    fontWeight: fontWeightSemiBold,
  },
  heading_sm: {
    lineHeight: pxToRem(20),
    fontSize: pxToRem(13),
    fontWeight: fontWeightSemiBold,
  },
  body_sm: {
    lineHeight: pxToRem(16),
    fontSize: pxToRem(12),
    fontWeight: fontWeightRegular,
  },
  body_sm_medium: {
    lineHeight: pxToRem(16),
    fontSize: pxToRem(12),
    fontWeight: fontWeightMedium,
  },
  body_sm_semibold: {
    lineHeight: pxToRem(16),
    fontSize: pxToRem(12),
    fontWeight: fontWeightSemiBold,
  },
  body_md: {
    lineHeight: pxToRem(20),
    fontSize: pxToRem(13),
    fontWeight: fontWeightRegular,
  },
  body_md_medium: {
    lineHeight: pxToRem(20),
    fontSize: pxToRem(13),
    fontWeight: fontWeightMedium,
  },
  body_md_semibold: {
    lineHeight: pxToRem(20),
    fontSize: pxToRem(13),
    fontWeight: fontWeightSemiBold,
  },
  body_md_bold: {
    lineHeight: pxToRem(20),
    fontSize: pxToRem(13),
    fontWeight: fontWeightBold,
  },
  body_lg: {
    lineHeight: pxToRem(20),
    fontSize: pxToRem(14),
    fontWeight: fontWeightRegular,
  },
  body_lg_medium: {
    lineHeight: pxToRem(20),
    fontSize: pxToRem(14),
    fontWeight: fontWeightMedium,
  },
  body_lg_semibold: {
    lineHeight: pxToRem(20),
    fontSize: pxToRem(14),
    fontWeight: fontWeightSemiBold,
  },
  // subtitle1: {
  //   fontWeight: 600,
  //   lineHeight: 1.5,
  //   fontSize: pxToRem(16),
  // },
  // subtitle2: {
  //   fontWeight: 400,
  //   lineHeight: 22 / 14,
  //   fontSize: pxToRem(14),
  // },
  // body1: {
  //   lineHeight: 1.5,
  //   fontSize: pxToRem(16),
  // },
  // body2: {
  //   lineHeight: 22 / 14,
  //   fontSize: pxToRem(14),
  // },
  // caption: {
  //   lineHeight: 1.5,
  //   fontSize: pxToRem(12),
  // },
  // overline: {
  //   fontWeight: 700,
  //   fontSize: pxToRem(12),
  //   lineHeight: 1.5,
  //   //textTransform: 'uppercase',
  // },
  button: {
    textTransform: 'unset',
  },
};

import { IndexTable, Text } from '@shopify/polaris';
import { chooseLan } from 'src/utils/language_code';
import { fCurrency, getDimension, getWeight } from 'src/utils/format_number';
import { Link } from '@mui/material';

const rowMarkup = ({ data, selectedItems }, handleRowClick) => {
  return data.map(
    (
      {
        packingMaterialId,
        packingMaterialNameLocal,
        packingMaterialNameEnglish,
        locationNameLocal,
        locationNameEnglish,
        packingMaterialType,
        length,
        width,
        height,
        dimensionUnit,
        weight,
        weightUnit,
        packingMaterialQuantity,
        currencyCode,
        purchaseCost,
        barcode,
      },
      index,
    ) => {
      const dimensionValue = getDimension(length, width, height, dimensionUnit);
      const weightValue = getWeight(weight, weightUnit);

      return (
        <IndexTable.Row
          id={packingMaterialId}
          key={packingMaterialId}
          selected={selectedItems.includes(packingMaterialId)}
          position={index}
          onClick={() =>
            handleRowClick({
              id: packingMaterialId,
              name: chooseLan(packingMaterialNameLocal, packingMaterialNameEnglish),
            })
          }
        >
          <IndexTable.Cell>
            <Link
              underline="hover"
              fontWeight={'bold'}
            >
              {chooseLan(packingMaterialNameLocal, packingMaterialNameEnglish)}
            </Link>
          </IndexTable.Cell>
          <IndexTable.Cell>{chooseLan(locationNameLocal, locationNameEnglish)}</IndexTable.Cell>
          <IndexTable.Cell>{packingMaterialId}</IndexTable.Cell>
          <IndexTable.Cell>{packingMaterialType}</IndexTable.Cell>
          <IndexTable.Cell>{barcode}</IndexTable.Cell>
          <IndexTable.Cell>{dimensionValue}</IndexTable.Cell>
          <IndexTable.Cell>{weightValue}</IndexTable.Cell>
          <IndexTable.Cell>{packingMaterialQuantity}</IndexTable.Cell>
          <IndexTable.Cell>
            <Text
              as="span"
              alignment="end"
              numeric
            >
              {fCurrency(purchaseCost, currencyCode)}
            </Text>
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    },
  );
};
export default rowMarkup;

import { useEffect, useCallback, useState } from 'react';
import { useGetMe } from 'src/api/user_api';
import { useRouter } from 'src/hooks/router/use_router';
import { PATHS } from 'src/routes/paths';
import { isEmpty } from 'src/utils/type_check';
import { getURL } from 'src/utils/url';

export default function AuthGuard({ children }) {
  const router = useRouter();

  const { user, searchLoading } = useGetMe();

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!searchLoading) {
      if (isEmpty(user)) {
        const href = getURL(PATHS.auth.email, { returnTo: window.location.pathname });

        router.replace(href);
      } else {
        setChecked(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchLoading, user, router]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchLoading, user]);

  if (searchLoading) {
    return null;
  }

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}

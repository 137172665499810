import axiosInstance, {
  fetcher,
  noRevalidateOnlyStaleOptions,
  revalidateOnlyStaleOptions,
} from 'src/utils/axios';
import useSWR from 'swr';
import { useMemo } from 'react';
import { getURL } from 'src/utils/url';
import { ENDPOINTS } from '../endpoints';

export function useGetOutbound(params) {
  const URL = getURL(ENDPOINTS.outbound.get_list, params);

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || {},
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );

  return memoizedValue;
}

export function useGetOutboundSortList() {
  const URL = ENDPOINTS.outbound.sort_list;

  const { data, isLoading, error, isValidating } = useSWR(
    URL,
    fetcher,
    noRevalidateOnlyStaleOptions,
  );

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || {},
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );

  return memoizedValue;
}

export function useGetSingleOutbound(id) {
  const URL = ENDPOINTS.outbound.get(id);
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || {},
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );

  return memoizedValue;
}

export async function getRePrintOutbound(shipmentPickingId) {
  if (!Array.isArray(shipmentPickingId)) shipmentPickingId = [shipmentPickingId];

  const statuses = {
    printed: 0,
    notPrinted: 0,
  };

  let printFiles = [];
  const bulkPrint = shipmentPickingId.map(async (ids) => {
    try {
      var ret = await axiosInstance.get(ENDPOINTS.print.re_print(ids.orderShipmentId));

      if (ret?.data?.isSuccess) {
        statuses.printed += 1;
        printFiles.push(ret?.data?.result?.shipmentLabelFileBase64Pdf);
      } else {
        statuses.notPrinted += 1;
      }
    } catch (error) {
      statuses.notPrinted += 1;
    }
  });

  await Promise.all(bulkPrint);

  return { statuses, printFiles };
}

export async function getRePrintOutboundSingle(locationId, type, shipmentPickingId) {
  var ret = await axiosInstance.get(
    ENDPOINTS.print.re_print_tracking_label(locationId, type, shipmentPickingId),
  );
  return ret.data;
}

export async function exportOutbound(params) {
  const URL = getURL(ENDPOINTS.outbound.get_excel_list, params);
  const res = await axiosInstance.get(URL);
  return res.data?.result?.list || [];
}

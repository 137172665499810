import { Badge, IndexTable } from '@shopify/polaris';
import { Typography } from '@mui/material';
import i18n from 'src/locales/i18n';
import BulkButton from 'src/components/bulk_button';
import { DeleteIcon, PrintIcon } from '@shopify/polaris-icons';
import { confirm, deleteConfirm } from 'src/components/dialog/confirmation';
import { deleteToteItem, printToteItem } from 'src/api/location_api';
import { toast } from 'react-toastify';
import QZPrintFile from 'src/utils/print';

export const rowMarkup = ({ data, selectedItems }) => {
  return data?.map((elem, index) => {
    return (
      <IndexTable.Row
        id={elem?.toteItemNumber}
        key={elem?.toteItemNumber}
        selected={selectedItems.includes(elem?.toteItemNumber)}
        position={index}
        onClick={() => {}}
      >
        <>
          <Typography variant="body_md_medium">{elem.toteBarcode}</Typography>
        </>
      </IndexTable.Row>
    );
  });
};

export const bulkAction = (
  data,
  selectedIds,
  handleSelectionChange,
  locationId,
  toteNumber,
  printerConfig,
) => {
  return [
    {
      content: (
        <BulkButton
          content={i18n.t('common:delete')}
          icon={DeleteIcon}
        />
      ),
      destructive: true,
      onAction: async () => {
        const confirmation = await deleteConfirm();
        if (confirmation) {
          const count = await deleteToteItem(selectedIds, locationId, toteNumber);
          if (!count.fail) {
            toast.success(
              i18n.t('settings:locations_management.tote.tote_delete_success', {
                toteItem: count.success,
              }),
            );
            handleSelectionChange('page', false);
          } else {
            toast.error(
              i18n.t('settings:locations_management.tote.tote_delete_fail', {
                total: Number(count.success) + Number(count.fail),
                success: count.success,
                fail: count.fail,
              }),
            );
          }
        }
      },
    },
    {
      content: (
        <BulkButton
          content={i18n.t('common:print_barcode')}
          icon={PrintIcon}
        />
      ),
      onAction: async () => {
        const confirmation = await confirm({
          title: i18n.t('settings:locations_management.tote.tote_print_confirmation_title'),
          content: i18n.t('settings:locations_management.tote.tote_print_confirmation_subtitle', {
            count: selectedIds.length,
          }),
        });
        if (confirmation) {
          try {
            const data = {
              locationId,
              toteNumber,
              toteItemNumbers: selectedIds.map((id) => {
                return { toteItemNumber: Number(id) };
              }),
            };
            const res = await printToteItem(data);

            if (res.isSuccess) {
              await Promise.all(
                res?.result?.toteItemNumbers.map(async (data) => {
                  await QZPrintFile(data?.labelBase64Pdf, printerConfig);
                }),
              );
            } else {
              toast.error('settings:locations_management.tote.tote_print_fail');
            }
            handleSelectionChange('page', false);
          } catch (err) {
            toast.error(typeof err === 'string' ? err : err.toString());
          }
        }
      },
    },
  ];
};

export const headers = () => {
  return [{ title: i18n.t('common:field.tote_number') }];
};

export const tabs = (count) => [
  {
    label: i18n.t('common:tab.all'),
    value: '',
    onClick: () => {},
    badge: <Badge>{count}</Badge>,
  },
];

import { Button, Page } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import LocationTitle from 'src/components/title/location_title';
import MappingTableView from './mapping_table_view';
import { userMapping } from './user_mapping_modal';
import { isEmpty } from 'src/utils/type_check';
import locationStore from 'src/stores/location_store';
import { useRef, useState } from 'react';
import CourierRangeTableView from './range_table_view';
import { importDialogCourier, trackingConfirm } from 'src/components/dialog/confirmation';
import { createTrackingNumber, importTrackingNumber } from 'src/api/courier_api';
import { toast } from 'react-toastify';
import i18n from 'src/locales/i18n';

export const PAGETYPE = {
  user: 'user',
  courier: 'courier',
  rangeTracking: 'rangeTracking',
};

const MappingView = () => {
  const { t } = useTranslation('settings');
  const tableRef = useRef();
  const [pageType, setPageType] = useState(PAGETYPE.mapping);
  const selectedLocation = locationStore((state) => state.getLocationId('mapping'));
  const { setLocationId: setSelectedLocation } = locationStore();
  const [rangeCourier, setRangeCourier] = useState('');
  const changeView = () => {
    setPageType(PAGETYPE.courier);
  };
  return (
    <Page
      fullWidth
      primaryAction={
        pageType !== PAGETYPE.rangeTracking ? (
          <Button
            variant="primary"
            onClick={async () => {
              await userMapping({ locationId: selectedLocation });
            }}
            disabled={isEmpty(selectedLocation)}
          >
            {t('settings:mapping:user_mapping')}
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={async () => {
              const confirmed = await trackingConfirm();
              if (confirmed.confirmed) {
                try {
                  const result = await createTrackingNumber({
                    locationId: selectedLocation,
                    courierId: rangeCourier,
                    startNumber: Number(confirmed.fromValue),
                    endNumber: Number(confirmed.toValue),
                  });
                  if (result.isSuccess) {
                    toast.success('settings:mapping.successful_tracking_number');
                  } else {
                    toast.error(result.errorMessages[0]);
                  }
                } catch (err) {
                  toast.error(err.toString());
                }
              }
            }}
          >
            {t('settings:mapping:create_tracking_number')}
          </Button>
        )
      }
      secondaryActions={
        pageType === PAGETYPE.rangeTracking && [
          {
            content: t('common:export'),
            onAction: async () => {
              await tableRef.current.exportData(rangeCourier);
            },
          },
          {
            content: t('common:import'),
            onAction: async () => {
              await importDialogCourier({
                name: i18n.t('settings:mapping.tracking_number'),
                type: 'trackingnumber',
                importData: importTrackingNumber,
                dataKey: 'trackingnumber',
                locationId: selectedLocation,
                courierId: rangeCourier,
              });
            },
          },
        ]
      }
      title={
        pageType !== PAGETYPE.rangeTracking ? (
          <LocationTitle
            handleLocationChange={(value) => setSelectedLocation('mapping', value)}
            selectedLocation={selectedLocation}
            title={t('settings:mapping.mapping_of')}
          />
        ) : (
          t('settings:mapping.range_tracking_number')
        )
      }
      backAction={
        pageType === PAGETYPE.rangeTracking && {
          content: t('common:Back'),
          onAction: changeView,
        }
      }
    >
      {pageType !== PAGETYPE.rangeTracking && (
        <MappingTableView
          selectedLocation={selectedLocation}
          setType={setPageType}
          type={pageType}
          setRangeCourier={setRangeCourier}
        />
      )}
      {pageType === PAGETYPE.rangeTracking && rangeCourier && (
        <CourierRangeTableView
          selectedLocation={selectedLocation}
          setType={setPageType}
          type={pageType}
          courierId={rangeCourier}
          tableRef={tableRef}
        />
      )}
      <div className="bottom-padding"></div>
    </Page>
  );
};

export default MappingView;

import React, { useMemo, useState } from 'react';
import StackedModal from 'src/components/dialog/stacked_modal';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Alert, Typography } from '@mui/material';
import { mappingCodeSchema } from 'src/utils/schema';
import FormProvider from 'src/components/hook_form/form_provider';
import RHFTextField from 'src/components/hook_form/rhf_text_field';
import { connectUserLocation } from 'src/api/mapping_api';
import { isEmpty } from 'lodash';
import UserAutocomplete from 'src/components/user_autocomplete';
import { isEmptyObject } from 'src/utils/type_check';
import { InlineGrid, InlineStack, Text, Button } from '@shopify/polaris';
import { getUserName } from 'src/utils/format_data';
import { XIcon } from '@shopify/polaris-icons';

const MapLocationToUserModal = ({ isOpen, onClose, onSuccess, locationId }) => {
  const { t } = useTranslation(['settings']);
  const [errorMsg, setErrorMsg] = useState('');

  const [selectedUser, setSelectedUser] = useState({});

  const handleSelectUser = (user) => {
    setSelectedUser(user);
  };

  const handleModalClose = () => {
    onClose();
    reset();
    setErrorMsg('');
  };

  const defaultValues = useMemo(
    () => ({
      mappingCode: '',
    }),
    [],
  );

  const methods = useForm({
    resolver: yupResolver(mappingCodeSchema(t)),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    setErrorMsg('');
    try {
      if (isEmpty(selectedUser)) {
        setErrorMsg(t('settings:locations.customer_required'));
        return;
      }
      const res = await connectUserLocation({
        userId: selectedUser.userId,
        locationId: locationId,
        mappingCode: data.mappingCode,
      });
      if (res.isSuccess) {
        onSuccess(getUserName(selectedUser));
        handleModalClose();
      } else {
        setErrorMsg(res.errorMessages[0] ?? '');
      }
    } catch (error) {
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });

  const renderFormContent = (
    <Stack spacing={1}>
      {!!errorMsg && (
        <Alert
          severity="error"
          onClose={() => setErrorMsg('')}
        >
          {errorMsg}
        </Alert>
      )}
      {isEmptyObject(selectedUser) ? (
        <Stack spacing={0.5}>
          <Typography variant="body_md">{t('common:field.customer')}</Typography>
          <UserAutocomplete onSelect={handleSelectUser} />
        </Stack>
      ) : (
        <InlineStack align="space-between">
          <InlineGrid>
            <Text
              as="h3"
              variant="bodyMd"
              fontWeight="semibold"
            >
              {getUserName(selectedUser)}
            </Text>
            <Text
              as="p"
              variant="bodyMd"
            >
              {selectedUser.loginId}
            </Text>
          </InlineGrid>
          <Button
            icon={XIcon}
            variant="plain"
            tone="critical"
            onClick={() => setSelectedUser({})}
            accessibilityLabel="Edit"
          />
        </InlineStack>
      )}
      <RHFTextField
        name="mappingCode"
        displayName={t('settings:locations.mapping_code')}
      />
    </Stack>
  );

  const renderForm = (
    <FormProvider
      methods={methods}
      onSubmit={onSubmit}
    >
      {renderFormContent}
    </FormProvider>
  );

  return (
    <StackedModal
      title={t('settings:locations.mapping_code')}
      content={renderForm}
      isOpen={isOpen}
      onClose={handleModalClose}
      primaryAction={{
        content: t('common:apply'),
        onAction: onSubmit,
        loading: isSubmitting,
      }}
      secondaryAction={{
        content: t('common:cancel'),
        onAction: handleModalClose,
      }}
    />
  );
};
export default MapLocationToUserModal;

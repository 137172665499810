import { IndexTable } from '@shopify/polaris';
import { Link, Typography } from '@mui/material';
import { chooseLan } from 'src/utils/language_code';

const rowMarkup = ({ data, selectedItems }, handleRowClick) => {
  return data.map((machine, index) => {
    const endpointsLength = machine.endpoints.length || 0;
    const id = machine.machineId;
    return (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedItems.includes(id)}
        position={index}
        onClick={() => handleRowClick(machine)}
      >
        {' '}
        <IndexTable.Cell>
          <Link underline="hover">
            <Typography variant="body_md_medium">
              {chooseLan(machine.machineNameLocal, machine.machineNameEnglish)}
            </Typography>
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {chooseLan(machine.locationNameLocal, machine.locationNameEnglish)}
        </IndexTable.Cell>
        <IndexTable.Cell>{machine.machineType}</IndexTable.Cell>
        <IndexTable.Cell>{endpointsLength}</IndexTable.Cell>
      </IndexTable.Row>
    );
  });
};
export default rowMarkup;

import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

const initialState = {
  locationId: '',
  dashboard: '',
  inbound: 'all',
  putaway: '',
  stock: '',
  outbound: '',
  packing: '',
  picking: '',
  shippingContainer: '',
  shippingContainerScan: '',
  shippingScan: '',
  carrier: '',
  machine: 'all',
  packingMaterial: 'all',
  sorter: 'all',
  mapping: '',
  locationManagement: 'all',
};

const store = (set, get) => ({
  ...initialState,

  setDefaultLocationId: (value) => {
    set({ locationId: value });
  },
  setLocationId: (page, value) => {
    set((prev) => ({ ...prev, [page]: value }));
  },
  getLocationId: (page) => {
    var defaultLocation = get().locationId;
    if (defaultLocation !== '') return defaultLocation;
    return get()[page] || '';
  },
  reset: () => {
    set(initialState);
  },
});

const locationStore = create(
  persist(store, {
    name: 'location',
    storage: createJSONStorage(() => localStorage),
  }),
);

export default locationStore;

import React, { useEffect, useRef, useState } from 'react';
import qz from 'qz-tray';
import { Typography, MenuItem, Select, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CheckIcon } from '@shopify/polaris-icons';

const QZPrint = ({ setPrinterConfig, setting }) => {
  const [printerList, setPrinterList] = useState([]);
  const [selectedPrinter, setSelectedPrinter] = useState('');
  const [minWidth, setMinWidth] = useState(0);
  const textMeasureRef = useRef(null);

  const saveSelectedPrinter = (printerName, config) => {
    localStorage.setItem('qzSelectedPrinter', printerName);
  };

  useEffect(() => {
    if (printerList.length > 0) {
      let longest = '';
      printerList.forEach((printer) => {
        if (printer.length > longest.length) {
          longest = printer;
        }
      });
      if (textMeasureRef.current) {
        textMeasureRef.current.innerHTML = longest;
        setMinWidth(
          textMeasureRef.current.offsetWidth > 300 ? 300 : textMeasureRef.current.offsetWidth,
        );
      }
    }
  }, [printerList]);

  useEffect(() => {
    const fetchPrinters = async () => {
      try {
        const printers = await qz.printers.find();
        setPrinterList(printers);
      } catch (error) {
        console.error('Error fetching printers:', error);
      }
    };
    fetchPrinters().then(() => {
      const printer = localStorage.getItem('qzSelectedPrinter') ?? '';
      if (printer.length > 0) {
        setSelectedPrinter(printer);
        const config = qz.configs.create(printer);
        setPrinterConfig(config);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPrinterSelect = (e) => {
    setSelectedPrinter(e.target.value);
    const config = qz.configs.create(e.target.value);
    setPrinterConfig(config);
    saveSelectedPrinter(e.target.value, JSON.stringify(config));
  };

  const renderTitle = (
    <Select
      variant="standard"
      disableUnderline
      displayEmpty
      sx={{
        minWidth: `${minWidth}px`,
        maxWidth: '300px',
        textAlign: 'start',
        height: '10px',
        borderRadius: '10px',
        alignItems: 'center',
        backgroundColor: 'inherit !important',
        '& .MuiSelect-select': {
          alignItems: 'center',
          backgroundColor: 'inherit !important',
          '&:focus': {
            borderRadius: '10px',
            backgroundColor: 'inherit !important',
          },
          paddingY: '0px !important',
        },
      }}
      IconComponent={() => (
        <KeyboardArrowDownIcon
          fontSize="inherit"
          sx={{ position: 'absolute', right: 0, top: -2, pointerEvents: 'none', color: 'icon' }}
        />
      )}
      value={selectedPrinter ?? ''}
      onChange={onPrinterSelect}
      renderValue={(selected) => (
        <Typography
          variant="body_sm_semibold"
          color={'text.primary'}
          sx={{ paddingLeft: '4px', paddingY: '0px' }}
        >
          {selected || 'Select a printer'}
        </Typography>
      )}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        PaperProps: {
          sx: {
            top: !setting ? '108px !important' : '200px !important',
            marginTop: '6px',
            borderRadius: '12px',
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
            border: '1px solid rgba(0, 0, 0, 0.1)',
          },
        },
      }}
    >
      {printerList.map((printer) => (
        <MenuItem
          key={printer}
          value={printer}
          sx={{
            '&.Mui-selected': {
              borderRadius: '10px',
              backgroundColor: 'rgba(128, 128, 128, 0.2)',
            },
            '&:hover': {
              borderRadius: '10px',
              backgroundColor: 'rgba(241, 241, 241, 1)',
            },
            display: 'flex',
            justifyContent: 'space-between',
            margin: '6px',
            paddingX: '6px',
          }}
        >
          <Typography
            variant="body_sm"
            color={'text.primary'}
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: `${minWidth}px`,
            }}
          >
            {printer}
          </Typography>
          {selectedPrinter === printer && <CheckIcon height="20px" />}
        </MenuItem>
      ))}
    </Select>
  );

  return (
    <div>
      <Box
        ref={textMeasureRef}
        sx={{
          position: 'absolute',
          visibility: 'hidden',
          whiteSpace: 'nowrap',
          fontSize: '16px', // Match with the Typography style
          fontFamily: 'inherit',
        }}
      />
      {renderTitle}
    </div>
  );
};

export default QZPrint;

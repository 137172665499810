import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { APP_NAME } from 'src/config';
import { PATHS } from 'src/routes/paths';
import { lowerCaseWithoutSpace } from 'src/utils/format_data';

const Logo = ({ color = 'black' }) => {
  return (
    <Link to={PATHS.dashboard.root}>
      <Box
        component="img"
        src={`/assets/logo/${lowerCaseWithoutSpace(APP_NAME)}_${color}.png`}
        sx={{ cursor: 'pointer', height: '24px' }}
      />
    </Link>
  );
};

export default Logo;
